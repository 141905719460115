import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import axios from '../axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../components/Loader";
import { useSelector } from "react-redux";
import ChartView from './ChartView';
import { Watermark } from '@hirohe/react-watermark';
import Logo from "../images/watermark-logo.png";

const Dashboard = () => {
    const permissions = useSelector((state) => state.getPermissions.permissions);
    const history = useHistory();
    const [success, setSuccess] = useState("")
    const [currentAmount, setCurrentAmount] = useState(0)
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if (history.location.state !== undefined) {
            setSuccess(history.location.state.message)
        }
    }, [history])

    useEffect(() => {
        if (success) {
            toast.success(success, '', 3500);
            setSuccess('')
            history.location.state = undefined
        }
    }, [success, history])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    useEffect(() => {
        if (permissions.includes('topupbalance_get_current_balance')) {
            setLoader(true)
            axios.get("topupbalance/get_current_balance")
                .then((response) => {
                    setCurrentAmount(response.data.data)
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response.status === 401 || error.response.status === 403) {
                        history.push("/logout");
                    } else if (error.response.status !== 200) {
                        setError(error.response.data.message);
                        setLoader(false)
                    }
                })
        }
    }, [history, permissions])

    return (
        <Watermark text="Synergistic" textColor="#00356A" lineHeight="1.2" gutter="35" wrapperElement="div">
            <div style={{ height: "90vh" }}>
                {loader ? <Loader /> : null}
                <ToastContainer theme="colored" />

                {/* <div className='rectangle-border'></div> */}
                <div>
                    <img src={Logo} style={{ float: "right" }} alt="" />
                </div>
                <div className='row mt-5' style={{ width: '100%' }}>
                    <h1>Welcome to the Client Portal</h1>
                </div>
                <div className='card dashboard-container'>
                    <div className='row'>
                        <div className='col-sm-8'>
                            <h3 className='dashboard-heading'> Welcome {localStorage.getItem("clientFirstName")} {localStorage.getItem("clientLastName")}</h3>
                        </div>
                        <hr className='vertical-line' />
                        <div className='col-sm-4'>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <label className='form-label'>
                                        Available Balance
                                    </label>
                                </div>
                                <div className='col-sm-6'>
                                    <h4 className='amount'> R{currentAmount}</h4>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                {/* graph */}

                <div className='row'>
                    <div className='col-sm-6'>
                        <div className='card' style={{ padding: '20px' }}>
                            <h3 className='dashboard-heading'>Stacked bar</h3>
                            <ChartView />
                        </div>
                    </div>


                    <div className='col-sm-6'>
                        <div className='card' style={{ padding: '20px' }}>
                            <h3 className='dashboard-heading'>Single bar</h3>
                            <ChartView />
                        </div>
                    </div>

                </div>
            </div>
        </Watermark>
    )
}

export default Dashboard;
