import React from 'react'

export const Demo = () => {
    return (
        <div>
            <div className="d-flex align-items-center">
                <div>
                    <h5 className="mb-0"><strong>Reports</strong></h5>
                </div>
            </div>
                <hr />
                <div className="row">
                    <div className="col-sm-6">
                        <div className="row">
                            <div className="col-sm-6">
                                <select className="form-select mt-3 mb-3" aria-label="Default select example">
                                    <option>Product Used</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                            <div className="col-sm-6">
                                <select className="form-select mt-3 mb-3" aria-label="Default select example">
                                    <option>Last edited by</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-check mt-3 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">Messages Sent</label>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-check mt-3 mb-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">Messages Recieved</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="row">
                            <div className="col-sm-6">
                                <input className="form-control mt-3 mb-3" type="text" placeholder="Message Contains" aria-label="default input example" /> </div>
                            <div className="col-sm-6">
                                <select className="form-select mt-3 mb-3" aria-label="Default select example">
                                    <option>Open this select menu</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <input className="form-control mt-3 mb-3" type="text" placeholder="Contact" aria-label="default input example" /> </div>
                            <div className="col-sm-6">
                                <select className="form-select mt-3 mb-3" aria-label="Default select example">
                                    <option>Scheduled End</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card radius-10">
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table align-middle mb-0">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Contact Number</th>
                                        <th>Customer</th>
                                        <th>Date</th>
                                        <th>Price</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Name Surname</td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div className="ms-2">
                                                    <h6 className="mb-1 font-14">+98 76 543 210</h6>
                                                </div>
                                            </div>
                                        </td>
                                        <td>Brooklyn Zeo</td>
                                        <td>12 Jul 2020</td>
                                        <td>$64.00</td>
                                        <td>
                                            <div className="d-flex align-items-center text-danger"> <i className='bx bx-radio-circle-marked bx-rotate-90 align-middle font-18 me-1'></i> <span>UnSubscribed</span> </div>
                                        </td>
                                        <td>
                                            <div className="d-flex order-actions">
                                                {/* <a href="#" className=""><i className="bx bx-calendar-star"></i></a> <a href="#" className="ms-4"><i className='bx bx-info-circle'></i></a> */}

                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Name Surname</td>
                                        <td>
                                            <div className="d-flex align-items-center">

                                                <div className="ms-2">
                                                    <h6 className="mb-1 font-14">+98 76 543 210</h6>
                                                </div>
                                            </div>
                                        </td>
                                        <td>Martin Hughes</td>
                                        <td>14 Jul 2020</td>
                                        <td>$45.00</td>
                                        <td>
                                            <div className="d-flex align-items-center text-success"> <i className='bx bx-radio-circle-marked bx-rotate-90 align-middle font-18 me-1'></i> <span>Subscribed</span> </div>
                                        </td>
                                        <td>
                                            <div className="d-flex order-actions">
                                                {/* <a href="#" className=""><i className="bx bx-calendar-star"></i></a> <a href="#" className="ms-4"><i className='bx bx-info-circle'></i></a> */}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Name Surname</td>
                                        <td>
                                            <div className="d-flex align-items-center">

                                                <div className="ms-2">
                                                    <h6 className="mb-1 font-14">+98 76 543 210</h6>
                                                </div>
                                            </div>
                                        </td>
                                        <td>Shoan Stephen</td>
                                        <td>15 Jul 2020</td>
                                        <td>$67.00</td>
                                        <td>
                                            <div className="d-flex align-items-center text-success"> <i className='bx bx-radio-circle-marked bx-rotate-90 align-middle font-18 me-1'></i> <span>Subscribed</span> </div>
                                        </td>
                                        <td>
                                            <div className="d-flex order-actions">
                                                {/* <a href="#" className=""><i className="bx bx-calendar-star"></i></a> <a href="#" className="ms-4"><i className='bx bx-info-circle'></i></a> */}

                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Name Surname</td>
                                        <td>
                                            <div className="d-flex align-items-center">

                                                <div className="ms-2">
                                                    <h6 className="mb-1 font-14">+98 76 543 210</h6>
                                                </div>
                                            </div>
                                        </td>
                                        <td>Alister Campel</td>
                                        <td>18 Jul 2020</td>
                                        <td>$87.00</td>
                                        <td>
                                            <div className="d-flex align-items-center text-success"> <i className='bx bx-radio-circle-marked bx-rotate-90 align-middle font-18 me-1'></i> <span>Subscribed</span> </div>
                                        </td>
                                        <td>
                                            <div className="d-flex order-actions">
                                                {/* <a href="#" className=""><i className="bx bx-calendar-star"></i></a> <a href="#" className="ms-4"><i className='bx bx-info-circle'></i></a>  */}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Name Surname</td>
                                        <td>
                                            <div className="d-flex align-items-center">

                                                <div className="ms-2">
                                                    <h6 className="mb-1 font-14">+98 76 543 210</h6>
                                                </div>
                                            </div>
                                        </td>
                                        <td>Keate Medona</td>
                                        <td>20 Jul 2020</td>
                                        <td>$75.00</td>
                                        <td>
                                            <div className="d-flex align-items-center text-danger"> <i className='bx bx-radio-circle-marked bx-rotate-90 align-middle font-18 me-1'></i> <span>UnSubscribed</span> </div>
                                        </td>
                                        <td>
                                            <div className="d-flex order-actions">
                                                {/* <a href="javascript:;" className=""><i className="bx bx-calendar-star"></i></a> <a href="javascript:;" className="ms-4"><i className='bx bx-info-circle'></i></a> */}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Name Surname</td>
                                        <td>
                                            <div className="d-flex align-items-center">

                                                <div className="ms-2">
                                                    <h6 className="mb-1 font-14">+98 76 543 210</h6>
                                                </div>
                                            </div>
                                        </td>
                                        <td>Winslet Maya</td>
                                        <td>22 Jul 2020</td>
                                        <td>$80.00</td>
                                        <td>
                                            <div className="d-flex align-items-center text-danger"> <i className='bx bx-radio-circle-marked bx-rotate-90 align-middle font-18 me-1'></i> <span>UnSubscribed</span> </div>
                                        </td>
                                        <td>
                                            <div className="d-flex order-actions">
                                                {/* <a href="javascript:;" className=""><i className="bx bx-calendar-star"></i></a> <a href="javascript:;" className="ms-4"><i className='bx bx-info-circle'></i></a> */}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Name Surname</td>
                                        <td>
                                            <div className="d-flex align-items-center">

                                                <div className="ms-2">
                                                    <h6 className="mb-1 font-14">+98 76 543 210</h6>
                                                </div>
                                            </div>
                                        </td>
                                        <td>Emy Jackson</td>
                                        <td>28 Jul 2020</td>
                                        <td>$96.00</td>
                                        <td>
                                            <div className="d-flex align-items-center text-danger"> <i className='bx bx-radio-circle-marked bx-rotate-90 align-middle font-18 me-1'></i> <span>UnSubscribed</span> </div>
                                        </td>
                                        <td>
                                            <div className="d-flex order-actions"> </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
    )
}
export default Demo;
