import axios from "axios";
import {baseUrlApi} from "./base";

const instance = axios.create({
  baseURL: baseUrlApi,
  
  
  headers: {
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem("token")}`;
      },
    },
  },
});
// console.log(localStorage.getItem('token'))
// instance.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token") || "";

export default instance;
