import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import axios from '../../axios'
import AlertBox from '../../components/AlertBox';
import GroupPermissionsList from '../UserManagement/GroupPermissionList';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";

const UserPermissions = (props) => {
    const permissions = useSelector((state) => state.getPermissions.permissions);
    var userid = props.userId;

    const history = useHistory();
    const [groupPermissionsAssigned, setGroupPermissionAssigned] = useState([]);
    const [groupPermissionsUnassigned, setGroupPermissionUnassigned] = useState([]);
    const [showGroupPermission, setShowGroupPermission] = useState(false)
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState("");
    const [alertId, setAlertId] = useState("");
    const [error, setError] = useState("")
    const [groupId, setGroupId] = useState("");
    const [serviceCall, setServiceCall] = useState(true);
    // const [userId, setUserId] = useState(userid || "");
    const [success, setSuccess] = useState("");
    const [loader, setLoader] = useState(false);
    const [groupName, setGroupName] = useState("")
    const [handleId, setHandleId] = useState("")

    useEffect(() => {
        if (serviceCall) {
            setLoader(true)
            axios
                .get("user/get_user_groups/" + userid)
                .then((response) => {
                    setGroupPermissionAssigned(response.data.data.groups.assigned);
                    setGroupPermissionUnassigned(response.data.data.groups.unassigned);
                    setServiceCall(false);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (
                            error.response.status === 401 ||
                            error.response.status === 403
                        ) {
                            history.push("/logout");
                        }
                        if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [history, userid, serviceCall])

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    const addHandler = (groupid, userid, groupName) => {
        setAlert("Please confirm Add User to " + groupName + " Group?");
        setAlertType("add_user_group");
        setAlertId({
            groupid: groupid,
            userid: userid
        });
    };

    const deleteHandler = (groupid, userid, groupName) => {
        setAlert("Please confirm Remove User from " + groupName + " Group?");
        setAlertType("remove_user_group");
        setAlertId({
            groupid: groupid,
            userid: userid
        });
    };

    const alertResponse = (response, type, alertId) => {
        if (response) {
            setLoader(true)
            axios
                .post("user/" + type, { UserID: alertId.userid, GroupID: alertId.groupid })
                .then((response) => {
                    setServiceCall(true);
                    setSuccess(response.data.data.message);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push("/logout");
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                });
        }
        setAlert("");
        setAlertType("");
        setAlertId();
    };

    const showGroupPermissionList = (groupid, name) => {
        setGroupName(name)
        setShowGroupPermission(true);
        setGroupId(groupid)
    }

    const hidePermisssionHandler = () => {
        setShowGroupPermission(false);
    }

    const handleSelection = (groupid) => {
        console.log(groupid)
        setHandleId(groupid)
    }

    return (
        <div>
            {loader ? <Loader /> : null}
            {showGroupPermission ? (<GroupPermissionsList
                groupId={groupId}
                groupName={groupName}
                hidePermisssionHandler={hidePermisssionHandler} />) :
                <div>
                    {alert ? (<AlertBox
                        message={alert}
                        type={alertType}
                        alertId={alertId}
                        alertResponse={alertResponse} />) : null}

                    <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                        <div className="row flex-a-center">
                            <div className="col-sm-6">
                                <h5 className="mb-0 custom-header-title"><strong>User Permission</strong></h5>
                            </div>
                            <div className="col-sm-6">
                                <div className="font-22 ms-auto confirm-btn">
                                    <button className="btn btn-danger px-4" onClick={props.hidePermisssionHandler}>
                                        Cancel/Back
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />

                    <div className="row">
                        <div className="col-sm-6">
                            <div className="card radius-10">
                                <div className="card-body" style={{ overflowX: 'auto' }}>
                                    <div className="table-responsive">
                                        <table className="table align-middle mb-0">
                                            <thead>
                                                <tr>
                                                    <h6> <b>Assigned Groups </b></h6>
                                                </tr>

                                                <tr>
                                                    <th>Available Groups</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="HoverChange">
                                                {(permissions.includes('user_get_user_groups')) ?
                                                    groupPermissionsAssigned.map((group) => (
                                                        <tr key={group.ID}
                                                            onClick={() => handleSelection(group.ID)}>
                                                            <td>
                                                                {
                                                                    (group.ID === handleId) ?
                                                                        <td style={{ 'color': '#004C97', fontWeight: 'bold', fontSize: '16px' }}>{group.Name}</td> :
                                                                        <td>{group.Name}</td>
                                                                }
                                                            </td>
                                                            <td>
                                                                {(!group.Assigned) ?
                                                                    <span title="Assign"
                                                                        style={{ cursor: "pointer", color: "green" }}
                                                                        className={permissions.includes('user_add_user_group') ? '' : 'blur-buttons'}
                                                                        onClick={() => addHandler(group.ID, userid, group.Name)}>
                                                                        <b className='editColor'>Add</b> &nbsp;
                                                                        {/* <i className="fa fa-plus-square"></i> */}
                                                                    </span>
                                                                    :
                                                                    <span
                                                                        title="Remove"
                                                                        style={{ cursor: "pointer", color: "red" }}
                                                                        className={permissions.includes('user_remove_user_group') ? '' : 'blur-buttons'}
                                                                        onClick={() => deleteHandler(group.ID, userid, group.Name)}
                                                                    >
                                                                        <b className='deleteColor'>Delete</b> &nbsp;
                                                                        {/* <i className="fa fa-trash"></i> */}
                                                                    </span>
                                                                }
                                                                <span
                                                                    title="View"
                                                                    style={{ cursor: "pointer" }}
                                                                    className={permissions.includes('user_get_permission') ? '' : 'blur-buttons'}
                                                                    onClick={() => showGroupPermissionList(group.ID, group.Name)}
                                                                >
                                                                    {/* <i className="fa fa-eye"></i> */}
                                                                    <b className='editColor'>View</b> &nbsp;
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )) : null}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="card radius-10">
                                <div className="card-body" style={{ overflowX: 'auto' }}>
                                    <div className="table-responsive">
                                        <table className="table align-middle mb-0">
                                            <thead>
                                                <tr>
                                                    <h6><b>Unassigned Groups</b></h6>
                                                </tr>
                                                <tr>
                                                    <th>Available Groups</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="HoverChange">
                                                {(permissions.includes('user_get_user_groups')) ?
                                                    groupPermissionsUnassigned.map((group) => (
                                                        <tr key={group.ID}
                                                            onClick={() => handleSelection(group.ID)}>
                                                            <td>
                                                                {
                                                                    (group.ID === handleId) ?
                                                                        <td style={{ 'color': '#004C97', fontWeight: 'bold', fontSize: '16px' }}>{group.Name}</td> :
                                                                        <td>{group.Name}</td>
                                                                }
                                                            </td>
                                                            <td>
                                                                {(!group.Assigned) ?
                                                                    <span title="Assign"
                                                                        style={{ cursor: "pointer", color: "green" }}
                                                                        className={permissions.includes('user_add_user_group') ? '' : 'blur-buttons'}
                                                                        onClick={() => addHandler(group.ID, userid, group.Name)}>
                                                                        <b className='editColor'>Add</b> &nbsp;
                                                                        {/* <i className="fa fa-plus-square"></i> */}
                                                                    </span>
                                                                    :
                                                                    <span
                                                                        title="Remove"
                                                                        style={{ cursor: "pointer", color: "red" }}
                                                                        className={permissions.includes('user_remove_user_group') ? '' : 'blur-buttons'}
                                                                        onClick={() => deleteHandler(group.ID, userid, group.Name)}
                                                                    >
                                                                        <b className='deleteColor'>Delete</b> &nbsp;
                                                                        {/* <i className="fa fa-trash"></i> */}
                                                                    </span>
                                                                }
                                                                <span
                                                                    title="View"
                                                                    style={{ cursor: "pointer" }}
                                                                    className={permissions.includes('user_get_permission') ? '' : 'blur-buttons'}
                                                                    onClick={() => showGroupPermissionList(group.ID, group.Name)}
                                                                >
                                                                    <b className='editColor'>View</b> &nbsp;
                                                                    {/* <i className="fa fa-eye"></i> */}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )) : null}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
export default UserPermissions;