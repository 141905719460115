import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom';
import axios from '../../axios'
import AlertBox from "../../components/AlertBox";
import PaginationComp from "../../components/PaginationComp";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import RecordHandler from '../../components/RecordHandler';

const ViewContactList = (props) => {
  const permissions = useSelector((state) => state.getPermissions.permissions);
  const history = useHistory();
  var url = new URL(window.location.href);
  var searchTxt = url.searchParams.get("search");
  var pageNo = url.searchParams.get("page");

  const [ContactList, setContactList] = useState([])
  const [serviceCall, setServiceCall] = useState(true);
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState(searchTxt || "");
  const [page, setPage] = useState(pageNo || 1);
  const [pagination, setPagination] = useState([]);
  const [perPage, setPerPage] = useState(0);
  const [no_of_records, no_of_recordsHandler] = useState(10);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [alertId, setAlertId] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const [ColorId, setColorId] = useState("");

  useEffect(() => {
    if (history.location.state !== undefined) {
      setSuccess(history.location.state.message)
    }
    if (serviceCall) {
      setLoader(true)
      axios
        .get("contact/get_all_contact_list?no_of_records=" + no_of_records + "&page=" + page + "&search=" + searchValue)
        .then((response) => {
          setContactList(response.data.data.contactLists)
          setPagination(response.data.data.pagination);
          setPerPage(response.data.data.per_page);
          setServiceCall(false);
          setLoader(false)
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              history.push("/logout");
            }
            if (error.response.status !== 200) {
              setError(error.response.data.message);
              setLoader(false)
            }
          }
        })
    }
  }, [serviceCall, no_of_records, page, searchValue, history])


  useEffect(() => {
    if (success) {
      toast.success(success);
      setSuccess('')
      history.replace({
        state: {}
      })
      // history.location.state = undefined;
    }
  }, [success, history])

  useEffect(() => {
    if (error !== '') {
      toast.error(error);
      setError('')
    }
  }, [error])

  useEffect(() => {
    var url = new URL(window.location.href);
    var pageNo = url.searchParams.get("page");
    setSuccess('');
    setPage(pageNo || 1);
    setServiceCall(true);
  }, [history.location.search]);


  const searchHandler = () => {
    setSearchValue(search);
    setServiceCall(true);
    history.push({
      search: "page=1&search=" + search,
    });
  }

  const clearSearchHandler = () => {
    history.push({ search: "page=1&search=", });
    setSearch('');
    setSearchValue('');
    setServiceCall(true);
  }

  const recordHandler = (e) => {
    no_of_recordsHandler(e.target.value)
    setPage(1)
    setServiceCall(true)
    history.push({
      search: "no_of_records=" + e.target.value + "&page=1&search=",
    });
  }

  const deleteContact = (ContactID) => {
    setAlert("Please disable or remove the contact list from the product before attempting to remove the contact list ");
    setAlertType("delete_contact_list");
    setAlertId(ContactID);
  };

  const alertResponse = (response, type, alertId) => {
    if (response) {
      setLoader(true)
      axios
        .post("contact/" + type, { ContactListID: alertId })
        .then((response) => {
          setSuccess(response.data.data);
          setServiceCall(true);
          setLoader(false)
        })
        .catch((error) => {
          if (error.response.status === 401 || error.response.status === 403) {
            history.push("/logout");
          } else if (error.response.status !== 200) {
            setError(error.response.data.message);
            setLoader(false)
          }
        });
    }
    setAlert("");
    setAlertType("");
    setAlertId();
  };

  const handleKeyEnter = (e) => {
    return (e.key === 'Enter') ? searchHandler() : null
  }

  const handleColor = (contactId) => {
    setColorId(contactId)
  }

  return (
    <div>
      {loader ? <Loader /> : null}
      {alert ? (
        <AlertBox
          message={alert}
          type={alertType}
          alertId={alertId}
          alertResponse={alertResponse}
        />
      ) : null}
      <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
        <div className="row flex-a-center">
          <div className="col-sm-6">
            <h5 className="mb-0 custom-header-title"><strong>View Contact Lists</strong></h5>
          </div>
          <div className="col-sm-6">
            <div className="font-22 ms-auto confirm-btn">
              <Link to="/add-contact-list" className={"btn btn-primary px-4 mb-1" + (permissions.includes('contact_add_contact_list') ? '' : ' blur-buttons')}>
                Add New
              </Link>
              &nbsp;
              {/* <button type="button" className="btn btn-primary px-4 mb-1"> Cancel/Back</button> */}
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div className="row">
        <div className="col-sm-3">
          <input
            type="text"
            className="form-control mb-1"
            id="search"
            name="search"
            placeholder="Search Here"
            value={search}
            onKeyDown={(e) => handleKeyEnter(e)}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div className="col-sm-3">
          <button
            style={{ float: 'left' }}
            type="submit"
            className="btn btn-primary"
            title="Search"
            onClick={searchHandler}
          >
            <i className="fa fa-search"></i>
          </button>

          {(searchValue) ?
            <button
              style={{ float: 'left', marginLeft: '5px' }}
              type="submit"
              className="btn btn-primary"
              title="Clear Search"
              onClick={clearSearchHandler}
            >
              <i className="fa fa-eraser"></i>
            </button>
            : null}
        </div>
      </div>

      <div className="card radius-10">
        <div className="card-body" style={{ overflowX: 'auto' }}>
          <div className="table-responsive">
            <table className="table align-left mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Numbers</th>
                  <th>Date Created</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className="HoverChange">
                {(permissions.includes('contact_get_all_contact_list')) ?
                  ContactList.length > 0 ? ContactList.map((contact, index) => (
                    <tr key={contact.ContactListID}
                      onClick={() => handleColor(contact.ContactListID)}>
                      <th scope="row">
                        {index + (parseInt(page) * perPage - perPage) + 1}
                      </th>
                      {
                        (contact.ContactListID === ColorId) ?
                          <td style={{ 'color': '#004C97', fontWeight: 'bold', fontSize: '16px' }}>{contact.ContactListName}</td> :
                          <td>{contact.ContactListName}</td>
                      }
                      <td>{contact.Description}</td>
                      <td>{contact.Numbers}&nbsp;
                        {/* {contact.FileName ?
                          <a style={{ color: "blue", cursor: "pointer" }} href={contact.FileName}
                            rel="noreferrer" target="_blank" title="Download" download><i className="fa fa-download"></i></a> : null} */}

                      </td>
                      <td>{contact.Created}</td>
                      <td>
                        <Link to={"/add-contact-list/" + contact.ContactListID} 
                        className={permissions.includes('contact_edit_contact_list') ? '' : ' blur-buttons'} title="Edit">
                          <b className='editColor'>Edit</b> &nbsp;
                          {/* <i className="fa fa-edit"></i> */}
                        </Link>
                        <span
                          title="Remove"
                          onClick={() => deleteContact(contact.ContactListID)}
                          className={permissions.includes('contact_delete_contact_list') ? '' : ' blur-buttons'}
                          style={{ cursor: "pointer" }}
                        >
                          <b className='deleteColor'>Delete</b> &nbsp;
                          {/* <i className="fa fa-trash"></i> */}
                        </span>
                        <Link
                          title="View"
                          to={"view-single-contactlist/" + contact.ContactListID}
                          className={permissions.includes('contact_get_all_contact_list_file') ? '' : ' blur-buttons'}
                          style={{ cursor: "pointer" }}>
                            <b className='editColor'>View</b> &nbsp;
                          {/* <i className="fa fa-eye"></i> */}
                        </Link>
                      </td>
                    </tr>
                  )) : <tr><th colSpan="6"> No Record Found</th></tr> : null}
              </tbody>
            </table>
          </div>
          <RecordHandler no_of_records={no_of_records} recordHandler={recordHandler}/>

          <PaginationComp
            pages={pagination}
            link={"contact-list"}
            activePage={parseInt(page)}
            search={"search=" + searchValue} />
        </div>
      </div>
      <ToastContainer theme="colored" />
    </div>
  )
}
export default ViewContactList