import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import LoginImage from '../../images/login-img.png';
import Logo from '../../images/logo.png';
import axios from "../../axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";

const ResetPasswordAdmin = () => {
    const { resetToken } = useParams();
    const [passwordData, setPassword] = useState({
        Password: "",
        cpassword: "",
    });
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [ShowHidePassword, setShowHidePassword] = useState(false)
    const [ShowHideConfirmPassword, setShowHideConfirmPassword] = useState(false)
    const { Password, cpassword } = passwordData;

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('');
        }
    }, [error])

    useEffect(() => {
        if (success !== '') {
            toast.success(success);
            setSuccess('');
        }
    }, [success])

    const handleChange = (e) => {
        setPassword({ ...passwordData, [e.target.name]: e.target.value });
    };

    const submitPassword = (e) => {
        e.preventDefault();
        setError("");

        var passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*^+#=?&_])[A-Za-z\d@$#=+_!%*^?&]{8,}$/;
        if (Password === "") {
            setError("Password field is required")
            return false;
        } else if (Password.length < 8) {
            setError("Password minimum length should 8");
            return false;
        } else if (!passwordCheck.test(Password)) {
            console.log("first")
            setError((<div style={{ textAlign: 'left' }}>
                Password should contains from following:- <br />
                1) Uppercase letters: A to Z <br />
                2) Lowercase letters: a to z <br />
                3) Numbers: 0 to 9 <br />
                4) Special characters <br />
                5) Password should be minimum 8 characters</div>));
            return false;
        }

        if (Password !== cpassword) {
            setError("Password and confirm password are not matched!!");
            return false;
        }

        if (cpassword === '') {
            setError("Confirm password fields are required");
            return false;
        }
        axios
            .post("login/reset_password_admin", {
                Token: resetToken,
                Password: Password,
            })
            .then((response) => {
                setSuccess(response.data.data);
                setPassword({
                    Password: "",
                    cpassword: "",
                });
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status !== 200) {
                        setError(error.response.data.message);
                    }
                }
            });
    }
    return (
        <div>
            <div className="row">
                <div className="col-md-6 login-img" style={{ backgroundImage: 'url(' + LoginImage + ')' }}></div>

                <div className="col-md-6 text-left" style={{ padding: '10% 8%' }}>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <img src={Logo} alt="SYNERGISTIC" style={{
                                width: '70%',
                                height: 'auto',
                                display: 'block'
                            }} />
                        </div>
                    </div>

                    <form onSubmit={submitPassword}>
                        <div className="row">
                            <div className="col-md-12 mt-5">
                                {/* <h4 className="login-title">We received your reset password request. Please enter your new password!</h4> */}
                                <h4 className="login-title">Reset password</h4>
                            </div>
                            <div className="col-md-12 mb-2 mt-2 flex-center-end">
                                <i
                                    className={ShowHidePassword ? "password-icon fa fa-eye" : "password-icon fa fa-eye-slash"}
                                    onClick={() => setShowHidePassword(!ShowHidePassword)}>
                                </i>
                                <input
									type={ShowHidePassword ? "text" : "password"}
									className="form-control login-input"
									placeholder="New password"
									name="Password"
									value={Password}
									onChange={handleChange} />
                            </div>

                            <div className="col-md-12 mb-2 mt-2 flex-center-end">
                            <i
									className={ShowHideConfirmPassword ? "password-icon fa fa-eye" : "password-icon fa fa-eye-slash"}
									onClick={() => setShowHideConfirmPassword(!ShowHideConfirmPassword)}>
								</i>
                               <input
									type={ShowHideConfirmPassword ? "text" : "password"}
									className="form-control login-input"
									placeholder="Confirm password"
									name="cpassword"
									value={cpassword}
									onChange={handleChange} />
                            </div>
                            
                            <div className="col-md-12 mb-3 mt-3">
                                <input type="submit" className="btn btn-primary signin-btn" value="Change Password" />
                                <Link to="/login">
                                    <p style={{ color: "#fff" }}
                                        className="forgot-password privacy-link btn btn-primary signin-btn">Back to Login</p>
                                </Link>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
            <ToastContainer theme="colored" />
        </div>
    )
}
export default ResetPasswordAdmin;