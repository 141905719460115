import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router';
import axios from '../../axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import { Prompt } from 'react-router-dom';

const AddClientAccounts = () => {
    const permissions = useSelector((state) => state.getPermissions.permissions);
    const { accountId } = useParams();
    const history = useHistory();

    const [ClientAccountDetails, setClientAccounts] = useState({
        ClientAccountID: accountId || "",
        AccountName: "",
        AccountDescription: "",
        Department: "",
        AdministratorTitle: "",
        AdministratorFirstName: "",
        AdministratorLastName: "",
        AdministratorUsername: "",
        AdministratorEmailAddress: "",
        AdministratorContactNumber: "",
        CreditLimit: "0"
    });
    const [accountNameError, setAccountNameError] = useState("");
    const [accountDescError, setAccountDescError] = useState("");
    const [deptError, setDeptError] = useState("");
    const [titleError, setTitleError] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [userNameError, setUserNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [contactError, setContactError] = useState("");
    const [creditLimitError, setCreditLimitError] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loader, setLoader] = useState(false);
    const [FromDataChanged, setFromDataChanged] = useState(false)

    const { AccountName, AccountDescription, Department, AdministratorTitle, AdministratorFirstName, AdministratorLastName, AdministratorUsername, AdministratorEmailAddress, AdministratorContactNumber, CreditLimit } = ClientAccountDetails;

    const NormalField = (localStorage.getItem("ClientNormalField") ? localStorage.getItem("ClientNormalField") : "");

    const TitleField = (localStorage.getItem("ClientTitleField") ? localStorage.getItem("ClientTitleField") : "");

    var titleList = [
        'Mr', 'Ms', 'Mrs', 'Dr', 'Prof', 'Adv', 'Other'
    ]

    const handleChange = (e) => {
        var input = e.target.value
        if (e.target.name === "CreditLimit") {
            input = input.replace(/\D/g, "");
        }
        setClientAccounts({ ...ClientAccountDetails, [e.target.name]: input });
        setFromDataChanged(true)
    };

    useEffect(() => {
        if (accountId && permissions.includes('clientaccount_get')) {
            setLoader(true)
            axios
                .get(`clientaccount/get/${accountId}`)
                .then((response) => {
                    setClientAccounts({
                        ClientAccountID: response.data.data.ID,
                        AccountName: response.data.data.AccountName,
                        AccountDescription: response.data.data.AccountDescription,
                        Department: response.data.data.Department,
                        AdministratorTitle: response.data.data.AdministratorTitle,
                        AdministratorFirstName: response.data.data.AdministratorFirstName,
                        AdministratorLastName: response.data.data.AdministratorLastName,
                        AdministratorUsername: response.data.data.AdministratorUsername,
                        AdministratorEmailAddress: response.data.data.AdministratorEmailAddress,
                        AdministratorContactNumber: response.data.data.AdministratorContactNumber,
                        CreditLimit: response.data.data.CreditLimit
                    })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [history, accountId, permissions])

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    function validate() {
        var flag = true;
        setAccountNameError("");
        setAccountDescError("");
        setDeptError("");
        setTitleError("");
        setFirstNameError("");
        setLastNameError("");
        setUserNameError("");
        setEmailError("");
        setContactError("");
        setCreditLimitError("");

        if (AccountName === "") {
            flag = false;
            setAccountNameError("Account Name field is required")
        }

        if (AccountDescription === "") {
            flag = false;
            setAccountDescError("Account Description field is required")
        }

        if (Department === "") {
            flag = false;
            setDeptError("Department field is required")
        }

        if (AdministratorTitle === "") {
            flag = false;
            setTitleError("Title field is required")
        }

        if (AdministratorFirstName === "") {
            flag = false;
            setFirstNameError("Firstname field is required")
        }

        if (AdministratorLastName === "") {
            flag = false;
            setLastNameError("Lastname field is required")
        }
        if (AdministratorUsername === "") {
            flag = false;
            setUserNameError("Username field is required")
        }

        var Emailpattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (AdministratorEmailAddress === "") {
            flag = false
            setEmailError("Email Address field is required");
        } else if (!Emailpattern.test(AdministratorEmailAddress)) {
            flag = false
            setEmailError("Please enter valid email address");
        }

        var pattern = new RegExp(/^[0-9\b]+$/);
        if (AdministratorContactNumber === '') {
            flag = false
            setContactError("Contact field is required");

        } else if (AdministratorContactNumber.length < 10 || AdministratorContactNumber.length > 12) {
            flag = false
            setContactError("Please enter valid 10 or 12 digit contact number");

        } else if (!pattern.test(AdministratorContactNumber)) {
            flag = false
            setContactError("Please enter only number");

        }

        // if (CreditLimit === "") {
        //     flag = false;
        //     setCreditLimitError("Credit Limit field is required")
        // }
        return flag
    }

    const submitAccountDetails = (e) => {
        e.preventDefault();
        setSuccess("")
        setError("");
        const flag = validate()
        if (flag) {
            var url = (accountId) ? 'edit' : 'add';
            setLoader(true)
            axios.post('clientaccount/' + url, ClientAccountDetails)
                .then((response) => {
                    setSuccess(response.data.data.message);
                    // history.push(`/account/${clientId}`)
                    setFromDataChanged(false)
                    history.push({
                        pathname: "/client-accounts/",
                        state: { message: response.data.data.message }
                    })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }


    const cancelHandler = () => {
        history.push('/client-accounts')
    }

    const handleContact = (e) => {
        let getContact = e.target.value;
        getContact = getContact.replace(/[^0-9]/gi, "");
        setClientAccounts({ ...ClientAccountDetails, AdministratorContactNumber: getContact });
        setFromDataChanged(true)
    }

    return (
        <div>
            <Prompt
                when={FromDataChanged}
                message="You are navigating away from this screen. You will lose the typed information. Are you sure you want to leave this screen?"
            />
            {loader ? <Loader /> : null}
            <form onSubmit={submitAccountDetails}>
                <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                    <div className="row flex-a-center">
                        <div className="col-sm-6">
                            <h5 className="mb-0 custom-header-title"><strong>
                                {(accountId > 0) ? 'Edit' : 'Add New'} Client Account</strong></h5>
                        </div>
                        <div className="col-sm-6">
                            <div className="font-22 ms-auto confirm-btn">
                                <button type="submit" className={"btn btn-success px-5" + (permissions.includes('clientaccount_add') ? '' : ' blur-buttons')}>
                                    Save
                                </button>
                                &nbsp;
                                <button type="button" className="btn btn-danger px-4"
                                    onClick={cancelHandler}
                                >
                                    Cancel/Back
                                </button>
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                <div className="card card-custom">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="AccountName"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >
                                        Account Name<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <input
                                        autoFocus
                                        type="text"
                                        className="form-control mb-3"
                                        id="AccountName"
                                        name="AccountName"
                                        placeholder="Enter Account Name"
                                        value={AccountName || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {accountNameError}
                                    </h6>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="AccountDescription"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >
                                        Account Description<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-3"
                                        id="AccountDescription"
                                        name="AccountDescription"
                                        placeholder="Enter Account Description"
                                        value={AccountDescription || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {accountDescError}
                                    </h6>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="Department"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >
                                        Department<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-3"
                                        id="Department"
                                        name="Department"
                                        placeholder="Enter Department"
                                        value={Department || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {deptError}
                                    </h6>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="title"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >
                                        Title<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <select
                                        id="AdministratorTitle"
                                        name="AdministratorTitle"
                                        className="form-select mb-3"
                                        value={AdministratorTitle || ""}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Title</option>
                                        {titleList.map((titleValue, index) => {
                                            return <option key={index}
                                                value={titleValue}
                                                maxLength={TitleField}
                                            >
                                                {titleValue}
                                            </option>
                                        })}
                                    </select>
                                    {/* <input
                                        type="text"
                                        className="form-control mb-3"
                                        id="AdministratorTitle"
                                        name="AdministratorTitle"
                                        placeholder="Enter Title"
                                        value={AdministratorTitle || ''}
                                        onChange={handleChange}
                                        maxLength={TitleField}
                                    /> */}
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {titleError}
                                    </h6>
                                </div>
                            </div>
                        </div>



                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="AdministratorFirstName"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >
                                        First Name<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-3"
                                        id="AdministratorFirstName"
                                        name="AdministratorFirstName"
                                        placeholder="Enter First Name"
                                        value={AdministratorFirstName || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {firstNameError}
                                    </h6>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="last_name"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >
                                        Last Name<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-3"
                                        id="AdministratorLastName"
                                        name="AdministratorLastName"
                                        placeholder="Enter Last Name "
                                        value={AdministratorLastName || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {lastNameError}
                                    </h6>
                                </div>
                            </div>
                        </div>




                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="username"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >
                                        Username<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-3"
                                        id="AdministratorUsername"
                                        name="AdministratorUsername"
                                        placeholder="Enter Username "
                                        value={AdministratorUsername || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {userNameError}
                                    </h6>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="email_address"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >
                                        Email Address<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-3"
                                        id="AdministratorEmailAddress"
                                        name="AdministratorEmailAddress"
                                        placeholder="Enter Email Address "
                                        value={AdministratorEmailAddress || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {emailError}
                                    </h6>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="AdministratorContactNumber"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >
                                        Contact Number<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-3"
                                        id="AdministratorContactNumber"
                                        name="AdministratorContactNumber"
                                        placeholder="Enter your Contact Number eg.27820000000"
                                        value={AdministratorContactNumber || ''}
                                        onChange={handleContact}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {contactError}
                                    </h6>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="CreditLimit"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >
                                        Credit Limit
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-3"
                                        id="CreditLimit"
                                        name="CreditLimit"
                                        placeholder="Enter Credit Limit "
                                        value={CreditLimit || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {creditLimitError}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer theme="colored" />
            </form>
        </div>
    )
}
export default AddClientAccounts;