import React, { useState, useEffect } from 'react';
import LoginImage from '../images/login-img.png';
import Logo from '../images/logo.png';
import { Link } from 'react-router-dom'
import axios from '../axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';

const ForgetPassword = () => {
    const history = useHistory();
    const [Username, setUsername] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('');
        }
    }, [error])

    useEffect(() => {
        if (message !== '') {
            toast.success(message);
            setMessage('');
        }
    }, [message])

    const SubmitUsername = (e) => {
        e.preventDefault();
        setError("")
        if (Username === '') {
            setError("Please enter your Username")
            return false;
        }
        axios
            .post("login/forgot_password", { Username: Username })
            .then((response) => {
                setMessage(response.data.data);
                setUsername('')
                history.push({
                    pathname: '/login',
                    state: { success: response.data.data }
                })
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status !== 200) {
                        // console.log(error.response.data.message)
                        setError(error.response.data.message);
                    }
                }
            });
    }


    return (
        <div>
            <div className="row">
                <div className="col-md-6 login-img" style={{ backgroundImage: 'url(' + LoginImage + ')' }}></div>

                <div className="col-md-6 text-left" style={{ padding: '10% 8%' }}>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <img src={Logo} alt="SYNERGISTIC" style={{
                                width: '70%',
                                height: 'auto',
                                display: 'block'
                            }} />
                        </div>
                    </div>

                    <form onSubmit={SubmitUsername}>
                        <div className="row">
                            <div className="col-md-12 mt-5">
                                <h4 className="login-title">Username / Email</h4>
                            </div>
                            <div className="col-md-12 mb-2 mt-2">
                                <input
                                    type="text"
                                    className="form-control login-input"
                                    placeholder="Enter your Username or Email Address to Reset Password"
                                    name="Username"
                                    value={Username}
                                    onChange={(e) => setUsername(e.target.value)} />
                            </div>
                            <div className="col-md-12 mb-3 mt-3">
                                <input type="submit" className="btn btn-primary signin-btn" value="Send Link" />
                                <Link to="/login">
                                    <p
                                        style={{ color: "#fff" }}
                                        className="forgot-password privacy-link btn btn-primary signin-btn">Back to Login</p>
                                </Link>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
            <ToastContainer theme="colored" />
        </div>
    )
}
export default ForgetPassword;