import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from '../../axios';
import AlertBox from "../../components/AlertBox";
import PaginationComp from "../../components/PaginationComp";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";
import RecordHandler from "../../components/RecordHandler";

export const ViewGroup = () => {
    const permissions = useSelector((state) => state.getPermissions.permissions);
    const history = useHistory();
    var url = new URL(window.location.href);
    var searchTxt = url.searchParams.get("search");
    var pageNo = url.searchParams.get("page");

    const [groups, setGroups] = useState([])
    const [serviceCall, setServiceCall] = useState(true);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [search, setSearch] = useState(searchTxt || "");
    const [searchValue, setSearchValue] = useState(searchTxt || "");
    const [page, setPage] = useState(pageNo || 1);
    const [no_of_records, no_of_recordsHandler] = useState(10);
    const [pagination, setPagination] = useState([]);
    const [perPage, setPerPage] = useState(0);
    const [loader, setLoader] = useState(false);
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState("");
    const [alertId, setAlertId] = useState("");

    useEffect(() => {
        if (history.location.state !== undefined) {
            setSuccess(history.location.state.message)
        }
        if (serviceCall) {
            setLoader(true);
            axios.get("group/getall?page=" + page + "&search=" + searchValue + "&no_of_records=" + no_of_records)
                .then((response) => {
                    setGroups(response.data.data.groups);
                    setPagination(response.data.data.pagination);
                    setPerPage(response.data.data.per_page);
                    setServiceCall(false);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (
                            error.response.status === 401 ||
                            error.response.status === 403
                        ) {
                            history.push("/logout");
                        }
                        if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [history, no_of_records, page, searchValue, serviceCall])

    useEffect(() => {
        var url = new URL(window.location.href);
        var pageNo = url.searchParams.get("page");
        setSuccess("");
        setPage(pageNo || 1);
        setServiceCall(true);
    }, [history.location.search]);

    useEffect(() => {
        if (success) {
            toast.success(success, '', 3500);
            setSuccess('')
            history.location.state = undefined
        }
    }, [success, history])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    const searchHandler = () => {
        setSearchValue(search);
        setServiceCall(true);
        history.push({
            search: "page=1&search=" + search,
        });
    }

    const clearSearchHandler = () => {
        history.push({
            search: "page=1&search=",
        });
        setSearch('');
        setSearchValue('');
        setServiceCall(true);
    }

    const recordHandler = (e) => {
        no_of_recordsHandler(e.target.value)
        setPage(1)
        setServiceCall(true)
        history.push({
            search: "no_of_records=" + e.target.value + "&page=1&search=",
        });
    }

    const handleKeyEnter = (e) => {
        return (e.key === 'Enter') ? searchHandler() : null
    }

    const deleteGroup = (id) => {
        setAlert("Please confirm remove group?")
        setAlertId(id);
        setAlertType("delete");
    }

    const alertResponse = (response, type, alertId) => {
        if (response) {
            setLoader(true)
            axios
                .post("group/" + type, { GroupID: alertId })
                .then((response) => {
                    setSuccess(response.data.data);
                    setServiceCall(true);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response.status === 401 || error.response.status === 403) {
                        history.push("/logout");
                    } else if (error.response.status !== 200) {
                        setError(error.response.data.message);
                        setLoader(false)
                    }
                });
        }
        setAlert("");
        setAlertType("");
        setAlertId();
    }
    return (
        <div>
            {loader ? <Loader /> : null}
            {alert ? (
                <AlertBox
                    message={alert}
                    type={alertType}
                    alertId={alertId}
                    alertResponse={alertResponse}
                />
            ) : null}
            <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                <div className="row flex-a-center">
                    <div className="col-sm-6">
                        <h5 className="mb-0 custom-header-title"><strong>Group Management</strong></h5>
                    </div>
                    <div className="col-sm-6">
                        <div className="font-22 ms-auto confirm-btn">
                            <Link to="/add-group" className={"btn btn-primary px-4 mb-1" + (permissions.includes('group_add') ? '' : ' blur-buttons')}>
                                Add New
                            </Link>
                            {/* <button type="button" className="btn btn-primary px-4 mb-1"> Cancel/Back</button> */}
                        </div>
                    </div>
                </div>
            </div>
            <hr />

            <div className="row">
                <div className="col-sm-3">
                    <input
                        type="text"
                        className="form-control mb-1"
                        id="search"
                        name="search"
                        placeholder="Search Here"
                        value={search}
                        onKeyDown={(e) => handleKeyEnter(e)}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>

                <div className="col-sm-3">
                    <button
                        style={{ float: 'left' }}
                        type="submit"
                        className="btn btn-primary mb-1"
                        title="Search"
                        onClick={searchHandler}
                    >
                        <i className="fa fa-search"></i>
                    </button>

                    {(searchValue) ?
                        <button
                            style={{ float: 'left', marginLeft: '5px' }}
                            type="submit"
                            className="btn btn-primary"
                            title="Clear Search"
                            onClick={clearSearchHandler}
                        >
                            <i className="fa fa-eraser"></i>
                        </button>
                        : null}
                </div>
            </div>

            <div className="card radius-10">
                <div className="card-body" style={{ overflowX: 'auto' }}>
                    <div className="table-responsive">
                        <table className="table align-middle mb-0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="HoverChange">
                                {(permissions.includes('group_getall')) ?
                                    groups.length > 0 ? groups.map((group, index) => (
                                        <tr key={group.ID}>
                                            <th>
                                                {index + (parseInt(page) * perPage - perPage) + 1}
                                            </th>
                                            <td>{group.Name}</td>
                                            <td>{group.Description}</td>
                                            <td>
                                                <Link to={"/add-group/" + group.ID} title="Edit"
                                                    className={permissions.includes('group_edit') ? '' : ' blur-buttons'}
                                                >
                                                    <b className='editColor'>Edit</b> &nbsp;
                                                    {/* <i className="fa fa-edit"></i> */}
                                                </Link>
                                                <span
                                                    title="Delete"
                                                    onClick={() => deleteGroup(group.ID)}
                                                    className={permissions.includes('group_delete') ? '' : ' blur-buttons'}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <b className='deleteColor'>Delete</b> &nbsp;
                                                    {/* <i className="fa fa-trash"></i> */}
                                                </span>
                                            </td>
                                        </tr>
                                    )) : <tr><td className="text-center" colSpan="100" ><b>No Record found</b></td></tr> : null}
                            </tbody>
                        </table>
                    </div>
                    <RecordHandler no_of_records={no_of_records} recordHandler={recordHandler}/>
                    <PaginationComp
                        pages={pagination}
                        link={"group"}
                        activePage={parseInt(page)}
                        search={"se<RecordHandlerarch=" + searchValue}
                    />
                </div>
            </div>

            <ToastContainer theme="colored" />
        </div>
    );
};
