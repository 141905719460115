import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../axios';
import Loader from "../../components/Loader";
import PaginationComp from "../../components/PaginationComp";
import { useSelector } from "react-redux";
import RecordHandler from '../../components/RecordHandler';

const QuickeSendResults = () => {
    const permissions = useSelector((state) => state.getPermissions.permissions);
    const history = useHistory();
    var url = new URL(window.location.href);
    var searchTxt = url.searchParams.get("search");
    var pageNo = url.searchParams.get("page");

    const [resultsList, setResultList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [search, setSearch] = useState("");
    const [searchValue, setSearchValue] = useState(searchTxt || "");
    const [page, setPage] = useState(pageNo || 1);
    const [pagination, setPagination] = useState([]);
    const [perPage, setPerPage] = useState(0);
    const [no_of_records, no_of_recordsHandler] = useState(10);

    useEffect(() => {
        setLoader(true);
        axios.get("sendsms/get_result?no_of_records=" + no_of_records + "&page=" + page + "&search=" + searchValue)
            .then((response) => {
                setResultList(response.data.data.Results)
                setPagination(response.data.data.pagination);
                setPerPage(response.data.data.per_page);
                setLoader(false)
            })
            .catch((error) => {
                if (error.response) {
                    if (
                        error.response.status === 401 ||
                        error.response.status === 403
                    ) {
                        history.push("/logout");
                    }
                    if (error.response.status !== 200) {
                        setError(error.response.data.message);
                        setLoader(false)
                    }
                }
            })

    }, [history, no_of_records, page, searchValue])

    useEffect(() => {
        var url = new URL(window.location.href);
        var pageNo = url.searchParams.get("page");
        setSuccess('');
        setPage(pageNo || 1);
    }, [history.location.search]);


    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    const searchHandler = () => {
        setSearchValue(search);
        history.push({
            search: "page=1&search=" + search,
        });
    }

    const clearSearchHandler = () => {
        history.push({ search: "page=1&search=", });
        setSearch('');
        setSearchValue('');
    }

    const recordHandler = (e) => {
        no_of_recordsHandler(e.target.value)
        setPage(1)
        history.push({
            search: "no_of_records=" + e.target.value + "&page=1&search=",
        });
    }

    const handleKeyEnter = (e) => {
        return (e.key === 'Enter') ? searchHandler() : null
    }

    return (
        <div>
            {loader ? <Loader /> : null}
            <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                <div className="row flex-a-center">
                    <div className="col-sm-6">
                        <h5 className="mb-0 custom-header-title"><strong>Results</strong></h5>
                    </div>
                    <div className="col-sm-6">
                        <div className="font-22 ms-auto confirm-btn">
                            {/* <Link to="/quick-send" className="btn btn-primary px-4 mb-1">
                                Add New
                            </Link> */}
                            {/* <button type="button" className="btn btn-primary px-4 mb-1"> Cancel/Back</button> */}
                        </div>
                    </div>
                </div>
            </div>
            <hr />

            <div className="row">
                <div className="col-sm-3">
                    <input
                        type="text"
                        className="form-control mb-1"
                        id="search"
                        name="search"
                        placeholder="Search Here"
                        value={search}
                        onKeyDown={(e) => handleKeyEnter(e)}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>

                <div className="col-sm-3">
                    <button
                        style={{ float: 'left' }}
                        type="submit"
                        className="btn btn-primary mb-1"
                        title="Search"
                        onClick={searchHandler}
                    >
                        <i className="fa fa-search"></i>
                    </button>

                    {(searchValue) ?
                        <button
                            style={{ float: 'left', marginLeft: '5px' }}
                            type="submit"
                            className="btn btn-primary"
                            title="Clear Search"
                            onClick={clearSearchHandler}
                        >
                            <i className="fa fa-eraser"></i>
                        </button>
                        : null
                    }
                </div>
            </div>

            <div className="card radius-10">
                <div className="card-body" style={{ overflowX: 'auto' }}>
                    <div className="table-responsive">
                        <table className="table align-middle mb-0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Sequence No</th>
                                    <th>Reference No</th>
                                    <th>VASCRefNo</th>
                                    <th>Segments</th>
                                    <th>Status</th>
                                    <th>Client</th>
                                    {/* <th>ToGateID</th> */}
                                    <th>Network</th>
                                    <th>Product</th>
                                    <th>To Address</th>
                                    <th>Src Address</th>
                                    <th>Tag</th>
                                    {/* <th>Content</th> */}
                                    <th>Scheduled</th>
                                    <th>Expires</th>
                                    <th>Queued</th>
                                    <th>Submitted</th>
                                    <th>Acknowledged</th>
                                    <th>Receipted</th>
                                    <th>Failed</th>
                                    <th>Err Code</th>
                                    <th>Err Text</th>
                                    <th>Data Coding</th>

                                </tr>
                            </thead>
                            <tbody className="HoverChange">
                                {permissions.includes('sendsms_get_result') ?
                                    resultsList.length > 0 ? resultsList.map((result, index) => (
                                        <tr key={result.SeqNo}>
                                            <th scope="row">
                                                {index + (parseInt(page) * perPage - perPage) + 1}
                                            </th>
                                            <td>{result.SeqNo}</td>
                                            <td>{result.RefNo}</td>
                                            <td>{result.VASCRefNo}</td>
                                            <td>{result.Segments}</td>
                                            <td>{result.Status}</td>
                                            <td>{result.ClientName}</td>
                                            {/* <td>{result.ToGateID}</td> */}
                                            <td>{result.NetworkName}</td>
                                            <td>{result.Product}</td>
                                            <td>{result.ToAddress}</td>
                                            <td>{result.SrcAddress}</td>
                                            <td>{result.Tag}</td>
                                            {/* <td>{result.Content}</td> */}
                                            <td>{result.Scheduled}</td>
                                            <td>{result.Expires}</td>
                                            <td>{result.Queued}</td>
                                            <td>{result.Submitted}</td>
                                            <td>{result.Acknowledged}</td>
                                            <td>{result.Receipted}</td>
                                            <td>{result.Failed}</td>
                                            <td>{result.ErrCode}</td>
                                            <td>{result.ErrText}</td>
                                            <td>{result.DataCoding}</td>

                                        </tr>
                                    )) : <tr><td className="text-center" colSpan="100" ><b>No Record found</b></td></tr> : null}
                            </tbody>
                        </table>
                    </div>

                    <RecordHandler no_of_records={no_of_records} recordHandler={recordHandler}/>
                    <PaginationComp
                        pages={pagination}
                        link={"quick-send-result"}
                        activePage={parseInt(page)}
                        search={"search=" + searchValue} />
                </div>
            </div>
            <ToastContainer theme="colored" />
        </div>
    )
}
export default QuickeSendResults