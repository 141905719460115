import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router';
import axios from '../../../axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../../components/Loader";
import { Prompt } from 'react-router-dom';

const AddFTPConfigure = () => {
    const permissions = useSelector((state) => state.getPermissions.permissions);
    const { productid } = useParams();
    // const { clientId } = useParams();
    const { accountId } = useParams();
    const history = useHistory();

    const [FTPDetails, setFTPDetails] = useState({
        ProductID: productid || "",
        FtpType: "",
        AuthorisationType: "",
        Username: "",
        Authenticator: "",
        Hostname: "",
        Directory: "",
        Port: ""
    })
    const { FtpType, AuthorisationType, Username, Authenticator, Hostname, Directory, Port } = FTPDetails;
    const [loader, setLoader] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [ftpTypeError, setFtpTypeError] = useState("")
    const [authorisationError, setAuthorisationTypeError] = useState("")
    const [usernameError, setUsernameError] = useState("")
    const [authenticatorError, setAuthenticatorError] = useState("")
    const [hostnameError, setHostnameError] = useState("")
    const [portError, setPortError] = useState("")
    const [directoryError, setDirectoryError] = useState("")
    const [FromDataChanged, setFromDataChanged] = useState(false)

    const NormalField = (localStorage.getItem("ClientNormalField") ? localStorage.getItem("ClientNormalField") : "");

    const handleChange = (e) => {
        setFTPDetails({ ...FTPDetails, [e.target.name]: e.target.value });
        setFromDataChanged(true)
    }

    const validate = () => {
        var flag = true;
        setFtpTypeError("");
        setAuthorisationTypeError("");
        setUsernameError("");
        setAuthenticatorError("");
        setHostnameError("");
        setPortError("");
        setDirectoryError("");

        if (FtpType === "") {
            flag = false;
            setFtpTypeError("FTP Type field is required")
        }

        // if (AuthorisationType === "") {
        //     flag = false;
        //     setAuthorisationTypeError("Authorisation Type field is required")
        // }

        if (Username === "") {
            flag = false;
            setUsernameError("Username field is required")
        } else if (Username.length < 8 || Username.length > 50) {
            flag = false;
            setUsernameError("Username minimum string length is 8 or maximum string length is 50");
        }

        if (Authenticator === "") {
            flag = false;
            setAuthenticatorError("Authenticator field is required")
        } else if (Authenticator.length < 8 || Authenticator.length > 50) {
            flag = false;
            setAuthenticatorError("Authenticator minimum string length is 8 or maximum string length is 50");
        }

        var hostCheck = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
        var IpCheck = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        if (Hostname === "") {
            flag = false;
            setHostnameError("Hostname Field is required")
        } else if (!IpCheck.test(Hostname)) {
            if (!hostCheck.test(Hostname)) {
                flag = false;
                setHostnameError("Hostname String should match")
            }
        }

        var numberCheck = /^[0-9]*$/
        if (Port === "") {
            flag = false;
            setPortError("Port field is required")
        }
        if (Port !== "") {
            if (!numberCheck.test(Port)) {
                flag = false;
                setPortError("Port field should be in numeric only")
            } else if (parseInt(Port) < 1 || parseInt(Port) > 65535) {
                console.log(parseInt(Port));
                flag = false;
                setPortError("Port field should be in range upto 65535")
            }
        }

        var directoryPathCheck = /^([a-z]:((\\|\/|\\\\|\/\/))|(\\\\|\/\/))[^<>:"|?*]+/i
        if (Directory !== "") {
            if (!directoryPathCheck.test(Directory)) {
                flag = false;
                setDirectoryError("Directory field format doesn't match. For Ex:- 'C://www/pathname/pathname'")
            }
        }
        return flag;
    }

    const submiitFTPDetails = (e) => {
        e.preventDefault();
        setSuccess("")
        setError("");
        const flag = validate()
        if (flag) {
            setLoader(true)
            axios.post("product/add_api_ftp", FTPDetails)
                .then((response) => {
                    setFromDataChanged(false)
                    history.push({
                        pathname: "/add-client-product/" + accountId + "/" + productid,
                        state: { message: response.data.data }
                    })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    useEffect(() => {
        if (permissions.includes('product_get_product_config')) {
            setLoader(true);
            axios.get('product/get_product_config/' + productid)
                .then((response) => {
                    setFTPDetails({
                        ProductID: productid,
                        FtpType: (response.data.data.FtpType) ? response.data.data.FtpType : '',
                        AuthorisationType: (response.data.data.AuthorisationType) ? response.data.data.AuthorisationType : '',
                        Username: (response.data.data.Username) ? response.data.data.Username : '',
                        Authenticator: (response.data.data.Authenticator) ? response.data.data.Authenticator : '',
                        Hostname: (response.data.data.Hostname) ? response.data.data.Hostname : '',
                        Directory: (response.data.data.Directory) ? response.data.data.Directory : '',
                        Port: (response.data.data.Port) ? response.data.data.Port : ''
                    })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [history, productid, permissions])

    const backHandler = () => {
        history.push("/add-client-product/" + accountId + "/" + productid)
    }

    return (
        <div>
            <Prompt
                when={FromDataChanged}
                message="You are navigating away from this screen. You will lose the typed information. Are you sure you want to leave this screen?"
            />
            {loader ? <Loader /> : null}
            <form onSubmit={submiitFTPDetails}>
                <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                    <div className="row flex-a-center">
                        <div className="col-sm-6">
                            <h5 className="mb-0 custom-header-title"><strong>Add Configure - FTP</strong></h5>
                        </div>
                        <div className="col-sm-6">
                            <div className="font-22 ms-auto confirm-btn">
                                <button type="submit" className={"btn btn-success px-5" + (permissions.includes('product_add_api_ftp') ? '' : ' blur-buttons')}>
                                    Save
                                </button>
                                &nbsp;
                                <button type="button" className="btn btn-danger px-4"
                                    onClick={backHandler}>
                                    Cancel/Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                <div className="card card-custom">
                    <div className="card-body">

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >FTP Type<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <select
                                        autoFocus
                                        value={FtpType}
                                        className="form-select mb-3"
                                        name="FtpType"
                                        id="FtpType"
                                        aria-label="Default select example"
                                        onChange={handleChange}
                                    >
                                        <option value="">Choose FTP Type</option>
                                        <option value="FTP">FTP</option>
                                        <option value="FTPS">FTPS</option>
                                    </select>
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {ftpTypeError}
                                    </h6>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >Authorisation Type
                                    </label>
                                    <select
                                        value={AuthorisationType}
                                        className="form-select mb-3"
                                        name="AuthorisationType"
                                        id="AuthorisationType"
                                        aria-label="Default select example"
                                        onChange={handleChange}
                                    >
                                        <option value="">Choose Authorisation Type</option>
                                        {/* {
                                        catgeoryList.map((category) => {
                                            return <option key={category.ID} value={category.ID}>
                                                {category.Name}
                                            </option>
                                        })
                                    } */}
                                    </select>
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {authorisationError}
                                    </h6>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >Username
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-3"
                                        id="Username"
                                        name="Username"
                                        placeholder="Enter Username"
                                        value={Username || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {usernameError}
                                    </h6>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >Authenticator
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-3"
                                        id="Authenticator"
                                        name="Authenticator"
                                        placeholder="Enter Authenticator"
                                        value={Authenticator || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {authenticatorError}
                                    </h6>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >Hostname<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-3"
                                        id="Hostname"
                                        name="Hostname"
                                        placeholder="Enter Hostname"
                                        value={Hostname || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {hostnameError}
                                    </h6>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >Port<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-3"
                                        id="Port"
                                        name="Port"
                                        placeholder="Enter Port"
                                        value={Port || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {portError}
                                    </h6>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >Directory
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-3"
                                        id="Directory"
                                        name="Directory"
                                        placeholder="Enter Directory"
                                        value={Directory || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {directoryError}
                                    </h6>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
            <ToastContainer theme="colored" />
        </div>);
};
export default AddFTPConfigure;
