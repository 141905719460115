import React from 'react'

const RecordHandler = (props) => {
    return (
        <div>
            <div style={{ float: 'left' }}>
                No Of Records
                <select className="form-select" value={props.no_of_records} onChange={props.recordHandler}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
            </div>
        </div>
    )
}

export default RecordHandler