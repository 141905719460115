import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router';
import axios from '../../../axios';
import axiosu from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../../components/Loader";
import { Prompt } from 'react-router-dom';

const AddHTTPConfigure = () => {
    const permissions = useSelector((state) => state.getPermissions.permissions);
    const { productid } = useParams();
    // const { clientId } = useParams();
    const { accountId } = useParams();
    const history = useHistory();
    const [HTTPDetails, setHTTPDetails] = useState({
        ProductID: productid || "",
        Username: "",
        Password: "",
        CallbackURL: "",
        CallbackAuth: ""
    })
    const { Username, Password, CallbackURL, CallbackAuth } = HTTPDetails;

    const [ShowPassword, setShowPassword] = useState(false);
    const [loader, setLoader] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [UsernameError, setUsernameError] = useState("")
    const [PasswordError, setPasswordError] = useState("")
    const [CallbackURLError, setCallbackURLError] = useState("")
    const [CallbackAuthError, setCallbackAuthError] = useState("")
    const [FromDataChanged, setFromDataChanged] = useState(false)

    const NormalField = (localStorage.getItem("ClientNormalField") ? localStorage.getItem("ClientNormalField") : "");

    const handleChange = (e) => {
        setHTTPDetails({ ...HTTPDetails, [e.target.name]: e.target.value });
        setFromDataChanged(true)
    }

    const validate = () => {
        var flag = true;
        setUsernameError("");
        setPasswordError("");
        setCallbackURLError("");
        setCallbackAuthError("");

        if (Username === "") {
            flag = false;
            setUsernameError("Username field is required");
        } else if (Username.length < 8 || Username.length > 50) {
            flag = false;
            setUsernameError("Username minimum string length is 8 or maximum string length is 50")
        }

        if (Password === "") {
            flag = false;
            setPasswordError("Password field is required")
        } else if (Password.length < 8 || Password.length > 50) {
            flag = false;
            setPasswordError("Password minimum string length is 8 or maximum string length is 50")
        }

        if (CallbackURL === "") {
            flag = false;
            setCallbackURLError("Request URL field is required")
        }

        return flag;
    }

    const submiitHTTPDetails = (e) => {
        e.preventDefault();
        setSuccess("")
        setError("");
        const flag = validate()
        if (flag) {
            setLoader(true)
            axios.post("product/add_api_http", HTTPDetails)
                .then((response) => {
                    setFromDataChanged(false)
                    history.push({
                        pathname: "/add-client-product/" + accountId + "/" + productid,
                        state: { message: response.data.data }
                    })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    useEffect(() => {
        if (permissions.includes('product_get_product_config')) {
            setLoader(true);
            axios.get('product/get_product_config/' + productid)
                .then((response) => {
                    setHTTPDetails({
                        ProductID: productid,
                        Username: (response.data.data.Username) ? response.data.data.Username : '',
                        Password: (response.data.data.Password) ? response.data.data.Password : '',
                        CallbackURL: (response.data.data.CallbackURL) ? response.data.data.CallbackURL : '',
                        CallbackAuth: (response.data.data.CallbackAuth) ? response.data.data.CallbackAuth : ''
                    })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [history, productid, permissions])

    const backHandler = () => {
        history.push("/add-client-product/" + accountId + "/" + productid)
    }

    const testUrl = () => {
        setCallbackURLError("")

        if (CallbackURL === "") {
            setCallbackURLError("Request URL field is required")
        } else {
            var regex = new RegExp('^((https?:)?\\/\\/)?' + // protocol
                '(?:\\S+(?::\\S*)?@)?' + // authentication
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i');
            if (!regex.test(CallbackURL)) {
                setError("Please enter valid url");
                return false
            }

            setLoader(true)
            axiosu({
                method: "post",
                url: CallbackURL,
                timeout: 1000 * 5,
                headers: {
                    "Content-Type": "application/json"
                },
                data: {}
            })
                .then(response => {
                    setLoader(false)
                    setSuccess("Success")
                })
                .catch(error => {
                    if (error.code === 'ECONNABORTED') {
                        setError("Error - Request timeout");
                    } else {
                        if (error.response) {
                            if (error.response.status === 404) {
                                setError("Page not found");
                            }
                        } else {
                            setSuccess("Success")
                        }
                    }
                    setLoader(false)
                });
        }
    }

    return (
        <div>
             <Prompt
                when={FromDataChanged}
                message="You are navigating away from this screen. You will lose the typed information. Are you sure you want to leave this screen?"
            />
            {loader ? <Loader /> : null}
            <form onSubmit={submiitHTTPDetails}>
                <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                    <div className="row flex-a-center">
                        <div className="col-sm-6">
                            <h5 className="mb-0 custom-header-title"><strong>Add Configure - HTTP</strong></h5>
                        </div>
                        <div className="col-sm-6">
                            <div className="font-22 ms-auto confirm-btn">
                                <button type="button" className="btn btn-primary px-5" onClick={testUrl}>
                                    Test
                                </button>
                                &nbsp;
                                <button type="submit" className={"btn btn-success px-5" + (permissions.includes('product_add_api_http') ? '' : ' blur-buttons')}>
                                    Save
                                </button>
                                &nbsp;
                                <button type="button" className="btn btn-danger px-4"
                                    onClick={backHandler}>
                                    Cancel/Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                <div className="card card-custom">
                    <div className="card-body">

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >Username<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <input
                                        autoFocus
                                        type="text"
                                        className="form-control mb-3"
                                        id="Username"
                                        name="Username"
                                        placeholder="Enter Username"
                                        value={Username || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {UsernameError}
                                    </h6>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="Password"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >Password<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <div className="row">
                                        <div className="col-md-12 flex-center-end1">
                                            <i className={(ShowPassword) ? "fa fa-eye password-icon" : "fa fa-eye-slash password-icon"} style={{ color: (ShowPassword) ? 'blue' : 'gray' }} onClick={() => setShowPassword(!ShowPassword)}></i>
                                            <input
                                                type={ShowPassword ? "text" : "password"}
                                                className="form-control mb-3"
                                                id="Password"
                                                name="Password"
                                                placeholder="Enter Password"
                                                value={Password || ''}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {PasswordError}
                                    </h6>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="CallbackURL"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >Request URL<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-3"
                                        id="CallbackURL"
                                        name="CallbackURL"
                                        placeholder="Enter Request URL"
                                        value={CallbackURL || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {CallbackURLError}
                                    </h6>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="CallbackAuth"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >Authorization
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-3"
                                        id="CallbackAuth"
                                        name="CallbackAuth"
                                        placeholder="e.g. Basic U3luZXJnaXNOaWM6cGFzc3dvcmQ="
                                        value={CallbackAuth || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {CallbackAuthError}
                                    </h6>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
            <ToastContainer theme="colored" />
        </div>);
};
export default AddHTTPConfigure;
