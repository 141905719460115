import React, { useState, useEffect } from 'react'
import axios from '../../axios'
import { useHistory } from 'react-router';
import Loader from "../../components/Loader";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GroupPermissionList = (props) => {
    var groupId = props.groupId;
    var groupName = props.groupName;
    const history = useHistory();
    const [getGroupPermission, setGroupPermission] = useState([])
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true)
        axios
            .get(`user/get_permission/${groupId}`)
            .then((response) => {
                setGroupPermission(response.data.data)
                setLoader(false)
            })
            .catch((error) => {
                if (error.response.status === 401 || error.response.status === 403) {
                    history.push("/logout");
                } else if (error.response.status !== 200) {
                    setError(error.response.data.message);
                    setLoader(false)
                }
            })
    }, [groupId, history])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])

    return (
        <div>
            {loader ? <Loader /> : null}
            <div className="d-flex align-items-center">
                <div className="title-box">
                    <h5 className="mb-0"><strong> Group Permissions : {groupName} </strong></h5>
                    <button className="btn btn-danger px-4" onClick={props.hidePermisssionHandler}>
                        Cancel/Back
                    </button>
                </div>
            </div>
            <hr />


            <div className="card radius-10">
                <div className="card-body" style={{ overflowX: 'auto' }}>
                    <div className="table-responsive">
                        <table className="table align-middle mb-0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Permissions</th>
                                    <th>Applied</th>
                                </tr>
                            </thead>
                            <tbody className="HoverChange">
                                {getGroupPermission.length > 0 ? getGroupPermission.map((group, index) => (
                                    <tr key={group.PermissionID}>
                                        <th scope="row">
                                            {(index + 1)}
                                        </th>
                                        <td style={{ textAlign: "left" }}>{group.Name}</td>
                                        <td>
                                            <span title="Applied" style={{
                                                height: "20px",
                                                width: "20px",
                                                background: "green",
                                                display: "block",
                                                borderRadius: "15px"
                                            }}></span>
                                        </td>
                                    </tr>
                                )) : <tr><td className="text-center" colSpan="100" ><b>No Record found</b></td></tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {/* <ToastContainer theme="colored" /> */}
        </div>

    )
}
export default GroupPermissionList;