import React, { useEffect } from "react";
import "./App.css";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from './axios';
import { setPermission } from "./redux/actions/PermissionToken";

import Sidebar from "./components/Sidebar";
import Dashboard from "./components/Dashboard";
import Login from "./Authentication/Login";
import { setToken } from "./redux/actions/TokenAction";
import ForgetPassword from "./Authentication/ForgetPassword";
import ResetPassword from "./Authentication/ResetPassword";
import Logout from "./Authentication/Logout";
import ViewContactList from "./containers/ContactList/ViewContactList"
import AddContactList from "./containers/ContactList/AddContactList";
import ViewTemplate from "./containers/Template/ViewTemplate";
import AddTemplate from "./containers/Template/AddTemplate";
import ViewQuickSend from "./containers/QuickSend/ViewQuickSend";
import ViewSingleContactList from "./containers/ContactList/ViewSingleContactList";
import { Demo } from "./containers/Template/Demo";
// import NotFound from "./Authentication/NotFound";
import SubmitATicket from "./containers/Support/SubmitATicket";
import TermsConditions from "./containers/TermsConditions/TermsConditions";
import ViewCommonTerms from "./containers/Support/CommonTerms/ViewCommonTerms";
import ViewUser from "./containers/UserManagement/ViewUser";
import AddUser from "./containers/UserManagement/AddUser";
import UserPermission from "./containers/UserManagement/UserPermission";
import QuickeSendResults from "./containers/QuickSend/QuickeSendResults";
import ResetPasswordAdmin from "./containers/UserManagement/ResetPasswordAdmin";
import AddClientAccounts from "./containers/ClientAccounts/AddClientAccounts";
import ViewClientAccounts from "./containers/ClientAccounts/ViewClientAccounts";
import AddClientProduct from "./containers/ClientProducts/AddClientProduct";
import ViewClientProduct from "./containers/ClientProducts/ViewClientProduct";
import AddSMPPConfigure from "./containers/ClientProducts/APIConfigure/AddSMPPConfigure";
import AddHTTPConfigure from "./containers/ClientProducts/APIConfigure/AddHTTPConfigure";
import AddEmailConfigure from "./containers/ClientProducts/APIConfigure/AddEmailConfigure";
import AddFTPConfigure from "./containers/ClientProducts/APIConfigure/AddFTPConfigure";
import { ViewGroup } from "./containers/GroupMangement/ViewGroup";
import { AddGroup } from "./containers/GroupMangement/AddGroup";
import AddCTBConfigure from "./containers/ClientProducts/APIConfigure/AddCTBConfigure";
import GeneratePassword from "./containers/Password/GeneratePassword";
import TopUpBalance from "./containers/TopUpBalance/TopUpBalance";
import ViewPayments from "./containers/Billing/ViewPayments";
import HTTPRest from "./containers/API's/HTTPRest";
import HTTPSoapHtml from "./containers/API's/HTTPSoapHtml";
import Profile from "./containers/ClientProfile/Profile";
import PrivacyPolicy from "./containers/PrivacyPolicy/PrivacyPolicy"
import ViewReports from "./containers/Reports/ViewReports";
import AccountReport from "./containers/Reports/AccountReport";
import VolumePerProductReport from "./containers/Reports/VolumePerProductReport";
import DataTableExample from "./containers/DataTableExample";
import Inbox from "./containers/Inbox/Inbox";

// import ViewSearchContactList from "./containers/ContactList/ViewSearchContactList";

function App() {
  const history = useHistory();
  const location = useLocation();
  // const { resetToken } = useParams()

  const dispatch = useDispatch();
  if (location.pathname.includes("change-password") || location.pathname.includes("change-password-admin")) {
    localStorage.removeItem("token");
    localStorage.removeItem("clientFirstName");
    localStorage.removeItem("clientLastName");
    localStorage.removeItem("clientEmail");
    dispatch(setToken(''));
  }

  if (localStorage.getItem("token")) {
    dispatch(setToken(localStorage.getItem("token")));
  }

  const token = useSelector((state) => state.getToken.token);

  useEffect(() => {
    if (token) {
      axios.get('group/get_my_permissions')
        .then((response) => {
          dispatch(setPermission(response.data.data))
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
              history.push('/logout')
            } else if (error.response.status !== 200) {
              // setError(error.response.data.message);
            }
          }
        })
    }
  }, [token, history, dispatch])

  var routes = null;

  if (token) {
    routes = (
      <Switch>
        {/* <Route exact path="/" render={() => (<Sidebar><Dashboard /></Sidebar>)} /> */}
        <Route exact path="/dashboard" render={() => (<Sidebar><Dashboard /></Sidebar>)} />
        <Route exact path="/contact-list" render={() => (<Sidebar><ViewContactList /></Sidebar>)} />
        <Route exact path="/add-contact-list/:contactId" render={() => (<Sidebar><AddContactList /></Sidebar>)} />
        <Route exact path="/add-contact-list" render={() => (<Sidebar><AddContactList /></Sidebar>)} />
        <Route exact path="/template" render={() => (<Sidebar> <ViewTemplate /> </Sidebar>)} />
        <Route exact path="/add-template" render={() => (<Sidebar> <AddTemplate /> </Sidebar>)} />
        <Route exact path="/add-template/:templateId" render={() => (<Sidebar> <AddTemplate /> </Sidebar>)} />
        <Route exact path="/quick-send" render={() => (<Sidebar> <ViewQuickSend /> </Sidebar>)} />
        <Route exact path="/quick-send-result" render={() => (<Sidebar> <QuickeSendResults /> </Sidebar>)} />
        <Route exact path="/view-single-contactlist/:contactID" render={() => (<Sidebar> <ViewSingleContactList /> </Sidebar>)} />
        <Route exact path="/demo" render={() => (<Sidebar> <Demo /> </Sidebar>)} />
        <Route exact path="/submit-a-ticket" render={() => (<Sidebar> <SubmitATicket /> </Sidebar>)} />
        <Route exact path="/common-terms" render={() => (<Sidebar> <ViewCommonTerms /> </Sidebar>)} />
        <Route exact path="/terms" component={TermsConditions} />

        <Route exact path="/user" render={() => (<Sidebar> <ViewUser /> </Sidebar>)} />
        <Route exact path="/add-user" render={() => (<Sidebar> <AddUser /> </Sidebar>)} />
        <Route exact path="/add-user/:userId" render={() => (<Sidebar> <AddUser /> </Sidebar>)} />
        <Route exact path="/user-permissions" render={() => (<Sidebar> <UserPermission /> </Sidebar>)} />

        <Route exact path="/client-accounts" render={() => (<Sidebar> <ViewClientAccounts /> </Sidebar>)} />
        <Route exact path="/add-client-accounts" render={() => (<Sidebar> <AddClientAccounts /> </Sidebar>)} />
        <Route exact path="/add-client-accounts/:accountId" render={() => (<Sidebar> <AddClientAccounts /> </Sidebar>)} />
        <Route exact path="/add-apismpp/:accountId/:productid" render={() => (<Sidebar> <AddSMPPConfigure /> </Sidebar>)} />
        <Route exact path="/add-apihttp/:accountId/:productid" render={() => (<Sidebar> <AddHTTPConfigure /> </Sidebar>)} />
        <Route exact path="/add-apiemail/:accountId/:productid" render={() => (<Sidebar> <AddEmailConfigure /> </Sidebar>)} />
        <Route exact path="/add-apiftp/:accountId/:productid" render={() => (<Sidebar> <AddFTPConfigure /> </Sidebar>)} />
        <Route exact path="/view-apictb/:accountId/:productid" render={() => (<Sidebar> <AddCTBConfigure /> </Sidebar>)} />

        <Route exact path="/client-product/:accountId" render={() => (<Sidebar> <ViewClientProduct /> </Sidebar>)} />
        <Route exact path="/add-client-product/:accountId" render={() => (<Sidebar> <AddClientProduct /> </Sidebar>)} />
        <Route exact path="/add-client-product/:accountId/:productid" render={() => (<Sidebar> <AddClientProduct /> </Sidebar>)} />

        <Route exact path="/group" render={() => (<Sidebar> <ViewGroup /> </Sidebar>)} />
        <Route exact path="/add-group" render={() => (<Sidebar> <AddGroup /> </Sidebar>)} />
        <Route exact path="/add-group/:groupid" render={() => (<Sidebar> <AddGroup /> </Sidebar>)} />

        <Route exact path="/password" render={() => (<Sidebar> <GeneratePassword /> </Sidebar>)} />
        <Route exact path="/top-up" render={() => (<Sidebar> <TopUpBalance /> </Sidebar>)} />

        <Route exact path="/payments" render={() => (<Sidebar> <ViewPayments /> </Sidebar>)} />

        <Route exact path="/http-rest" render={() => (<Sidebar> <HTTPRest /> </Sidebar>)} />
        <Route exact path="/http-soap-html" render={() => (<Sidebar> <HTTPSoapHtml /> </Sidebar>)} />

        <Route exact path="/profile" render={() => (<Sidebar> <Profile /> </Sidebar>)} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />

        <Route exact path="/reports" render={() => (<Sidebar><ViewReports /></Sidebar>)} />
        <Route exact path="/reports/AccountReport" render={() => (<Sidebar><AccountReport /></Sidebar>)} />
        <Route exact path="/reports/VolumePerProductReport" render={() => (<Sidebar><VolumePerProductReport /></Sidebar>)} />

        <Route exact path="/test" render={() => (<Sidebar><DataTableExample /></Sidebar>)} />
        <Route exact path="/Inbox" render={() => (<Sidebar><Inbox /></Sidebar>)} />

        <Route exact path="/logout" component={Logout} />
        <Route exact path="/logout/:LogoutFlag" component={Logout} />
        <Route path="/" render={() => (<Sidebar><Dashboard /></Sidebar>)} />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/reset-password" component={ForgetPassword} />
        <Route exact path="/change-password/:resetToken" component={ResetPassword} />
        <Route exact path="/change-password-admin/:resetToken" component={ResetPasswordAdmin} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/terms" component={TermsConditions} />
        <Route exact path="/logout/:LogoutFlag" component={Logout} />
        <Route path="/" component={Login} />
      </Switch>
    );
  }


  return (

    <div className="App">{routes}</div>
  );
}

export default App;
