import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Loader from "../../components/Loader";
import { useHistory } from 'react-router';
import axios from '../../axios'
import { Prompt } from 'react-router-dom';

const GeneratePassword = () => {
    const history = useHistory();
    const [passwordDetails, setPasswordDetails] = useState({
        CurrentPassword: "",
        NewPassword: ""
    })
    const { CurrentPassword, NewPassword } = passwordDetails;

    const [ShowCurrentPassword, setShowCurrentPassword] = useState(false);
    const [ShowNewPassword, setShowNewPassword] = useState(false);
    const [ShowConfirmPassword, setShowConfirmPassword] = useState(false);

    const [loader, setLoader] = useState(false)
    const [ConfirmPassword, setConfirmPassword] = useState("")
    const [currentPasswordError, setCurrentPasswordError] = useState("")
    const [newPasswordError, setnewPasswordError] = useState("")
    const [confirmPasswordError, setConfirmPasswordError] = useState("")
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [warning, setWarning] = useState("");
    const [FromDataChanged, setFromDataChanged] = useState(false)

    const handleChange = (e) => {
        setPasswordDetails({ ...passwordDetails, [e.target.name]: e.target.value });
        setFromDataChanged(true)
    };

    useEffect(() => {
        if (history.location.state !== undefined) {
            setWarning(history.location.state.message)
        }
    }, [history])

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    useEffect(() => {
        if (warning) {
            toast.warning(warning, '', 3500);
            setWarning('')
            history.location.state = undefined
        }
    }, [warning, history])

    function validate() {
        var flag = true;
        setCurrentPasswordError("");
        setnewPasswordError("");
        setConfirmPasswordError("");

        if (CurrentPassword === "") {
            flag = false
            setCurrentPasswordError("Current Password field is required");
        }

        var passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*^+#=?&_])[A-Za-z\d@$#=+_!%*^?&]{8,}$/;
        if (NewPassword === "") {
            flag = false
            setnewPasswordError("New Password field is required");
        } else if (NewPassword.length < 8) {
            flag = false;
            setnewPasswordError("New Password minmum length should 8")
        }
        else if (!passwordCheck.test(NewPassword)) {
            flag = false;
            setnewPasswordError("Password should contains from following:- <br/> 1) Uppercase letters: A to Z <br/> 2) Lowercase letters: a to z <br/> 3) Numbers: 0 to 9 <br/> 4) Special characters <br/> 5) Password should be minimum 8 characters ")
        }

        if (ConfirmPassword === "") {
            flag = false
            setConfirmPasswordError("Confirm Password field is required");
        } else if (NewPassword !== ConfirmPassword) {
            flag = false
            setConfirmPasswordError("New Password and Confirm Password are not matching")
        }
        return flag
    }

    const submitPasswordDetails = (e) => {
        e.preventDefault();
        setSuccess("")
        setError("");
        const flag = validate()
        // var url = (groupid) ? 'edit' : 'add';
        if (flag) {
            setLoader(true)
            axios
                .post("password/generate_password", passwordDetails)
                .then((response) => {
                    setFromDataChanged(false)
                    setPasswordDetails({
                        CurrentPassword: "",
                        NewPassword: ""
                    })
                    setConfirmPassword("")
                    // setSuccess(response.data.data.message);
                    setLoader(false)
                    localStorage.removeItem("password_status")
                    history.push({
                        pathname: '/dashboard',
                        state: { message: response.data.data.message }
                    })
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.data.message);
                            setLoader(false)
                        }
                    }
                });
        }
    };


    return (
        <div>
              <Prompt
                when={FromDataChanged}
                message="You are navigating away from this screen. You will lose the typed information. Are you sure you want to leave this screen?"
            />
            {loader ? <Loader /> : null}
            <form onSubmit={submitPasswordDetails}>
                <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                    <div className="row flex-a-center">
                        <div className="col-sm-6">
                            <h5 className="mb-0 custom-header-title"><strong>Change Password</strong></h5>
                        </div>
                        <div className="col-sm-6">
                            <div className="font-22 ms-auto confirm-btn">
                                <button type="submit" className="btn btn-success px-5">
                                    Save
                                </button>
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                <div className="card card-custom">
                    <div className="card-body">


                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >
                                        Current Password<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <div className="row">
                                        <div className="col-md-12 flex-center-end1">
                                            <i className={(ShowCurrentPassword) ? "fa fa-eye password-icon" : "fa fa-eye-slash password-icon"} style={{ color: (ShowCurrentPassword) ? 'blue' : 'gray' }} onClick={() => setShowCurrentPassword(!ShowCurrentPassword)}></i>
                                            <input
                                                type={ShowCurrentPassword ? "text" : "password"}
                                                className="form-control mb-3"
                                                id="CurrentPassword"
                                                name="CurrentPassword"
                                                placeholder="Enter Current Password"
                                                value={CurrentPassword || ''}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {currentPasswordError}
                                    </h6>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="inputEnterYourSurname"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >
                                        New Password<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <div className="row">
                                        <div className="col-md-12 flex-center-end1">
                                            <i className={(ShowNewPassword) ? "fa fa-eye password-icon" : "fa fa-eye-slash password-icon"} style={{ color: (ShowNewPassword) ? 'blue' : 'gray' }} onClick={() => setShowNewPassword(!ShowNewPassword)}></i>
                                            <input
                                                type={ShowNewPassword ? "text" : "password"}
                                                className="form-control mb-3"
                                                id="NewPassword"
                                                name="NewPassword"
                                                placeholder="Enter New Password"
                                                value={NewPassword || ''}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <h6 dangerouslySetInnerHTML={{ __html: newPasswordError }} className="mb-0" style={{ color: "red" }}>

                                    </h6>
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-sm-6">
                                    <div className="mt-3 mb-3">
                                        <label
                                            htmlFor="inputEnterYourSurname"
                                            className="form-label form-lable-custom mt-3 mb-3"
                                        >
                                            Confirm New Password<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                        </label>
                                        <div className="row">
                                            <div className="col-md-12 flex-center-end1">
                                                <i className={(ShowConfirmPassword) ? "fa fa-eye password-icon" : "fa fa-eye-slash password-icon"} style={{ color: (ShowConfirmPassword) ? 'blue' : 'gray' }} onClick={() => setShowConfirmPassword(!ShowConfirmPassword)}></i>
                                                <input
                                                    type={ShowConfirmPassword ? "text" : "password"}
                                                    className="form-control mb-3"
                                                    id="ConfirmPassword"
                                                    name="ConfirmPassword"
                                                    placeholder="Confirm New Password"
                                                    value={ConfirmPassword || ''}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <h6 className="mb-0" style={{ color: "red" }}>
                                            {confirmPasswordError}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <ToastContainer theme="colored" />
        </div>
    )
}

export default GeneratePassword