import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Loader from "../../components/Loader";
import PaginationComp from '../../components/PaginationComp';
import axios from '../../axios';
import AlertBox from '../../components/AlertBox';
import { useSelector } from "react-redux";
import RecordHandler from '../../components/RecordHandler';

const ViewClientAccounts = () => {
    const permissions = useSelector((state) => state.getPermissions.permissions);
    const history = useHistory();
    var url = new URL(window.location.href);
    var searchTxt = url.searchParams.get("search");
    var pageNo = url.searchParams.get("page");
    var statusTxt = url.searchParams.get("status");

    const [clientAccountList, setClientAccountList] = useState([])
    const [serviceCall, setServiceCall] = useState(true);
    const [search, setSearch] = useState(searchTxt || "");
    const [searchValue, setSearchValue] = useState(searchTxt || "");
    const [page, setPage] = useState(pageNo || 1);
    const [no_of_records, no_of_recordsHandler] = useState(10);
    const [pagination, setPagination] = useState([]);
    const [loader, setLoader] = useState(false);
    const [status, setStatus] = useState(statusTxt || "");
    const [statusValue, setStatusValue] = useState(statusTxt || "");
    const [perPage, setPerPage] = useState(0);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState("");
    const [alertId, setAlertId] = useState("");

    useEffect(() => {
        if (history.location.state !== undefined) {
            setSuccess(history.location.state.message)
        }
        if (serviceCall) {
            setLoader(true)
            axios
                .get(`/clientaccount/getall?no_of_records=` + no_of_records + "&page=" + page + "&search=" + searchValue +
                    "&status=" + statusValue)
                .then((response) => {
                    setClientAccountList(response.data.data.clientAccount)
                    setPagination(response.data.data.pagination);
                    setPerPage(response.data.data.per_page);
                    setServiceCall(false);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push("/logout");
                        }
                        if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [no_of_records, page, searchValue, history, serviceCall, statusValue])

    useEffect(() => {
        var url = new URL(window.location.href);
        var pageNo = url.searchParams.get("page");
        setSuccess("");
        setPage(pageNo || 1);
        setServiceCall(true);
    }, [history.location.search]);

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
            history.location.state = undefined
        }
    }, [success, history])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    const searchHandler = () => {
        setSearchValue(search);
        setStatusValue(status);
        setServiceCall(true);
        console.log(history)
        history.push({
            search: "page=1&search=" + search + "&status=" + status
        });
    }

    const clearSearchHandler = () => {
        history.push({ search: "page=1&search=&status=" });
        setSearch('');
        setStatus('');
        setSearchValue('');
        setStatusValue('');
        setServiceCall(true);
    }

    const recordHandler = (e) => {
        no_of_recordsHandler(e.target.value)
        setPage(1)
        setServiceCall(true)
        history.push({
            search: "no_of_records=" + e.target.value + "&page=1&search=&status=",
        });
    }

    const deleteHandler = (ClientAccountID) => {
        setAlert("Please confirm Remove Client Account?");
        setAlertType("delete");
        setAlertId(ClientAccountID);
    }

    const enabledisable = (id, accountStatus) => {
        var getStatus = (accountStatus === 'Enabled' ? 'Disable' : 'Enable')
        var type = (accountStatus === 'Enabled') ? 'change_status_disable' : 'change_status_enable';
        setAlert("Please confirm " + getStatus + " Client Account?");
        setAlertType(type);
        setAlertId(id);
    }

    const alertResponse = (response, type, alertId) => {
        if (response) {
            setLoader(true)
            axios
                .post("clientaccount/" + type, { ClientAccountID: alertId })
                .then((response) => {
                    setSuccess(response.data.data);
                    setServiceCall(true);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push("/logout");
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                });
        }
        setAlert("");
        setAlertType("");
        setAlertId();
    };

    const handleKeyEnter = (e) => {
        return (e.key === 'Enter') ? searchHandler() : null
    }

    return (
        <div>
            {loader ? <Loader /> : null}
            {alert ? (
                <AlertBox
                    message={alert}
                    type={alertType}
                    alertId={alertId}
                    alertResponse={alertResponse}
                />
            ) : null}
            <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                <div className="row flex-a-center">
                    <div className="col-sm-6">
                        <h5 className="mb-0 custom-header-title"><strong>Manage Client Accounts</strong></h5>
                    </div>
                    <div className="col-sm-6">
                        <div className="font-22 ms-auto confirm-btn">
                            <Link to="/add-client-accounts" className={"btn btn-primary px-4 mb-1" + (permissions.includes('clientaccount_add') ? '' : ' blur-buttons')}>
                                Add New
                            </Link>
                            {/* &nbsp;
                            <button type="button" className="btn btn-primary px-4 mb-1"
                            // onClick={cancelHandler}
                            >Cancel/Back</button> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="d-flex align-items-center">
                <h5>{FirstName} Main Account </h5>
            </div> */}
            <hr />

            <div className="row">
                <div className="col-sm-3">
                    <input
                        type="text"
                        className="form-control mb-1"
                        id="search"
                        name="search"
                        placeholder="Search Here"
                        value={search}
                        onKeyDown={(e) => handleKeyEnter(e)}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>

                <div className="col-sm-3">
                    <select
                        value={status}
                        className="form-select mb-3"
                        name="status"
                        id="status"
                        aria-label="Default select example"
                        onChange={(e) => setStatus(e.target.value)}
                    >
                        <option value="">Choose Status</option>
                        <option value="Enabled">Enabled</option>
                        <option value="Disabled">Disabled</option>
                    </select>
                </div>

                <div className="col-sm-3">
                    <button
                        style={{ float: 'left' }}
                        type="submit"
                        className="btn btn-primary mb-1"
                        title="Search"
                        onClick={searchHandler}
                    >
                        <i className="fa fa-search"></i>
                    </button>

                    {(searchValue || statusValue) ?
                        <button
                            style={{ float: "left", marginLeft: '5px' }}
                            type="submit"
                            className="btn btn-primary mb-1"
                            title="Clear Search"
                            onClick={clearSearchHandler}
                        >
                            <i className="fa fa-eraser"></i>
                        </button>
                        : null}
                </div>
            </div>


            <div className="card radius-10">
                <div className="card-body" style={{ overflowX: 'auto' }}>
                    <div className="table-responsive">
                        <table className="table align-middle mb-0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Account Name</th>
                                    <th>Credit Limit</th>
                                    <th>Current Balance</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="HoverChange">
                                {(permissions.includes('clientaccount_getall')) ?
                                    clientAccountList.length > 0 ? clientAccountList.map((account, index) => (
                                        <tr key={account.ID}>
                                            <th>
                                                {index + (parseInt(page) * perPage - perPage) + 1}
                                            </th>
                                            <td>{account.AccountName}</td>
                                            <td>{account.CreditLimit}</td>
                                            <td>{account.CurrentBalance}</td>
                                            <td>
                                                <span
                                                    title="Change Status"
                                                    onClick={() => enabledisable(account.ID, account.Status)}
                                                    style={{ cursor: "pointer" }}
                                                    className={(account.Status === 'Enabled') ? (permissions.includes('clientaccount_change_status_disable') ? '' : ' blur-buttons') : (permissions.includes('clientaccount_change_status_enable') ? '' : ' blur-buttons')}
                                                >
                                                    {(account.Status === 'Enabled') ? <i style={{ color: "green" }} className="fa fa-check-circle"></i> :
                                                        <i style={{ color: "red" }} className="fa fa-times-circle"></i>}
                                                </span>

                                            </td>
                                            <td>

                                                <Link to={`/add-client-accounts/` + account.ID} title="Edit"
                                                    className={permissions.includes('clientaccount_edit') ? '' : ' blur-buttons'}>
                                                    <b className='editColor'>Edit</b> &nbsp;
                                                    {/* <i className="fa fa-edit"></i> */}
                                                </Link>

                                                <span
                                                    title="Delete"
                                                    className={permissions.includes('clientaccount_delete') ? '' : ' blur-buttons'}
                                                    onClick={() => deleteHandler(account.ID)}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <b className='deleteColor'>Delete</b> &nbsp;
                                                    {/* <i className="fa fa-trash"></i> */}
                                                </span>

                                                {/* <span
                                                    title="Change Status"
                                                    onClick={() => enabledisable(account.ID, account.Status)}
                                                    style={{ cursor: "pointer" }}
                                                    className={(account.Status === 'Enabled') ? (permissions.includes('clientaccount_change_status_disable') ? '' : ' blur-buttons') : (permissions.includes('clientaccount_change_status_enable') ? '' : ' blur-buttons')}
                                                >
                                                    {(account.Status === 'Enabled') ? <i style={{ color: "red" }} className="fa fa-times-circle"></i> :
                                                        <i style={{ color: "green" }} className="fa fa-check-circle"></i>}
                                                </span> */}

                                                <Link to={"/client-product/" + account.ID}
                                                    title="View Client Product"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <b className='editColor'>Product</b> &nbsp;
                                                    {/* <i className="fa fa-flag"></i> */}
                                                </Link>
                                            </td>
                                        </tr>
                                    )) : <tr><td className="text-center" colSpan="100" ><b>No Record found</b></td></tr> : null}
                            </tbody>
                        </table>
                    </div>
                    <RecordHandler no_of_records={no_of_records} recordHandler={recordHandler} />
                    <PaginationComp
                        pages={pagination}
                        link="/client-account"
                        activePage={parseInt(page)}
                        search={"search=" + searchValue}
                    />
                </div>
            </div>
            <ToastContainer theme="colored" />
        </div>
    )
}
export default ViewClientAccounts;