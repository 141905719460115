import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router';
import axios from '../../../axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../../components/Loader";
import { Prompt } from 'react-router-dom';

const AddCTBConfigure = () => {

    const permissions = useSelector((state) => state.getPermissions.permissions);
    const { productid } = useParams();
    const { accountId } = useParams();

    const history = useHistory();

    const [ctbDetails, setCTBDetails] = useState({
        ProductID: productid || "",
        ServiceID: "",
        PartnerID: "",
        ContentName: "",
        RedirectURL: ""
    });

    const [ServiceIDError, setServiceIDError] = useState("");
    const [PartnerIDError, setPartnerIDError] = useState("");
    const [ContentNameError, setContentNameError] = useState("");
    const [RedirectURLError, setRedirectURLError] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loader, setLoader] = useState(false);
    const [FromDataChanged, setFromDataChanged] = useState(false)

    const { ServiceID, PartnerID, ContentName, RedirectURL } = ctbDetails;

    const handleChange = (e) => {
        setCTBDetails({ ...ctbDetails, [e.target.name]: e.target.value });
        setFromDataChanged(true)
    };

    const NormalField = (localStorage.getItem("ClientNormalField") ? localStorage.getItem("ClientNormalField") : "");

    useEffect(() => {
        if (permissions.includes('product_get_product_config')) {
            setLoader(true);
            axios.get('product/get_config_ctb/' + productid)
                .then((response) => {
                    setCTBDetails({
                        ProductID: productid,
                        ServiceID: (response.data.data.ServiceID ? response.data.data.ServiceID : ''),
                        PartnerID: (response.data.data.PartnerID) ? response.data.data.PartnerID : '',
                        ContentName: (response.data.data.ContentName) ? response.data.data.ContentName : '',
                        RedirectURL: (response.data.data.RedirectURL) ? response.data.data.RedirectURL : ''

                    })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [history, productid, permissions])


    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    function validate() {
        var flag = true;
        setServiceIDError("");
        setPartnerIDError("");
        setContentNameError("");
        setRedirectURLError("");

        if (ServiceID === "") {
            flag = false
            setServiceIDError("Service ID field is required");
        }

        if (PartnerID === "") {
            flag = false
            setPartnerIDError("Partner ID field is required");
        }

        if (ContentName === "") {
            flag = false
            setContentNameError("Content Name field is required");
        }

        if (RedirectURL === "") {
            flag = false
            setRedirectURLError("Redirect URL field is required");
        } else {
            var regex = new RegExp('^((https?:)?\\/\\/)?' + // protocol
                '(?:\\S+(?::\\S*)?@)?' + // authentication
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i');
            if (!regex.test(RedirectURL)) {
                setRedirectURLError("Please enter valid url");
                return false
            }
        }
        return flag
    }

    const submitGroupDetails = (e) => {
        e.preventDefault();
        setSuccess("")
        setError("");
        const flag = validate()
        if (flag) {
            setLoader(true)
            axios
                .post("product/add_api_ctb", ctbDetails)
                .then((response) => {
                    setSuccess(response.data.data.message);
                    setFromDataChanged(false)
                    history.push({
                        pathname: "/add-client-product/" + accountId + "/" + productid,
                        state: { message: response.data.data }
                    })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                });
        }
    };

    const cancelHandler = () => {
        history.push("/add-client-product/" + accountId + "/" + productid)
    }


    return (
        <div>
            <Prompt
                when={FromDataChanged}
                message="You are navigating away from this screen. You will lose the typed information. Are you sure you want to leave this screen?"
            />
            <form onSubmit={submitGroupDetails}>
                {loader ? <Loader /> : null}

                <div>
                    <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                        <div className="row flex-a-center">
                            <div className="col-sm-6">
                                <h5 className="mb-0 custom-header-title"><strong> Add Configure - CTB</strong></h5>
                            </div>
                            <div className="col-sm-6">
                                <div className="font-22 ms-auto confirm-btn">
                                    <button type="submit" className="btn btn-success px-5">
                                        Save
                                    </button>
                                    <button type="button" className="btn btn-danger px-4" onClick={cancelHandler}>
                                        Cancel/Back
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />

                    <div className="card card-custom">
                        <div className="card-body">


                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="mt-3 mb-3">
                                        <label
                                            htmlFor="inputEnterYourName"
                                            className="form-label form-lable-custom mt-3 mb-3"
                                        >
                                            Service ID<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control mb-3"
                                            id="ServiceID"
                                            name="ServiceID"
                                            placeholder="Enter Service ID"
                                            value={ServiceID || ''}
                                            onChange={handleChange}
                                            maxLength={NormalField}
                                        />
                                        <h6 className="mb-0" style={{ color: "red" }}>
                                            {ServiceIDError}
                                        </h6>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="mt-3 mb-3">
                                        <label
                                            htmlFor="inputEnterYourSurname"
                                            className="form-label form-lable-custom mt-3 mb-3"
                                        >
                                            Partner ID<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control mb-3"
                                            id="PartnerID"
                                            name="PartnerID"
                                            placeholder="Enter Partner ID"
                                            value={PartnerID || ''}
                                            onChange={handleChange}
                                            maxLength={NormalField}
                                        />
                                        <h6 className="mb-0" style={{ color: "red" }}>
                                            {PartnerIDError}
                                        </h6>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="mt-3 mb-3">
                                        <label
                                            htmlFor="inputEnterYourSurname"
                                            className="form-label form-lable-custom mt-3 mb-3"
                                        >
                                            Content Name<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control mb-3"
                                            id="ContentName"
                                            name="ContentName"
                                            placeholder="Enter Content Name"
                                            value={ContentName || ''}
                                            onChange={handleChange}
                                            maxLength={NormalField}
                                        />
                                        <h6 className="mb-0" style={{ color: "red" }}>
                                            {ContentNameError}
                                        </h6>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="mt-3 mb-3">
                                        <label
                                            htmlFor="inputEnterYourSurname"
                                            className="form-label form-lable-custom mt-3 mb-3"
                                        >
                                            Redirect URL<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control mb-3"
                                            id="RedirectURL"
                                            name="RedirectURL"
                                            placeholder="Enter Redirect URL"
                                            value={RedirectURL || ''}
                                            onChange={handleChange}
                                            maxLength={NormalField}
                                        />
                                        <h6 className="mb-0" style={{ color: "red" }}>
                                            {RedirectURLError}
                                        </h6>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                <ToastContainer theme="colored" />
            </form>
        </div>
    )
}

export default AddCTBConfigure