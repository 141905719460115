import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const ChartView = () => {

    const options = {
        chart: {
            type: 'column'
        },
        colors: ['rgb(110,167,251)', 'rgb(19,109,250)'],
        title: {
            text: null
        },
        xAxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
        },
        yAxis: {
            labels: {
                format: '{value}'
            },
            min: 0,
            title: {
                text: null
            }
        },
        tooltip: {
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.percentage:.0f}%</b><br/>',
            shared: true
        },
        plotOptions: {
            column: {
                stacking: 'normal'
            }
        },
        series: [
            {
                name: 'Legend',
                data: [14.8, 7.4, 15.7, 12.4, 8, 59, 2.9, 8, 0.9, 1.6]
            },
            {
                name: 'Legend',
                data: [5.2, 4.8, 0.1, 5, 4.2, 3.3, 17.6, 3.6, 0.6, 40.3]
            }
        ]
    }

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}

export default ChartView