import React, { Component } from 'react'
import '../components/Accordion.css'

class Accordionr extends Component {
    state = {
        showBody: true,
        checkAll: false,
        setCheck:""
    }

    handleClick = () => {
        this.setState({
            showBody: !this.state.showBody
        })
    }

    handleChecked = (checked) =>{
        this.setState({
            checkAll: !this.state.checkAll
        })
        console.log(checked)
        console.log(this.checkAll)
    }


    render() {
        return (
            <div className={`${this.showBody ? "active" : ""}`}>
                <div className="accordian-head">
                    <label>{this.props.title}</label> 
                    <span>
                        {
                        (this.props.Value.length > 0 && !Array.isArray(this.props.Value[0].Value)) ?
                        <input
                            style={{marginLeft:"100px"}}
                            className="form-check-input"
                            type="checkbox"
                            onChange={(e) => {this.props.handleParent(e.target.checked,this.props.parentIndex)}}
                        /> : null
                        }
                          
                    </span>

                    {this.state.showBody ? <i  onClick={() => this.handleClick()} style={{float:'right',marginRight:'5px'}} className="fadeIn animated bx bx-chevron-up-circle" ></i> : <i onClick={() => this.handleClick()} style={{float:'right',marginRight:'5px'}} className="fadeIn animated bx bx-chevron-down-circle"></i>}

                </div>
                {
                    this.state.showBody ?
                        <div className={`accordion-body ${this.showBody ? "open" : ""}`}>
                            <div className="answer">
                            {this.props.body}
                            </div>
                        </div>
                        : null
                }

            </div>
            
        )
    }
}

export default Accordionr;