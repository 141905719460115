import React, { useState, useEffect } from 'react'
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"

const HTTPRest = () => {

  const [url, setUrl] = useState("")

  useEffect(() => {
    setUrl("https://petstore.swagger.io/v2/swagger.json")
  },[url])

  return (
    <div>
      <SwaggerUI url={url} />
    </div>
  )
}

export default HTTPRest