import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AlertBox from '../../components/AlertBox';
import axios from "../../axios";
import PaginationComp from '../../components/PaginationComp';
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import RecordHandler from '../../components/RecordHandler';

const ViewClientProduct = () => {
    const permissions = useSelector((state) => state.getPermissions.permissions);
    const history = useHistory();
    const { accountId } = useParams();
    var url = new URL(window.location.href);
    var searchTxt = url.searchParams.get("search");
    var pageNo = url.searchParams.get("page");

    const [getProductDetails, setProductDetails] = useState([]);
    const [search, setSearch] = useState(searchTxt || "");
    const [searchValue, setSearchValue] = useState(searchTxt || "");
    const [page, setPage] = useState(pageNo || 1);
    const [pagination, setPagination] = useState([]);
    const [perPage, setPerPage] = useState(0);
    const [no_of_records, no_of_recordsHandler] = useState(10);
    const [serviceCall, setServiceCall] = useState(true);
    const [loader, setLoader] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState("");
    const [alertId, setAlertId] = useState("");
    const [AccountName, setAccountName] = useState("")

    useEffect(() => {
        if (history.location.state !== undefined) {
            setSuccess(history.location.state.message)
        }
        if (serviceCall) {
            setLoader(true)
            axios.get("product/getall/" + accountId + "?no_of_records=" + no_of_records + "&page=" + page + "&search=" + searchValue)
                .then((response) => {
                    setProductDetails(response.data.data.products);
                    setPagination(response.data.data.pagination);
                    setPerPage(response.data.data.per_page);
                    setServiceCall(false);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (
                            error.response.status === 401 ||
                            error.response.status === 403
                        ) {
                            history.push("/logout");
                        }
                        if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [history, serviceCall, page, no_of_records, searchValue, accountId])

    useEffect(() => {
        setLoader(true)
        axios.get('/clientaccount/get/' + accountId)
            .then((response) => {
                setAccountName(response.data.data.AccountName)
                setLoader(false)
            })
            .catch((error) => {
                if (error.response) {
                    if (
                        error.response.status === 401 ||
                        error.response.status === 403
                    ) {
                        history.push("/logout");
                    }
                    if (error.response.status !== 200) {
                        setError(error.response.data.message);
                        setLoader(false)
                    }
                }
            })
    }, [accountId, history])

    useEffect(() => {
        var url = new URL(window.location.href);
        var pageNo = url.searchParams.get("page");
        setSuccess("");
        setPage(pageNo || 1);
        setServiceCall(true);
    }, [history.location.search]);

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
            history.location.state = undefined
        }
    }, [success, history])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    const searchHandler = () => {
        setSearchValue(search);
        setServiceCall(true);
        history.push({
            search: "page=1&search=" + search,
        });
    }

    const clearSearchHandler = () => {
        history.push({ search: "page=1&search=", });
        setSearch('');
        setSearchValue('');
        setServiceCall(true);
    }

    const recordHandler = (e) => {
        no_of_recordsHandler(e.target.value)
        setPage(1)
        setServiceCall(true)
        history.push({
            search: "no_of_records=" + e.target.value + "&page=1&search=&status=",
        });
    }

    const deleteHandler = (productid) => {
        setAlert("Please confirm Remove Product?");
        setAlertType("delete");
        setAlertId(productid);
    }

    const enabledisable = (productid, productStatus) => {
        var getStatus = (productStatus === 'Enabled') ? 'Disable' : 'Enable';
        var type = (productStatus === 'Enabled') ? 'change_status_disable' : 'change_status_enable';
        setAlert("Please Confirm " + getStatus + " Product?");
        setAlertType(type);
        setAlertId(productid);
    }

    const alertResponse = (response, type, alertId) => {
        if (response) {
            setLoader(true)
            axios
                .post("product/" + type, { ProductID: alertId })
                .then((response) => {
                    setSuccess(response.data.data);
                    setServiceCall(true);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push("/logout");
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                });
        }
        setAlert("");
        setAlertId("");
        setAlertType("")
    }

    const cancelHandler = () => {
        history.push('/client-accounts');
    }

    const handleKeyEnter = (e) => {
        return (e.key === 'Enter') ? searchHandler() : null
    }


    return (
        <div>
            {loader ? <Loader /> : null}
            {alert ? (
                <AlertBox
                    message={alert}
                    type={alertType}
                    alertId={alertId}
                    alertResponse={alertResponse}

                />
            ) : null}
            <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                <div className="row flex-a-center">
                    <div className="col-sm-6">
                        <h5 className="mb-0 custom-header-title"><strong>Manage Client Products</strong></h5>
                    </div>
                    <div className="col-sm-6">
                        <div className="font-22 ms-auto confirm-btn">
                            <Link to={"/add-client-product/" + accountId} className={"btn btn-primary px-4 mb-1" + (permissions.includes('product_add') ? '' : ' blur-buttons')}>
                                Add New
                            </Link>
                            &nbsp;
                            <button type="button" className="btn btn-danger px-4 mb-1"
                                onClick={cancelHandler}
                            >Cancel/Back</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex align-items-center">
                <h5>
                    {AccountName} -
                    Account</h5>
            </div>
            <hr />

            <div className="row">
                <div className="col-sm-3">
                    <input
                        type="text"
                        className="form-control mb-1"
                        id="search"
                        name="search"
                        placeholder="Search Here"
                        value={search}
                        onKeyDown={(e) => handleKeyEnter(e)}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>

                <div className="col-sm-3">
                    <button
                        style={{ float: 'left' }}
                        type="submit"
                        className="btn btn-primary mb-1"
                        title="Search"
                        onClick={searchHandler}
                    >
                        <i className="fa fa-search"></i>
                    </button>

                    {searchValue ?
                        <button
                            style={{ float: "left", marginLeft: '5px' }}
                            type="submit"
                            className="btn btn-primary mb-1"
                            title="Clear Search"
                            onClick={clearSearchHandler}
                        >
                            <i className="fa fa-eraser"></i>
                        </button>
                        : null}
                </div>
            </div>

            <div className="card radius-10">
                <div className="card-body" style={{ overflowX: 'auto' }}>
                    <div className="table-responsive">
                        <table className="table align-middle mb-0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Product Name</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="HoverChange">
                                {(permissions.includes('product_getall')) ?
                                    getProductDetails.length > 0 ? getProductDetails.map((product, index) => (
                                        <tr key={product.ID}>
                                            <th>
                                                {index + (parseInt(page) * perPage - perPage) + 1}
                                            </th>
                                            <td>{product.Name}</td>
                                            <td>
                                                <span
                                                    title="Change Status"
                                                    onClick={() => enabledisable(product.ID, product.Status)}
                                                    style={{ cursor: "pointer" }}
                                                    className={(product.Status === 'Enabled') ? (permissions.includes('product_change_status_disable') ? '' : ' blur-buttons') : (permissions.includes('product_change_status_enable') ? '' : ' blur-buttons')}
                                                >
                                                    {(product.Status === 'Enabled') ?
                                                        <i style={{ color: "green" }} className="fa fa-check-circle"></i> :
                                                        <i style={{ color: "red" }} className="fa fa-times-circle"></i>}
                                                </span>

                                            </td>
                                            <td>
                                                <Link to={"/add-client-product/" + accountId + "/" + product.ID} title="Edit"
                                                    className={permissions.includes('product_edit') ? '' : ' blur-buttons'}>
                                                    {/* <i className="fa fa-edit"></i> */}
                                                    <b className='editColor'>Edit</b> &nbsp;
                                                </Link>
                                                <span
                                                    title="Delete"
                                                    className={permissions.includes('product_delete') ? '' : ' blur-buttons'}
                                                    onClick={() => deleteHandler(product.ID)}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <b className='deleteColor'>Delete</b> &nbsp;
                                                    {/* <i className="fa fa-trash"></i> */}
                                                </span>

                                                {/* <span
                                                    title="Change Status"
                                                    onClick={() => enabledisable(product.ID, product.Status)}
                                                    style={{ cursor: "pointer" }}
                                                    className={(product.Status === 'Enabled') ? (permissions.includes('product_change_status_disable') ? '' : ' blur-buttons') : (permissions.includes('product_change_status_enable') ? '' : ' blur-buttons')}
                                                >
                                                    {(product.Status === 'Enabled') ?
                                                        <i style={{ color: "red" }} className="fa fa-times-circle"></i> :
                                                        <i style={{ color: "green" }} className="fa fa-check-circle"></i>}
                                                </span> */}

                                            </td>
                                        </tr>
                                    )) : <tr><td className="text-center" colSpan="100" ><b>No Record found</b></td></tr> : null}
                            </tbody>
                        </table>
                    </div>
                    <RecordHandler no_of_records={no_of_records} recordHandler={recordHandler}/>

                    <PaginationComp
                        pages={pagination}
                        link={'client-product/' + accountId}
                        activePage={parseInt(page)}
                        search={"search=" + searchValue}
                    />
                </div>
            </div>
            <ToastContainer theme="colored" />
        </div>
    )
}
export default ViewClientProduct