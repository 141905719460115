import React from 'react';
import { Link } from 'react-router-dom';

const PaginationComp = (props) => {

    const pages = props.pages.map((page) => {
        return (
            <li key={page} className={(props.activePage === page) ? 'active' : null}>
                <Link to={{ pathname: '/' + props.link, search: 'page=' + page + '&' + props.search }} >
                    {page}
                </Link>
            </li>
        );
    });

    return (
        (props.pages.length > 1) ?
            <div className="box-footer clearfix">
                <ul className="pagination">
                    <li className="page-item">
                        <Link to={{ pathname: '/' + props.link, search: 'page=1&' + props.search }}>
                            «
                        </Link>
                    </li>
                    {pages}
                    <li className="page-item">
                        <Link to={{ pathname: '/' + props.link, search: 'page=' + props.pages.length + '&' + props.search }}>
                            »
                        </Link>
                    </li>
                </ul>
            </div> : null
    );
}

export default PaginationComp;