import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import LOGO from '../../images/logo-client.jpg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../components/Loader";
import axios from '../../axios'

const Profile = () => {
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [clientAccountList, setClientAccountList] = useState([])
    const [accountManagerDetails, setAccountManagerDetails] = useState({
        AccountContactNumber: "",
        AccountEmailAddress: "",
        AccountName: "",
        AccountSurname: ""
    })
    const { AccountContactNumber, AccountEmailAddress, AccountName, AccountSurname } = accountManagerDetails
    const [clientUserList, setClientUserList] = useState([])
    const [clientDetails, setClientDetails] = useState({
        ClientID: "",
        ClientRole: "",
        ContactNumber: "",
        EmailAddress: "",
        Name: "",
        Status: "",
        Surname: "",
        Username: "",
        CurrentBalance: "",
        Notes: ""
    })

    const { Name, Surname, Username, EmailAddress, ContactNumber, CurrentBalance, Notes } = clientDetails;

    useEffect(() => {
        setLoader(true);
        axios.get("profile/get_all_details")
            .then((response) => {
                setLoader(false);
                setClientDetails({
                    ContactNumber: response.data.data.client.ContactNumber,
                    EmailAddress: response.data.data.client.EmailAddress,
                    Name: response.data.data.client.Name,
                    Status: response.data.data.client.Status,
                    Surname: response.data.data.client.Surname,
                    Username: response.data.data.client.Username,
                    CurrentBalance: response.data.data.client.CurrentBalance,
                    Notes: response.data.data.client.Notes
                })
                setClientAccountList(response.data.data.clientAccount)
                setClientUserList(response.data.data.clientUsers)
                setAccountManagerDetails({
                    AccountContactNumber: response.data.data.aAccountManager.ContactNumber,
                    AccountEmailAddress: response.data.data.aAccountManager.EmailAddress,
                    AccountName: response.data.data.aAccountManager.Name,
                    AccountSurname: response.data.data.aAccountManager.Surname
                })
            })
            .catch((error) => {
                if (error.response) {
                    if (
                        error.response.status === 401 ||
                        error.response.status === 403
                    ) {
                        history.push("/logout");
                    }
                    if (error.response.status !== 200) {
                        setError(error.response.data.message);
                        setLoader(false);

                    }
                }
            })
    }, [history])

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    return (
        <div>
            {loader ? <Loader /> : null}
            <ToastContainer theme="colored" />

            <div className="d-flex align-items-center">
                <div className="title-box">
                    <h5 className="mb-0"><strong>View Client Profile : {Name}</strong></h5>
                </div>
            </div>
            <hr />

            <div className='row'>
                <div className='col-sm-6'>

                    <div className='row'>
                        <div className='col-sm-3'>
                            <div>
                                <img src={LOGO} alt="" className='logo-client' />
                            </div>

                        </div>
                        <div className='col-sm-9'>
                            <div className='client-details'>
                                <label style={{ color: "black", fontWeight: "bold" }} className='col-form-label'> Full Name :
                                    {Name} {Surname}
                                </label><br />
                                <label style={{ color: "black", fontWeight: "bold" }} className='col-form-label'> Account Balance : {CurrentBalance}
                                </label><br />

                            </div>

                        </div>
                    </div>
                </div>

                <div className='col-sm-6'>
                    <h5 style={{ textAlign: "left", fontWeight: "bolder" }}>Notes</h5>
                    <p style={{ textAlign: "left" }}>
                        {Notes}
                    </p>
                </div>
            </div>
            <br />

            {/* fisrt row */}
            <div className="row">
                <div className="col-md-12">
                    <h6 style={{ textAlign: "left" }}> <strong> Contact Details </strong></h6>
                    <hr />
                    <div className="text-left" style={{ textAlign: "left" }}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row mb-2">
                                    <label className="col-sm-3">Name:</label>
                                    <label
                                        className="col-sm-3"

                                    >
                                        {Name}
                                    </label>
                                </div>

                                {/* <div className="row mb-2">
                                    <label className="col-sm-3">Registration Number:</label>
                                    <label
                                        className="col-sm-3"

                                    >
                                    </label>
                                </div> */}

                                <div className="row mb-2">
                                    <label className="col-sm-3">Username:</label>
                                    <label
                                        className="col-sm-3"

                                    >
                                        {Username}
                                    </label>
                                </div>

                                <div className="row mb-2">
                                    <label className="col-sm-3">Email Address:</label>
                                    <label
                                        className="col-sm-3"

                                    >
                                        {EmailAddress}
                                    </label>
                                </div>

                                <div className="row mb-2">
                                    <label className="col-sm-3">Contact Number:</label>
                                    <label
                                        className="col-sm-3"

                                    >
                                        {ContactNumber}
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* end  */}

            <br />
            {/* second */}
            <div className="row">
                <div className="col-md-12">
                    <h6 style={{ textAlign: "left" }}> <strong> Account Manager Details </strong></h6>
                    <hr />
                    <div className="text-left" style={{ textAlign: "left" }}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row mb-2">
                                    <label className="col-sm-3">Name:</label>
                                    <label
                                        className="col-sm-3"

                                    >
                                        {AccountName}
                                    </label>
                                </div>


                                <div className="row mb-2">
                                    <label className="col-sm-3">Surname:</label>
                                    <label
                                        className="col-sm-3"

                                    >
                                        {AccountSurname}
                                    </label>
                                </div>

                                <div className="row mb-2">
                                    <label className="col-sm-3">Email Address:</label>
                                    <label
                                        className="col-sm-3"

                                    >
                                        {AccountEmailAddress}
                                    </label>
                                </div>

                                <div className="row mb-2">
                                    <label className="col-sm-3">Contact Number:</label>
                                    <label
                                        className="col-sm-3"

                                    >
                                        {AccountContactNumber}
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* second */}


            <br />
            {/* second row */}
            {/* <div className="row">
                <div className="col-md-12">
                    <h6 style={{ textAlign: "left" }}> <strong> Authorised Signatory Details </strong></h6>
                    <hr />
                    <div className="text-left" style={{ textAlign: "left" }}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row mb-2">
                                    <label className="col-sm-3">Name:</label>
                                    <label
                                        className="col-sm-3"

                                    >
                                    </label>
                                </div>

                                <div className="row mb-2">
                                    <label className="col-sm-3">Surname:</label>
                                    <label
                                        className="col-sm-3"

                                    >
                                    </label>
                                </div>

                                <div className="row mb-2">
                                    <label className="col-sm-3">Email Address:</label>
                                    <label
                                        className="col-sm-3"

                                    >
                                    </label>
                                </div>

                                <div className="row mb-2">
                                    <label className="col-sm-3">Contact Number:</label>
                                    <label
                                        className="col-sm-3"

                                    >
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <br />


            {/* Client Row */}
            <div className="d-flex align-items-center">
                <div className="title-box">
                <h6 style={{ textAlign: "left" }}> <strong> Accounts </strong></h6>
                </div>
            </div>
            <hr />
            <div className="card radius-10">
                <div className="card-body" style={{ overflowX: 'auto' }}>
                    <div className="table-responsive">
                        <table className="table align-middle mb-0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Account Name</th>
                                    <th>Account Description</th>
                                    <th>Account Status</th>
                                    <th>Credit Limit</th>
                                    <th>Account Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clientAccountList.length > 0 ? clientAccountList.map((clientAccount, index) => (
                                    <tr key={clientAccount.ID}>
                                        <th scope="row">
                                            {index + 1}
                                        </th>
                                        <td>{clientAccount.AccountName}</td>
                                        <td>{clientAccount.AccountDescription}</td>
                                        <td>
                                            {(clientAccount.Status === 'Enabled') ?
                                                <i style={{ color: "green" }} className="fa fa-check-circle"></i> :
                                                <i style={{ color: "red" }} className="fa fa-times-circle"></i>}
                                        </td>
                                        <td>{(clientAccount.CreditLimit) ? clientAccount.CreditLimit : 0}</td>
                                        <td>{clientAccount.ClientType}</td>
                                    </tr>
                                ))
                                    : <tr><td className="text-center" colSpan="100" ><b>No Record found</b></td></tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Client User */}
            <div className="d-flex align-items-center">
                <div className="title-box">
                    <h6 style={{ textAlign: "left" }}> <strong> Client Users </strong></h6>
                </div>
            </div>
            <hr />
            <div className="card radius-10">
                <div className="card-body" style={{ overflowX: 'auto' }}>
                    <div className="table-responsive">
                        <table className="table align-middle mb-0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Surname</th>
                                    <th>Username</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clientUserList.length > 0 ? clientUserList.map((clientUser, index) => (
                                    <tr key={index}>
                                        <th scope="row">
                                            {index + 1}
                                        </th>
                                        <td>{clientUser.Name}</td>
                                        <td>{clientUser.Surname}</td>
                                        <td>{clientUser.Username}</td>
                                        <td>
                                            {(clientUser.Status === 'Enabled') ?
                                                <i style={{ color: "green" }} className="fa fa-check-circle"></i> :
                                                <i style={{ color: "red" }} className="fa fa-times-circle"></i>}
                                        </td>
                                    </tr>
                                ))
                                    : <tr><td className="text-center" colSpan="100" ><b>No Record found</b></td></tr>}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Profile