import React from "react";
// import Loader from 'react-loader-spinner';

const LoaderComp = () => {
  return (
  //   <div
  //   style={{
  //     position: "absolute",
  //     height: "100vh",
  //     width: "100%",
  //     background: 'rgb(255,255,255,0.3)',
  //     zIndex: "99",
  //     top: "-60px",
  //     left: "0",
  //   }}
  // >
  //   <div className="spinner-grow" role="status" style={{position: 'relative',top: '45vh'}}>
  //     <span className="visually-hidden">Loading...</span>
  //   </div>
  // </div>
  <div className="loader">
    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  );
};

export default LoaderComp;
