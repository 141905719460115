import React from 'react'

const Inbox = () => {
  return (
    <div>
      <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
        <div className="row flex-a-center">
          <div className="col-sm-6">
            <h5 className="mb-0 custom-header-title"><strong>View Inbox</strong></h5>
          </div>
          <div className="col-sm-6">
            <div className="font-22 ms-auto confirm-btn">
              {/* <Link to="/add-contact-list" className="btn btn-primary px-4 mb-1">
                Add New
              </Link>
              &nbsp; */}
              {/* <button type="button" className="btn btn-primary px-4 mb-1"> Cancel/Back</button> */}
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div className="row">
        <div className="col-sm-3">
          <input
            type="text"
            className="form-control mb-1"
            id="search"
            name="search"
            placeholder="Search Here"
          // value={search}
          // onKeyDown={(e) => handleKeyEnter(e)}
          // onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div className="col-sm-3">
          <button
            style={{ float: 'left' }}
            type="submit"
            className="btn btn-primary"
            title="Search"
          // onClick={searchHandler}
          >
            <i className="fa fa-search"></i>
          </button>

          {/* {(searchValue) ? */}
          <button
            style={{ float: 'left', marginLeft: '5px' }}
            type="submit"
            className="btn btn-primary"
            title="Clear Search"
          // onClick={clearSearchHandler}
          >
            <i className="fa fa-eraser"></i>
          </button>
          {/* : null} */}
        </div>
      </div>

      <div className="card radius-10">
        <div className="card-body" style={{ overflowX: 'auto' }}>
          <div className="table-responsive">
            <table className="table align-left mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Numbers</th>
                  <th>Date Created</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className="HoverChange">
                {/* {(permissions.includes('contact_get_all_contact_list')) ?
                  ContactList.length > 0 ? ContactList.map((contact, index) => (
                    <tr key={contact.ContactListID}
                      onClick={() => handleColor(contact.ContactListID)}>
                      <th scope="row">
                        {index + (parseInt(page) * perPage - perPage) + 1}
                      </th>          
                      <td>{contact.Description}</td>
                      <td>{contact.Numbers}
                      </td>
                      <td>{contact.Created}</td>
                     
                    </tr>
                  )) : <tr><th colSpan="6"> No Record Found</th></tr> : null} */}
              </tbody>
            </table>
          </div>
          {/* <RecordHandler no_of_records={no_of_records} recordHandler={recordHandler} />

          <PaginationComp
            pages={pagination}
            link={"contact-list"}
            activePage={parseInt(page)}
            search={"search=" + searchValue} /> */}
        </div>
      </div>
    </div>
  )
}

export default Inbox