import React, { useState, useEffect } from 'react';
import Loader from "../../components/Loader";
import { useHistory, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../axios';
import PaginationComp from "../../components/PaginationComp";
import AlertBox from "../../components/AlertBox";
import { useSelector } from "react-redux";
import RecordHandler from '../../components/RecordHandler';

const ViewUser = () => {
    const permissions = useSelector((state) => state.getPermissions.permissions);
    const history = useHistory();

    var url = new URL(window.location.href);
    var searchTxt = url.searchParams.get("search");
    var statusTxt = url.searchParams.get("status");
    var pageNo = url.searchParams.get("page");

    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [search, setSearch] = useState(searchTxt || "");
    const [searchValue, setSearchValue] = useState(searchTxt || "");
    const [page, setPage] = useState(pageNo || 1);
    const [pagination, setPagination] = useState([]);
    const [perPage, setPerPage] = useState(0);
    const [status, setStatus] = useState(statusTxt || "");
    const [statusValue, setStatusValue] = useState(statusTxt || "");
    const [no_of_records, no_of_recordsHandler] = useState(10);
    const [loader, setLoader] = useState(false);
    const [users, setUsers] = useState([]);
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState("");
    const [alertId, setAlertId] = useState("");
    const [serviceCall, setServiceCall] = useState(true);
    // const [sortData, setSortData] = useState([])

    useEffect(() => {
        if (history.location.state !== undefined) {
            setSuccess(history.location.state.message)
        }
        if (serviceCall) {
            setLoader(true);
            axios.get("/user/getall?no_of_records=" + no_of_records + "&page=" + page + "&search=" + searchValue +
                "&status=" + statusValue)
                .then((response) => {
                    setUsers(response.data.data.users);
                    setPagination(response.data.data.pagination);
                    setPerPage(response.data.data.per_page);
                    setServiceCall(false);
                    setLoader(false);
                })
                .catch((error) => {
                    if (error.response) {
                        if (
                            error.response.status === 401 ||
                            error.response.status === 403
                        ) {
                            history.push("/logout");
                        }
                        if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [history, searchValue, page, statusValue, no_of_records, serviceCall])

    useEffect(() => {
        var url = new URL(window.location.href);
        var pageNo = url.searchParams.get("page");
        setSuccess('');
        setPage(pageNo || 1);
        setServiceCall(true);
    }, [history.location.search]);

    const searchHandler = () => {
        setSearchValue(search);
        setStatusValue(status);
        setServiceCall(true);
        history.push({
            search: "page=1&search=" + search + "&status=" + status,
        });
    };

    const recordHandler = (e) => {
        no_of_recordsHandler(e.target.value)
        setPage(1)
        setServiceCall(true);
        history.push({
            search: "no_of_records=" + e.target.value + "&page=1&search=&status=",
        });
    }


    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
            history.replace({
                state: {}
            })
        }
    }, [success, history])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    const clearSearchHandler = () => {
        history.push({
            search: "page=1&search=&status=",
        });
        setSearch('');
        setStatus('');
        setSearchValue('');
        setStatusValue('');
        setServiceCall(true);
    };

    const saveResetPassword = (getUsername) => {
        if (permissions.includes('user_reset_password_user')) {
            setLoader(true)
            axios
                .post("user/reset_password_user", { Username: getUsername, from: 'UserProfile' })
                .then((response) => {
                    setSuccess(response.data.data);
                    setServiceCall(true);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (
                            error.response.status === 401 ||
                            error.response.status === 403
                        ) {
                            history.push("/logout");
                        }
                        if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }

    const selectUsers = (index) => {
        var dummyUsers = JSON.parse(JSON.stringify(users))
        dummyUsers[index].checked = (dummyUsers[index]["checked"] === undefined) ? true : !dummyUsers[index].checked
        setUsers(dummyUsers)
    }

    const getSelectedUsers = () => {
        var selectedUsers = []
        for (var i = 0; i < users.length; i++) {
            if (users[i].checked === true) {
                selectedUsers.push(users[i].Username)
            }
        }
        return selectedUsers;
    }

    const saveResetPasswordUsernames = () => {
        if (permissions.includes('user_reset_password_user_multiple')) {
            const Usernames = getSelectedUsers()
            setLoader(true)
            axios.post("user/reset_password_user_multiple", { Usernames: Usernames })
                .then((response) => {
                    setSuccess(response.data.data);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (
                            error.response.status === 401 ||
                            error.response.status === 403
                        ) {
                            history.push("/logout");
                        }
                        if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }

    const getSelectedDeletedUsers = () => {
        var selectedUsersIds = [];
        for (var i = 0; i < users.length; i++) {
            if (users[i].checked === true) {
                selectedUsersIds.push(users[i].ID)
            }
        }
        return selectedUsersIds
    }

    const saveDeleteUsers = () => {
        setAlert("Are you sure you want to delete the selected users? <br> <b>Note:-</b> <br> If users are linked to Client Account or another campaign then they'll not be deleted");
        setAlertType("delete_user_multiple");
        const DeleteUsersIds = getSelectedDeletedUsers()
        setAlertId(DeleteUsersIds);
    }

    const enabledisable = (UserID, userStatus) => {
        console.log(userStatus);
        var getStatus = (userStatus === 'Enabled') ? 'Disable' : 'Enable';
        var type = (userStatus === 'Enabled') ? 'change_status_disable' : 'change_status_enable';
        setAlert("Please confirm " + getStatus + " User?");
        setAlertType(type);
        setAlertId(UserID);
    };


    const alertResponse = (response, type, alertId) => {
        if (response) {
            const name = (type === 'delete_user_multiple') ? { UserIDs: alertId } : { UserID: alertId };
            setLoader(true)
            axios.post("user/" + type, name)
                .then((response) => {
                    setSuccess(response.data.data);
                    setServiceCall(true);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (
                            error.response.status === 401 ||
                            error.response.status === 403
                        ) {
                            history.push("/logout");
                        }
                        if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
        setAlert("");
        setAlertType("");
        setAlertId();
    }

    const handleKeyEnter = (e) => {
        return (e.key === 'Enter') ? searchHandler() : null
    }

    const selectAllUser = (e) => {
        var dummyUsers = JSON.parse(JSON.stringify(users))
        if (e.target.checked === true) {
            for (var i = 0; i < users.length; i++) {
                dummyUsers[i].checked = true;
                setUsers(dummyUsers)
            }
        } else {
            for (var j = 0; j < users.length; j++) {
                dummyUsers[j].checked = false;
                setUsers(dummyUsers)
            }
        }
    }

    // const sortData = (getvalue) => {
    //     // console.log(getvalue)
    //     const array_column = (users, column) => {
    //         return users.map(e => e[column]);
    //     }
    //     // console.log(array_column(users))

    //     const temp = JSON.parse(JSON.stringify(users))
    //     const sort = temp.sort().reverse();
    //     // console.log("sort", sort)
    //     setUsers(sort)
    // }

    // console.log("users", users)

    return (
        <div>
            {loader ? <Loader /> : null}
            {alert ? (
                <AlertBox
                    message={alert}
                    type={alertType}
                    alertId={alertId}
                    alertResponse={alertResponse}
                />
            ) : null}
            <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                <div className="row flex-a-center">
                    <div className="col-sm-6">
                        <h5 className="mb-0 custom-header-title"><strong>Manage Users</strong></h5>
                    </div>
                    <div className="col-sm-6">
                        <div className="font-22 ms-auto confirm-btn">
                            <Link to="/add-user" className={"btn btn-primary px-4 mb-1" + (permissions.includes('user_add') ? '' : ' blur-buttons')}>Add New </Link>
                            <button type="button" className={"btn btn-primary px-4 mb-1" + (permissions.includes('user_reset_password_user_multiple') ? '' : ' blur-buttons')} onClick={saveResetPasswordUsernames}
                            > Reset Password</button>
                            <button type="button" className={"btn btn-danger px-4 mb-1" + (permissions.includes('user_delete_user_multiple') ? '' : ' blur-buttons')}
                                onClick={saveDeleteUsers}> Delete</button>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            {/* <div className="row">
                <div className="input-group">
                    <input
                        className="form-control border-end-0 border"
                        placeholder="Search Here"
                        type="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        id="search" />
                    <span className="input-group-append">
                        <button className="btn btn-outline-secondary border-left-0 border" type="button"
                            onClick={searchHandler}>
                            <i className="fa fa-search"></i>
                        </button>
                    </span>
                </div>


                <div className="input-group">
                    <select
                        value={status}
                        className="form-select mb-3"
                        name="status"
                        id="status"
                        aria-label="Default select example"
                        onChange={(e) => setStatus(e.target.value)}
                    >
                        <option value="">Choose Status</option>
                        <option value="Enabled">Enabled</option>
                        <option value="Disabled">Disabled</option>
                    </select>
                </div>

            </div> */}


            <div className="row">
                <div className="col-sm-3">
                    <input
                        type="text"
                        className="form-control mb-1"
                        id="search"
                        name="search"
                        placeholder="Search Here"
                        value={search}
                        onKeyDown={(e) => handleKeyEnter(e)}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <div className="col-sm-3">
                    <select
                        value={status}
                        className="form-select mb-3"
                        name="status"
                        id="status"
                        aria-label="Default select example"
                        onChange={(e) => setStatus(e.target.value)}
                    >
                        <option value="">Choose Status</option>
                        <option value="Enabled">Enabled</option>
                        <option value="Disabled">Disabled</option>
                    </select>
                </div>
                <div className="col-sm-3">
                    <button
                        style={{ float: "left", margin: 'auto' }}
                        type="submit"
                        className="btn btn-primary mb-1"
                        title="Search"
                        onClick={searchHandler}
                    >
                        <i className="fa fa-search"></i>
                    </button>

                    {
                        (searchValue || statusValue) ?
                            <button
                                style={{ float: "left", marginLeft: '5px', marginTop: 0 }}
                                type="submit"
                                className="btn btn-primary mb-1"
                                title="Clear Search"
                                onClick={clearSearchHandler}
                            >
                                <i className="fa fa-eraser"></i>
                            </button>
                            : null}
                </div>
            </div>

            <br />
            <div className="card radius-10">
                <div className="card-body" style={{ overflowX: 'auto' }}>
                    <div className="table-responsive">
                        <table className="table align-middle mb-0">
                            <thead>
                                <tr>
                                    <td><input
                                        type="checkbox"
                                        className="form-check-input"
                                        onChange={(e) => selectAllUser(e)}
                                    ></input></td>
                                    <th>Name</th>
                                    <th>Surname</th>
                                    <th>Username</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="HoverChange">
                                {(permissions.includes('user_getall')) ?
                                    users.length > 0 ? users.map((user, index) => (
                                        <tr key={user.ID}>
                                            <th>
                                                <input
                                                    checked={(user["checked"] === undefined) ? false : user.checked}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    onChange={() => selectUsers(index)} />
                                                &nbsp;
                                                <p style={{ display: 'none' }}>{index + (parseInt(page) * perPage - perPage) + 1}</p>
                                            </th>
                                            <td><h6>{user.Name}</h6></td>
                                            <td><h6>{user.Surname}</h6></td>
                                            <td><h6>{user.Username}</h6></td>
                                            <td>
                                                <h6
                                                    title="Change Status"
                                                    onClick={() => enabledisable(user.ID, user.Status)}
                                                    style={{ cursor: "pointer", color: '#004C97' }}
                                                    className={(user.Status === 'Enabled') ? (permissions.includes('user_change_status_disable') ? '' : ' blur-buttons') : (permissions.includes('user_change_status_enable') ? '' : ' blur-buttons')}
                                                >
                                                    {(user.Status === 'Enabled') ?
                                                        <i style={{ color: "green" }} className="fa fa-check-circle"></i> :
                                                        <i style={{ color: "red" }} className="fa fa-times-circle"></i>}
                                                </h6>

                                            </td>
                                            <td>
                                                <Link to={"/add-user/" + user.ID} title="Edit" className={permissions.includes('user_edit') ? '' : 'blur-buttons'}>
                                                    <b className='editColor'>Edit</b> &nbsp;
                                                </Link>

                                                {/* <h6
                                                    title="Change Status"
                                                    onClick={() => enabledisable(user.ID, user.Status)}
                                                    style={{ cursor: "pointer", color: '#004C97' }}
                                                    className={(user.Status === 'Enabled') ? (permissions.includes('user_change_status_disable') ? '' : ' blur-buttons') : (permissions.includes('user_change_status_enable') ? '' : ' blur-buttons')}
                                                >
                                                    Status
                                                    {(user.Status === 'Enabled') ?
                                                        <i style={{ color: "red" }} className="fa fa-times-circle"></i> :
                                                        <i style={{ color: "green" }} className="fa fa-check-circle"></i>}
                                                </h6> */}
                                                <span
                                                    title="Reset Password"
                                                    onClick={() => saveResetPassword(user.Username)}
                                                    className={permissions.includes('user_reset_password_user') ? '' : ' blur-buttons'}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <b className='editColor'>Reset password</b> &nbsp;
                                                </span>
                                            </td>
                                        </tr>
                                    )) : <tr><td className="text-center" colSpan="100" ><b>No Record found</b></td></tr> : null}

                            </tbody>
                        </table>
                    </div>
                    <RecordHandler no_of_records={no_of_records} recordHandler={recordHandler} />
                    <PaginationComp
                        pages={pagination}
                        link={"user"}
                        activePage={parseInt(page)}
                        search={"search=" + searchValue + "&status=" + statusValue}
                    />
                </div>
            </div>
            <ToastContainer theme="colored" />
        </div>
    )
}
export default ViewUser;