import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router';
import axios from '../../../axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../../components/Loader";
import { Prompt } from 'react-router-dom';

const AddSMPPConfigure = () => {
    const permissions = useSelector((state) => state.getPermissions.permissions);
    const { productid } = useParams();
    // const { clientId } = useParams();
    const { accountId } = useParams();
    const history = useHistory();

    const [SMPPDetails, setSMPPDetails] = useState({
        ProductID: productid || "",
        SystemID: "",
        Password: "",
        SystemType: ""
    })
    const { SystemID, Password, SystemType } = SMPPDetails;

    const [ShowPassword, setShowPassword] = useState(false);
    const [loader, setLoader] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [SystemIDError, setSystemIDError] = useState("");
    const [PasswordError, setPasswordError] = useState("");
    const [SystemTypeError, setSystemTypeError] = useState("");
    const [FromDataChanged, setFromDataChanged] = useState(false)

    const NormalField = (localStorage.getItem("ClientNormalField") ? localStorage.getItem("ClientNormalField") : "");

    const handleChange = (e) => {
        setSMPPDetails({ ...SMPPDetails, [e.target.name]: e.target.value });
        setFromDataChanged(true)
    }

    const validate = () => {
        var flag = true;
        setSystemIDError("");
        setPasswordError("");
        setSystemTypeError("");

        if (SystemID === "") {
            flag = false;
            setSystemIDError("System ID field is required")
        } else if (SystemID.length < 1 || SystemID.length > 15) {
            flag = false;
            setSystemIDError("System ID minimum string length is 1 or maximum string length is 15")
        }

        if (Password === "") {
            flag = false;
            setPasswordError("Password field is required")
        } else if (Password.length < 1 || Password.length > 8) {
            flag = false;
            setPasswordError("Password minimum string length is 1 or maximum string length is 8")
        }

        if (SystemType !== "") {
            if (SystemType.length < 0 || SystemType.length > 12) {
                flag = false;
                setSystemTypeError("System Type minimum string length is 0 or maximum string length is 12")
            }
        }
        return flag;
    }

    const submitSMPPDetails = (e) => {
        e.preventDefault();
        setSuccess("")
        setError("");
        const flag = validate()
        if (flag) {
            setLoader(true)
            axios.post("product/add_api_smpp", SMPPDetails)
                .then((response) => {
                    setFromDataChanged(false)
                    history.push({
                        pathname: "/add-client-product/" + accountId + "/" + productid,
                        state: { message: response.data.data }
                    })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }

    useEffect(() => {
        if (permissions.includes('product_get_product_config')) {
            setLoader(true);
            axios.get('product/get_product_config/' + productid)
                .then((response) => {
                    setSMPPDetails({
                        ProductID: productid,
                        SystemID: (response.data.data.SystemID) ? response.data.data.SystemID : '',
                        Password: (response.data.data.Password) ? response.data.data.Password : '',
                        SystemType: (response.data.data.SystemType) ? response.data.data.SystemType : ''
                    })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [history, productid, permissions])

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    const backHandler = () => {
        history.push("/add-client-product/" + accountId + "/" + productid)
    }

    return (
        <div>
             <Prompt
                when={FromDataChanged}
                message="You are navigating away from this screen. You will lose the typed information. Are you sure you want to leave this screen?"
            />
            {loader ? <Loader /> : null}
            <form onSubmit={submitSMPPDetails}>
                <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                    <div className="row flex-a-center">
                        <div className="col-sm-6">
                            <h5 className="mb-0 custom-header-title"><strong>Add Configure - SMPP</strong></h5>
                        </div>
                        <div className="col-sm-6">
                            <div className="font-22 ms-auto confirm-btn">
                                <button type="submit" className={"btn btn-success px-5" + (permissions.includes('product_add_api_smpp') ? '' : ' blur-buttons')}>
                                    Save
                                </button>
                                &nbsp;
                                <button type="button" className="btn btn-danger px-4"
                                    onClick={backHandler}>
                                    Cancel/Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                <div className="card card-custom">
                    <div className="card-body">

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="SystemID"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >System ID<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <input
                                        autoFocus
                                        type="text"
                                        className="form-control mb-3"
                                        id="SystemID"
                                        name="SystemID"
                                        placeholder="Enter System ID"
                                        value={SystemID || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {SystemIDError}
                                    </h6>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="Password"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >Password<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <div className="row">
                                        <div className="col-md-12 flex-center-end1">
                                            <i className={(ShowPassword) ? "fa fa-eye password-icon" : "fa fa-eye-slash password-icon"} style={{ color: (ShowPassword) ? 'blue' : 'gray' }} onClick={() => setShowPassword(!ShowPassword)}></i>
                                            <input
                                                type={ShowPassword ? "text" : "password"}
                                                className="form-control mb-3"
                                                id="Password"
                                                name="Password"
                                                placeholder="Enter Password"
                                                value={Password || ''}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {PasswordError}
                                    </h6>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="SystemType"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >System Type
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-3"
                                        id="SystemType"
                                        name="SystemType"
                                        placeholder="Enter System Type"
                                        value={SystemType || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {SystemTypeError}
                                    </h6>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </form >
            <ToastContainer theme="colored" />
        </div>);
};
export default AddSMPPConfigure;
