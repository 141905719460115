import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ViewReports = () => {

    const reportList = ['Account Report', 'Volume Per Product Report'];
    const [search, setSearch] = useState("");
    const [filterData, setFilterData] = useState([])

    const searchHandler = (searchValue) => {
        setSearch(searchValue)
        const result = reportList.filter(item => (item.toLowerCase().includes(searchValue.toLowerCase())));
        setFilterData(result)
    }

    const clearSearchHandler = () => {
        setSearch("")
    }

    return (
        <div>
            <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                <div className="row flex-a-center">
                    <div className="col-sm-6">
                        <h5 className="mb-0 custom-header-title"><strong>Reports</strong></h5>
                    </div>
                </div>
            </div>
            <hr />

            <div className="row">
                <div className="col-sm-3">
                    <div className='input-group'>
                        <span className="input-group-text bg-transparent  mb-3">
                            <i className='fa fa-search'></i>
                        </span>
                        <input
                            type="text"
                            className="form-control border-start-0 mb-3"
                            id="search"
                            name="search"
                            placeholder="Search Here"
                            value={search}
                            onChange={(e) => searchHandler(e.target.value)}
                        />
                    </div>
                </div>

                <div className="col-sm-3">
                    {
                        (search) ?
                            <button
                                style={{ float: "left", marginLeft: '5px' }}
                                type="submit"
                                className="btn btn-primary mb-1"
                                title="Clear Search"
                                onClick={clearSearchHandler}
                            >
                                <i className="fa fa-eraser"></i>
                            </button>
                            : null}
                </div>
            </div>

            <div className="card radius-10">
                <div className="card-body" style={{ overflowX: 'auto' }}>
                    <div className="table-responsive">
                        <table className="table align-middle mb-0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="HoverChange">
                                {
                                    (search.length > 1) ?
                                        // search list
                                        filterData.length > 0 ? filterData.map((item, index) => (
                                            <tr key={index} >
                                                <th>
                                                    {index + 1}
                                                </th>
                                                <td>{item}</td>
                                                <td>
                                                    <Link to={"/reports/" + item.replace(/\s/g, '')} title="View">
                                                        <b className="editColor">View</b> &nbsp;
                                                    </Link>
                                                </td>
                                            </tr>
                                        )) : <tr><th colSpan="3"> No Record Found</th></tr>

                                        :
                                        //normal list
                                        reportList.length > 0 ? reportList.map((reports, index) => (
                                            <tr key={index} >
                                                <th>
                                                    {index + 1}
                                                </th>
                                                <td>{reports}</td>
                                                <td>
                                                    <Link to={"/reports/" + reports.replace(/\s/g, '')} title="View">
                                                        <b className="editColor">View</b> &nbsp;
                                                    </Link>
                                                </td>
                                            </tr>
                                        )) : <tr><td className="text-center" colSpan="100" ><b>No Record found</b></td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default ViewReports