import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Tooltip from '../../components/Tooltip';
import axios from "../../axios";
import Multiselect from 'multiselect-react-dropdown';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tagsinput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css'
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import AlertBox from "../../components/AlertBox";
import { Prompt } from 'react-router-dom';

const ViewQuickSend = () => {
    const permissions = useSelector((state) => state.getPermissions.permissions);
    const history = useHistory();
    const [sendDetails, setSendDetails] = useState({
        ContactList: [],
        NumberList: [],
        ListType: "NumberList",
        Name: "",
        Description: "",
        Template: "",
        ScheduleSend: "",
        ScheduleSendDate: new Date(),
        ScheduleSendTime: new Date(),
        Confirmation: "no",
        Content: "",
        ProductID: "",
        ProductName: ""
    })
    const { NumberList, ContactList, Name, Description, Template, ScheduleSend, ScheduleSendDate, ScheduleSendTime, Confirmation, Content, ListType, ProductID } = sendDetails;
    const [laterOption, setLaterOptions] = useState(false);
    const [PreviewInput, setPreviewInput] = useState('');
    const [tooltip, setTooltip] = useState("");
    const [templateListDetails, setTemplateListDetails] = useState([])
    const [contactListDetails, setContactListDetails] = useState([])
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [nameError, setNameError] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [contentError, setContentError] = useState("");
    const [contactListError, setContactListError] = useState("");
    const [NumberListError, setNumberListError] = useState("");
    const [scheduleSendError, setScheduleSendError] = useState("");
    const [confirmationError, setConfirmationError] = useState("");
    const [previewInputError, setPreviewInputError] = useState("");
    const [dateError, SetDateError] = useState("");
    const [timeError, SettimeError] = useState("")
    // const [showTemplateContent , setShowTemplateContent] = useState("");
    const [ClientEmail, setClientEmail] = useState("")
    const [ButtonValue, setButtonValue] = useState("Save")
    const [CharacterCount, setCharacterCount] = useState(0)
    const [CharacterParts, setCharacterParts] = useState(0)
    // const [selected, setSelected] = useState(["papaya"]);
    // const [showHideContacts, setShowHideContacts] = useState(false)
    // const [showHideDropdown, setshowHideDropdown] = useState(true)
    // const [ContactButton, setContactButton] = useState("Select Contact List")
    const [loader, setLoader] = useState(false);
    const [readonly, setReadOnly] = useState(false)
    const [contactListRender, setContactListRender] = useState(true)
    const textInput = useRef(null);
    // var tomorrowDate = new Date(ScheduleSendDate.getFullYear(),ScheduleSendDate.getMonth(), ScheduleSendDate.getDate()) + 1;
    const [isContact, setisContact] = useState("NumberList")
    // const [isDataFilled, setIsDataFilled] = useState(false)
    const [alert, setAlert] = useState("");
    const [ProductIDError, setProductIDError] = useState("")
    const [ProductList, setProductList] = useState([])
    const [FromDataChanged, setFromDataChanged] = useState(false)


    const NormalField = (localStorage.getItem("ClientNormalField") ? localStorage.getItem("ClientNormalField") : "");

    useEffect(() => {
        if (localStorage.getItem("clientEmail")) {
            setClientEmail(localStorage.getItem("clientEmail"))
        }
    }, [])

    const handleChange = (e) => {
        setSendDetails({ ...sendDetails, [e.target.name]: e.target.value });
        setFromDataChanged(true)
    };

    const handleSelectValue = (e) => {
        if (e.target.value === 'later') {
            setSendDetails({ ...sendDetails, [e.target.name]: e.target.value });
            setLaterOptions(true);
            setButtonValue("Save")
        } else {
            setSendDetails({ ...sendDetails, [e.target.name]: e.target.value });
            setLaterOptions(false);
            setButtonValue("Send")
        }
        setFromDataChanged(true)
    }

    const handleTemplate = (e) => {
        if (e.target.value === '') {
            // setSendDetails({ ...sendDetails, [e.target.name]: e.target.value });
            charcaterPartHandler('', e.target.value);
            setReadOnly(false)
        }
        for (var i = 0; i < templateListDetails.length; i++) {
            if (templateListDetails[i].ID === e.target.value) {
                setReadOnly(true)
                // setSendDetails({ ...sendDetails, [e.target.name]: e.target.value})
                charcaterPartHandler(templateListDetails[i].Content, e.target.value);
                break
            }
        }
        setFromDataChanged(true)
    }

    const handleContent = (e) => {
        // setSendDetails({ ...sendDetails, [e.target.name]: e.target.value  });
        var input = e.target.value;
        // (e.target.value === '') ? setReadOnly(false) : setReadOnly(true);
        // setReadOnly(true)
        charcaterPartHandler(input, Template);
        setFromDataChanged(true)
    }

    const contactListHandler = (e) => {
        // setisContact(false)
        var listIds = [];
        for (var i = 0; i < e.length; i++) {
            listIds.push(e[i].id)
        }
        setSendDetails({ ...sendDetails, ContactList: listIds, ListType: "ContactList" });
        setFromDataChanged(true)
    }

    const handleContactTexts = (e) => {

        var ListNo = [];
        for (var i = 0; i < e.length; i++) {
            if ((e[i].length >= 9 && e[i].length <= 15) || (e[i][0] === '+' && e[i].length >= 9 && e[i].length <= 16)) {
                ListNo.push(e[i])
            }
        }
        setSendDetails({ ...sendDetails, NumberList: ListNo, ListType: "NumberList" });
        setFromDataChanged(true)
    }

    const dateHandler = (date) => {
        // console.log(date.CURDATE())
        setSendDetails({ ...sendDetails, ScheduleSendDate: date })
        setFromDataChanged(true)
    }

    const timeHandler = (date) => {
        setSendDetails({ ...sendDetails, ScheduleSendTime: date })
        setFromDataChanged(true)
    }



    const options = contactListDetails.map((contact) => {
        return { name: contact.ContactListName, id: contact.ContactListID }
    })

    function charcaterPartHandler(input, template) {
        const max_limit = 800
        const part_limit = 160;
        // const part_limit = (input.length > 160) ? 153 : 160;

        if (input.length <= max_limit) {
            setCharacterCount(input.length)
            setCharacterParts((input.length % part_limit === 0) ? (input.length / part_limit) : parseInt(input.length / part_limit) + 1)
            setSendDetails({ ...sendDetails, Content: input, Template: template });
        }
    }

    useEffect(() => {
        if (permissions.includes('sendsms_get_template')) {
            setLoader(true)
            axios
                .get("sendsms/get_template")
                .then((response) => {
                    setTemplateListDetails(response.data.data.Template);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (
                            error.response.status === 401 ||
                            error.response.status === 403
                        ) {
                            history.push("/logout");
                        }
                        if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [history, error, permissions])

    useEffect(() => {
        if (permissions.includes('sendsms_get_all_contact_list')) {
            setLoader(true)
            axios
                .get("sendsms/get_all_contact_list")
                .then((response) => {
                    setContactListDetails(response.data.data.contactLists);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (
                            error.response.status === 401 ||
                            error.response.status === 403
                        ) {
                            history.push("/logout");
                        }
                        if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [history, permissions])

    function validate() {
        var flag = true;
        setNameError("");
        setDescriptionError("");
        setContactListError("");
        setContentError("");
        setScheduleSendError("");
        setConfirmationError("");
        SetDateError("")
        SettimeError("")
        setNumberListError("")
        setProductIDError("")

        if (Name === '') {
            flag = false;

            setNameError("Name field is required");
        }

        if (Description === '') {
            flag = false;

            setDescriptionError("Description field is required");
        }

        if (ListType === "ContactList") {
            if (ContactList.length === 0) {
                flag = false;

                setContactListError("Contact List field is required");
            }
        }

        // var pattern = new RegExp(/^(?=.*[0-9])[- +()0-9]+$/);
        if (ListType === "NumberList") {
            if (NumberList.length === 0) {
                flag = false;
                setNumberListError("Number List field is required");
            }
        }
        // else if (!pattern.test(NumberList)) {
        //     flag = false
        //     setNumberListError("Please enter only number");        
        //   }
        // }

        if (Content === '') {
            flag = false;
            setContentError("Content field is required");
        }

        if (ScheduleSend === '') {
            flag = false;
            setScheduleSendError("Schedule Send field is required");
        }

        if (Confirmation === '') {
            flag = false;
            setConfirmationError("Confirmation field is required");
        }

        if (ScheduleSendDate === null) {
            flag = false;
            SetDateError("Date field is required");
        }

        if (ScheduleSendTime === null) {
            flag = false;
            SettimeError("Time field is required");
        }

        // if (ProductList.length > 0) {
            if (ProductID === "") {
                flag = false;
                setProductIDError("Product field is required");
            }
        // }

        return flag
    }

    const submitQuickSendDetails = (e) => {
        e.preventDefault();
        setSuccess("")
        setError("");
        // var pattern = new RegExp(/^[0-9\b]+$/);
        // if (!pattern.test(NumberList)) {
        //     // flag = false
        //     setNumberListError("Please enter only number");        
        //   }
        const flag = validate()
        // var url = (contactId) ? 'edit_contact_list' : 'add_contact_list';
        if (flag) {
            SavePromptHandler()
        }
    }

    const SavePromptHandler = () => {
        setAlert("Please ensure that you have verified your character count. Characters beyond 160 count as two or more messages per recipient. Are you sure you want to save the details?")
    }

    const alertResponse = (response) => {
        if (response) {
            setLoader(true)
            sendDetails.ProductID = ProductList.length > 0 ? ProductList[ProductID].ID : ''
            sendDetails.ProductName = ProductList.length > 0 ? ProductList[ProductID].Name : ''
            axios
                .post("sendsms/add", sendDetails)
                .then((response) => {
                    setFromDataChanged(false)
                    setSuccess(response.data.data.Message);
                    setSendDetails({
                        ContactList: [],
                        NumberList: [],
                        ListType: "NumberList",
                        Name: "",
                        Description: "",
                        Template: "",
                        ScheduleSend: "",
                        ScheduleSendDate: new Date(),
                        ScheduleSendTime: new Date(),
                        Confirmation: "no",
                        Content: "",
                        ProductID: ""
                    })
                    setCharacterCount(0)
                    setCharacterParts(0)
                    setReadOnly(false)
                    setContactListRender(false)
                    setContactListRender(true)
                    setLoader(false)
                    setAlert("")
                    //  history.push('/contact-list')
                    //  history.push('/add-contact-list/'+response.data.data.ContactListID)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                });
        } else {
            // history.push("/template")
            setAlert("")
        }

    }

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    // const cancelHandler = () => {

    // }

    const previewHandler = () => {
        if (Content) {
            setTooltip("Please Insert the Test Number");
        } else {
            setError("Please first provide the required informaton")
        }
    }

    const validatePreview = () => {
        var flag = true;
        setPreviewInputError("")


        var pattern = new RegExp(/^[0-9\b]+$/);
        if (PreviewInput === '') {
            flag = false
            setPreviewInputError("Preview Test Number Field is required")
        } else if (!pattern.test(PreviewInput)) {
            flag = false
            setPreviewInputError("Please enter only Numeric")
        } else if (PreviewInput.length !== 10) {
            flag = false
            setPreviewInputError("Please enter only 10 digit Number")
        }

        return flag;
    }

    const toolTipResponse = (response) => {
        const flag = validatePreview();
        if (response) {
            if (flag) {
                const params = {
                    Number: PreviewInput,
                    Content: Content
                }
                setLoader(true)
                axios.post("sendsms/preview", params)
                    .then((response) => {
                        setSuccess(response.data.data.Message)
                        setTooltip("")
                        setLoader(false)
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401 || error.response.status === 403) {
                                history.push('/logout')
                            } else if (error.response.status !== 200) {
                                setError(error.response.data.Message);
                                setLoader(false)
                            }
                        }
                    })
            }
        } else {
            setTooltip("")
            setPreviewInputError("")
            setPreviewInput("")
        }

    }


    // const showHideContactList = () => {
    //     (showHideDropdown === true) ?
    //         setshowHideDropdown(false) :
    //         setshowHideDropdown(true)
    //     // setshowHideDropdown(false)
    //     if (ContactButton === "Select Contact List") {
    //         setContactButton("+ Add Contact Number")
    //     } else {
    //         setContactButton("Select Contact List")
    //     }

    // }

    // const changeTime = (date) => {
    //     filterPassedTime(date)
    // }

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        if (ScheduleSendDate.getDate() === currentDate.getDate()) {
            return currentDate.getTime() < selectedDate.getTime()
        } else {
            return true;
        }
    };

    useEffect(() => {
        textInput.current.focus();
    }, []);


    useEffect(() => {
        setLoader(true);
        axios.get("sendsms/get_list_of_products")
            .then((response) => {
                setProductList(response.data.data)
                setLoader(false)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401 || error.response.status === 403) {
                        history.push('/logout')
                    } else if (error.response.status !== 200) {
                        setError(error.response.data.Message);
                        setLoader(false)
                    }
                }
            })
    }, [history])
    return (
        <div>
            {loader ? <Loader /> : null}
            <Prompt
                when={FromDataChanged}
                message="You are navigating away from this screen. You will lose the typed information. Are you sure you want to leave this screen?"
            />
            {alert ? (
                <AlertBox
                    message={alert}
                    alertResponse={alertResponse}
                />
            ) : null}
            {
                tooltip ?
                    (<Tooltip
                        message={tooltip}
                        PreviewInput={PreviewInput}
                        setPreviewInput={setPreviewInput}
                        previewInputError={previewInputError}
                        toolTipResponse={toolTipResponse} />)
                    : null
            }
            <form onSubmit={submitQuickSendDetails}>
                <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                    <div className="row flex-a-center">
                        <div className="col-sm-4">
                            <h5 className="mb-0 custom-header-title"><strong>Quick Send</strong></h5>
                        </div>
                        <div className="col-sm-8">
                            <div className="font-22 ms-auto confirm-btn">
                                <button type="button" className="btn btn-primary px-5 mb-2"
                                    title="Preview" onClick={previewHandler}>
                                    Preview
                                </button>&nbsp;

                                {/* <Link to="/add-contact-list" className={"btn btn-primary px-5" + (permissions.includes('contact_add_contact_list') ? '' : ' blur-buttons')}>
                                    Add New Contacts
                                </Link> */}

                                <button type="submit" className={"btn btn-success px-5 mb-2" + (permissions.includes('sendsms_add') ? '' : ' blur-buttons')}>
                                    {ButtonValue}
                                </button>&nbsp;


                                {/* <button type="button" className="btn btn-danger px-4 mb-2" onClick={cancelHandler}>
                                    Cancel/Back
                                </button> */}
                            </div>
                        </div>
                    </div>

                    {/* <div className='row'>
                        <div className='col-sm-9'></div>
                        <div className='col-sm-3'>
                            <Link to="/add-contact-list" className={"btn btn-primary px-5" + (permissions.includes('contact_add_contact_list') ? '' : ' blur-buttons')}>
                                Add New Contacts
                            </Link>
                        </div>
                    </div> */}
                    <hr />
                </div>
                {/* <hr /> */}

                <div className="card card-custom">
                    <div className="card-body">
                        <div className="row">
                            {/* {(showHideDropdown) ? */}

                            <div className='row'>
                                <div className='col-sm-9'></div>
                                <div className='col-sm-3' style={{ marginTop: "-18px" }}>
                                    <Link to="/add-contact-list" className={"btn btn-primary px-5" + (permissions.includes('contact_add_contact_list') ? '' : ' blur-buttons')}>
                                        Add New Contacts
                                    </Link>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div
                                    onClick={() => setisContact('NumberList')}
                                    className="mt-3 mb-3"
                                    style={{ opacity: (isContact !== "NumberList") ? "0.5" : "1" }}>
                                    <label htmlFor="Name" className="form-label form-lable-custom mt-3 mb-3">
                                        Contact Number
                                        <i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <Tagsinput
                                        ref={textInput}
                                        value={NumberList || []}
                                        onChange={handleContactTexts}
                                        name="NumberList"
                                        addOnBlur={true}
                                        onlyUnique={true}
                                        validationRegex={/^[+\d][0-9]*$/}
                                        // validationRegex={/^[27][0-9]{0,11}$/}
                                        inputProps={{ className: 'react-tagsinput-input', placeholder: 'Enter Contact Number e:g 278200000000' }}
                                        accept={() => {
                                            console.log('first')
                                        }}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {NumberListError}
                                    </h6>
                                </div>
                            </div>

                            {/* <div className="col-sm-6">
                                <button type="button" className={(ListType === "NumberList") ? "btn btn-primary px-4 mb-5 mt-3" : "btn btn-secondary px-4 mb-5 mt-3"}
                                    onClick={handleContactTexts}
                                >+ Add Contact Number</button>
                            </div> */}
                        </div>
                        {/* : */}
                        <div className="row">
                            <div className="col-sm-6">
                                <div
                                    onClick={() => setisContact('ContactList')}
                                    className="mt-3 mb-3"
                                    style={{ opacity: isContact !== "ContactList" ? "0.5" : "1" }}>
                                    <label htmlFor="Name" className="form-label form-lable-custom mt-3 mb-3">
                                        Contact List
                                        {/* <i style={{ color: 'red', fontSize: "20px" }}>*</i> */}
                                    </label>
                                    {contactListRender ?
                                        <Multiselect
                                            options={options}
                                            displayValue="name"
                                            placeholder="Select Contact List"
                                            onSelect={contactListHandler}
                                        // style={{ opacity: isContact ? "" : "0.4" }}
                                        // className={isContact ? "" : "contactListOpacity"}

                                        ></Multiselect> : null
                                    }
                                </div>
                                <h6 className="mb-0" style={{ color: "red" }}>
                                    {contactListError}
                                </h6>
                            </div>

                            {/* <div className="col-sm-6">
                                <button type="button"
                                    className={(ListType === "ContactList") ? "btn btn-primary px-4 mb-5 mt-3" : "btn btn-secondary px-4 mb-5 mt-3"}
                                    onClick={contactListHandler}
                                >Select Contact List</button>
                            </div> */}
                        </div>

                        <br />
                        <div className="row">
                            <div className="col-sm-6">

                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="mt-3 mb-3">
                                            <label htmlFor="Name" className="form-label form-lable-custom mt-3 mb-3">
                                                Name <i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="Name"
                                                name="Name"
                                                placeholder="Enter Recipient Name"
                                                value={Name || ''}
                                                onChange={handleChange}
                                                maxLength={NormalField}
                                            />
                                            <h6 className="mb-0" style={{ color: "red" }}>
                                                {nameError}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="mt-3 mb-3">
                                            <label htmlFor="Description" className="form-label form-lable-custom mt-3 mb-3">
                                                Description <i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="Description"
                                                name="Description"
                                                value={Description || ''}
                                                onChange={handleChange}
                                                placeholder="Enter Description"
                                                maxLength={NormalField}
                                            />
                                            <h6 className="mb-0" style={{ color: "red" }}>
                                                {descriptionError}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <Link to="/add-contact-list">
                                        <button type="submit" className={"btn btn-secondary px-5" + (permissions.includes('contact_add_contact_list') ? '' : ' blur-buttons')}>
                                            Add New Contact List
                                        </button>
                                    </Link>
                                </div>
                            </div> */}


                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="mt-3 mb-3">
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Content"
                                            id="Content"
                                            name="Content"
                                            rows="11"
                                            cols="7"
                                            style={{ overflowY: "auto" }}
                                            value={Content}
                                            onChange={handleContent}
                                            readOnly={readonly}
                                        />
                                        <h6 className="mb-0" style={{ color: "red" }}>
                                            {contentError}
                                        </h6>
                                        <label className="form-label form-lable-custom mt-3 mb-3" style={{ textAlign: 'left' }}> Characters:{CharacterCount} / Messages: {CharacterParts}</label>
                                        <i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-sm-6">
                                    <div className="mt-3 mb-3 d-flex">
                                        <select
                                            className="form-select"
                                            name="Template"
                                            id="Template"
                                            value={Template || ''}
                                            onChange={handleTemplate}
                                        >
                                            <option value="">
                                                Select Template
                                            </option>

                                            {
                                                templateListDetails.map((template) => {
                                                    return <option key={template.ID} value={template.ID}> {template.Name} </option>
                                                })
                                            }
                                        </select>
                                        {/* <i style={{ color: 'red', fontSize: "20px", marginLeft: '2%' }}>*</i> */}
                                    </div>

                                    <div className="mt-3 mb-3">
                                        <Link to="/add-template">
                                            <button type="submit" className={"btn btn-secondary px-5" + (permissions.includes('template_add') ? '' : ' blur-buttons')}>
                                                Add New Template
                                            </button>
                                        </Link>
                                    </div>

                                    <div className="mt-3 mb-3">
                                        <p style={{ textAlign: "left" }}>If your message exceeds 160 characters it will
                                            become an EMS (Extended Message). Calculations
                                            will then be based on 153 character increments
                                            and you will be billed per message sent.
                                            <br></br>
                                            Total allowable limit per message would be
                                            800 character.
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-sm-6">

                                    {/* <div className="mt-2 mb-2">
                                        <p style={{ textAlign: "left" }}>If your message exceeds 160 characters it will
                                            become an EMS (Extended Message). Calculations
                                            will then be based on 153 character increments
                                            and you will be billed per message sent.
                                            <br></br>
                                            Total allowable limit per message would be
                                            800 character.
                                        </p>
                                    </div> */}

                                    <div className="mt-3 mb-3">
                                        <label htmlFor="SchedulScheduleSendDateeSend" className="form-label form-lable-custom mt-3 mb-3 quickSend-labels" style={{ textAlign: 'left' }}>
                                            Schedule Send   <i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                        </label>
                                        <select
                                            onChange={handleSelectValue}
                                            className="form-select mb-6"
                                            name="ScheduleSend"
                                            id="ScheduleSend"
                                            value={ScheduleSend || ''}
                                        >
                                            <option value="">Select Schedule</option>
                                            <option value="now">
                                                Now
                                            </option>

                                            <option value="later">
                                                Later
                                            </option>
                                        </select>
                                        <h6 className="mb-0" style={{ color: "red" }}>
                                            {scheduleSendError}
                                        </h6>
                                    </div>

                                    <div className="mt-3 mb-3">
                                        <label
                                            htmlFor="Confirmation"
                                            className="form-label form-lable-custom mt-3 mb-3 quickSend-labels"
                                            style={{ textAlign: 'left' }}
                                        >
                                            Confirmation    <i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                        </label>
                                        <select
                                            className="form-select mb-6"
                                            name="Confirmation"
                                            id="Confirmation"
                                            value={Confirmation || ''}
                                            onChange={handleChange}
                                        >
                                            <option value="no">
                                                No
                                            </option>
                                            <option value={ClientEmail}>
                                                {ClientEmail}
                                            </option>
                                        </select>
                                    </div>
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {confirmationError}
                                    </h6>
                                </div>

                                {
                                    laterOption ?
                                        <div className="col-sm-6">
                                            <div className="mt-3 mb-3">
                                                <DatePicker
                                                    className="form-control"
                                                    name="ScheduleSendDate"
                                                    dateFormat="yyyy/MM/dd"
                                                    selected={ScheduleSendDate}
                                                    minDate={new Date()}
                                                    maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
                                                    readOnly={false}
                                                    disabledKeyboardNavigation
                                                    onChange={(date) => dateHandler(date)}
                                                // onSelect={changeTime}
                                                // onChange={(date) => setSendDetails({...sendDetails,date})} 
                                                />
                                                <h6 className="mb-0" style={{ color: "red" }}>
                                                    {dateError}
                                                </h6>
                                            </div>

                                            <div className="mt-3 mb-3">
                                                <DatePicker
                                                    className="form-control"
                                                    name="ScheduleSendTime"
                                                    onChange={(date) => timeHandler(date)}
                                                    dateFormat="h:mm aa"
                                                    selected={ScheduleSendTime}
                                                    showTimeSelectOnly
                                                    showTimeSelect
                                                    timeCaption="Time"
                                                    timeIntervals={15}
                                                    filterTime={filterPassedTime}
                                                />
                                                <h6 className="mb-0" style={{ color: "red" }}>
                                                    {timeError}
                                                </h6>
                                            </div>

                                        </div>
                                        : null}

                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <label htmlFor="Name" className="form-label form-lable-custom mt-3 mb-3">
                                            Select Product <i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                        </label>
                                        <div className="mt-3 mb-3">
                                            <select
                                                className="form-select"
                                                name="ProductID"
                                                id="ProductID"
                                                value={ProductID || ''}
                                                onChange={handleChange}
                                            >
                                                <option value="">
                                                    Select Product
                                                </option>

                                                {
                                                    ProductList.map((item, index) => {
                                                        return <option key={item.ID} value={index}> {item.Name} </option>
                                                    })
                                                }
                                            </select>
                                            <h6 className="mb-0" style={{ color: "red" }}>
                                                {ProductIDError}
                                            </h6>
                                            {/* <i style={{ color: 'red', fontSize: "20px", marginLeft: '2%' }}>*</i> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* 2 row */}

                        </div>
                    </div>
                </div>
                <ToastContainer theme="colored" />
            </form >
        </div >
    )
}
export default ViewQuickSend;