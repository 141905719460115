import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "../../axios";
import AlertBox from "../../components/AlertBox";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ViewSearchContactList from "./ViewSearchContactList";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";

const ViewSingleContactList = () => {
  const permissions = useSelector((state) => state.getPermissions.permissions);
  const { contactID } = useParams();
  const history = useHistory();
  const [ContactDetails, setContactDetails] = useState({
    Name: "",
    Description: "",
  });
  const { Name, Description } = ContactDetails;
  const [ContactListFiles, setContactListFiles] = useState([]);
  const [serviceCall, setServiceCall] = useState(true);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [alertId, setAlertId] = useState("");
  const [showSerachHandlerWindow, setSerachHandlerWindow] = useState(false);
  const [ContactFileId, setContactFileId] = useState("");
  const [ContactFileName, setContactFileName] = useState("");
  const [ContactFileNumbers, setContactFileNumbers] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true)
    axios
      .get("contact/get_contact_list/" + contactID)
      .then((response) => {
        setContactDetails({
          Name: response.data.data.Name,
          Description: response.data.data.Description,
        });
        setLoader(false)
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            history.push("/logout");
          }
          if (error.response.status !== 200) {
            setError(error.response.data.message);
            setLoader(false)
          }
        }
      });
  }, [contactID, history]);

  useEffect(() => {
    if (serviceCall) {
      setLoader(true)
      axios
        .get("contact/get_all_contact_list_file/" + contactID)
        .then((response) => {
          setContactListFiles(response.data.data.files);
          setServiceCall(false);
          setLoader(false)
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              history.push("/logout");
            }
            if (error.response.status !== 200) {
              setError(error.response.data.message);
              setLoader(false)
            }
          }
        });
    }
  }, [contactID, serviceCall, history]);

  useEffect(() => {
    if (success) {
      toast.success(success);
      setSuccess('')
    }
  }, [success])

  useEffect(() => {
    if (error !== '') {
      toast.error(error);
      setError('')
    }
  }, [error])

  const deleteContactFile = (fileId) => {
    // console.log(fileId)
    setAlert("Please note that you can only delete a Contact List after removing it from a campaign. Do you want to proceed?");
    setAlertType("delete_contact_list_file");
    setAlertId(fileId);
  };

  const alertResponse = (response, type, alertId) => {
    if (response) {
      setLoader(true)
      axios
        .post("contact/" + type, { FileID: alertId })
        .then((response) => {
          setSuccess(response.data.data);
          setServiceCall(true);
          setLoader(false)
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              history.push("/logout");
            } else if (error.response.status !== 200) {
              setError(error.response.data.message);
              setLoader(false)
            }
          }
        });
    }
    setAlert("");
    setAlertType("");
    setAlertId();
  };

  const viewSerachHandler = (contactFileId, ContactFilename, contactFileNumber) => {
    setSerachHandlerWindow(true);
    setContactFileId(contactFileId)
    setContactFileName(ContactFilename)
    setContactFileNumbers(contactFileNumber)
  };

  const hideSearchFileWindow = () => {
    setSerachHandlerWindow(false);
  }

  const cancelHandler = () => {
    history.push("/contact-list");
  };
  return (
    <div>
      {loader ? <Loader /> : null}
      {showSerachHandlerWindow ?
        (<ViewSearchContactList
          ContactFileId={ContactFileId}
          ContactFileName={ContactFileName}
          ContactFileNumbers={ContactFileNumbers}
          hideSearchFileWindow={hideSearchFileWindow}
        />)
        :
        <div>
          <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
            <div className="row flex-a-center">
              <div className="col-sm-6">
                <h5 className="mb-0 custom-header-title"><strong>View Contact Lists : {Name}</strong></h5>
              </div>
              <div className="col-sm-6">
                <div className="font-22 ms-auto confirm-btn">
                  <button
                    type="button"
                    className="btn btn-danger px-4"
                    onClick={cancelHandler}
                  >
                    Cancel/Back
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <div className="row">
            <div className="align-items-center ">
              <div className="row mb-3">
                <label className="col-sm-3 col-form-label" style={{ textAlign: 'left' }}>Name:</label>
                <label
                  className="col-sm-3 col-form-label"
                  style={{ fontWeight: "bold", textAlign: 'left' }}
                >
                  {Name}
                </label>
              </div>

              <div className="row mb-3">
                <label className="col-sm-3 col-form-label" style={{ textAlign: 'left' }}>Description:</label>
                <label
                  className="col-sm-3 col-form-label"
                  style={{ fontWeight: "bold", textAlign: 'left' }}
                >
                  {Description}
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            {alert ? (
              <AlertBox
                message={alert}
                type={alertType}
                alertId={alertId}
                alertResponse={alertResponse}
              />
            ) : null}
            <div className="d-flex align-items-center">
              <div className="title-box">
                <h5 className="mb-0"><strong>Contact Lists Attached</strong></h5>
              </div>
            </div>

            <div className="card radius-10">
              <div className="card-body" style={{ overflowX: 'auto' }}>
                <div className="table-responsive">
                  <table className="table align-middle mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Numbers</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody className="HoverChange">
                      {(permissions.includes('contact_get_all_contact_list_file')) ?
                        ContactListFiles.length > 0 ? (
                          ContactListFiles.map((contactfile, index) => (
                            <tr key={contactfile.ID}>
                              <th scope="row">{index + 1}</th>
                              <td>{contactfile.FileName}</td>
                              <td>{contactfile.Numbers}&nbsp;</td>
                              <td>
                                <span
                                  title="Remove"
                                  className={permissions.includes('contact_delete_contact_list_file') ? '' : ' blur-buttons'}
                                  onClick={() => deleteContactFile(contactfile.ID)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <b className='deleteColor'>Delete</b> &nbsp;
                                  {/* <i className="fa fa-trash"></i> */}
                                </span>

                                <span
                                  title="View"
                                  className={permissions.includes('contact_get_contact_list') ? '' : ' blur-buttons'}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => viewSerachHandler(contactfile.ID, contactfile.FileName, contactfile.Numbers)}
                                >
                                  <b className='editColor'>View</b> &nbsp;
                                  {/* <i className="fa fa-eye"></i> */}
                                </span>

                                {/* <Link to={"view-search-contactlist/" + contactfile.ID}
                                style={{ cursor: "pointer" }}>
                                <i className="fa fa-eye"></i>
                                </Link> */}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr><td className="text-center" colSpan="100" ><b>No Record found</b></td></tr>
                        ) : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer theme="colored" />
        </div>
      }

    </div>
  );
};
export default ViewSingleContactList;
