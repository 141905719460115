import React from "react";

const Tooltip = (props) => {
  return (
    <div className="back-bg">
      <div
        className="col custom-popup"
      >
        <div className="card" style={{ margin: 0 }}>
          <div className="card-body">
            <input
              type="text"
              className="form-control"
              name="PreviewInput"
              value={props.PreviewInput}
              onChange={(e) => props.setPreviewInput(e.target.value)} />

            <p className="mb-0" style={{ color: "red" }}>
              {props.previewInputError}
            </p>

            <p className="mt-2">{props.message}</p>
            <div className="">
              <button
                onClick={() => props.toolTipResponse(true)}
                className="btn btn-primary my-2"
              >
                Send
              </button>
              &nbsp;
              <button
                onClick={() => props.toolTipResponse(false)}
                className="btn btn-danger my-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
