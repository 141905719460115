import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Loader from "../../../components/Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "../../../axios";
import { useSelector } from "react-redux";

const ViewCommonTerms = () => {
    const permissions = useSelector((state) => state.getPermissions.permissions);
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [commonTermsLists, setCommonTermLists] = useState([])

    useEffect(() => {
        if (permissions.includes('support_get_common_terms')) {
            setLoader(true);
            axios.get("support/get_common_terms")
                .then((response) => {
                    setCommonTermLists(response.data.data.CommonTerms)
                    setLoader(false);
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [history, permissions])

    useEffect(() => {
        if (success) {
            toast.success(success, '', 3500);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])
    return (
        <div>
            {loader ? <Loader /> : null}
            <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                {/* <div className="title-box"> */}
                <h5 className="mb-0 custom-header-title"><strong>Support / Common Terms</strong></h5>
                {/* </div> */}
            </div>
            <hr />

            <div className="card card-custom">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <h4 className="support-welcome-msg">Welcome to the Synergistic Common Terms.<br /> Here we will try and shed some light on the various options we offer in our range of Services.</h4>

                            {
                                commonTermsLists.map((listData) => {
                                    return <div className="support-sms" key={listData.ID}>
                                        <hr />
                                        <div className="mt-3 mb-3 d-flex">
                                            <p className="sms-title">{listData.Heading}</p>
                                            <p className="sms-sub-title m-0">
                                                {listData.CategoryName}
                                            </p>
                                        </div>
                                        <div className="mt-3 mb-3">
                                            <p className="sms-message">
                                                {listData.Body}
                                            </p>
                                        </div>
                                        {/* <div className="mt-3 mb-3">
                                            <ul className="support-ul p-0">
                                                <li>Bulk SMS</li>
                                                <li>SMS mail</li>
                                                <li>Email 2 SMS</li>
                                                <li>XML 2 SMS</li>
                                            </ul>
                                        </div> */}
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer theme="colored" />
        </div>
    )
}
export default ViewCommonTerms;
