import React, { useState, useEffect } from "react";
import LoginImage from '../images/login-img.png';
import Logo from '../images/logo.png';
import { useHistory, withRouter } from "react-router-dom";
import axios from "../axios";
import { useDispatch } from 'react-redux';
import { setToken } from "../redux/actions/TokenAction";
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setPermission } from "../redux/actions/PermissionToken";
import Loader from "../components/Loader"
// import {Link} from 'react-router-dom'
const Login = () => {
  // useSelector((state) => state.token);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loginData, setLoginData] = useState({ Username: "", Password: "" });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [changeInput, setChangeInput] = useState("password");
  const [changeIcons, setchangeIcons] = useState("fa fa-eye-slash");
  const { Username, Password } = loginData;
  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (history.location.state !== undefined) {
      setError(history.location.state.message)
      setSuccess(history.location.state.success)
    }
    return {}
  }, [history])

  useEffect(() => {
    if (success !== '') {
      toast.success(success);
      setSuccess('')
      history.location.state = undefined;
    }
  }, [success, history])


  useEffect(() => {
    if (error !== '') {
      toast.error(error);
      setError('');
      history.location.state = undefined;
    }
  }, [error, history])

  function get_permissions() {
    setLoader(true);
    axios.get('group/get_my_permissions')
      .then((response) => {
        dispatch(setPermission(response.data.data))
        setLoader(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            history.push('/logout')
          } else if (error.response.status !== 200) {
            setError(error.response.data.message);
            setLoader(false)
          }
        }
      })
  }

  const Submitlogin = (e) => {
    e.preventDefault();
    setError("")
    if (Username === '' || Password === '') {
      setError("Please enter correct username and password")
      return false;
    }
    const loginData = { Username, Password };
    setLoader(true);
    axios
      .post("login/authenticate", loginData)
      .then((response) => {
        localStorage.setItem("token", response.data.data.token)
        var data = response.data.data.clien_details
        localStorage.setItem("clientFirstName", data.FirstName)
        localStorage.setItem("clientLastName", data.LastName)
        localStorage.setItem("clientEmail", data.EmailAddress)

        localStorage.setItem("ClientTitleField", 15)
        localStorage.setItem("ClientNormalField", 90)

        dispatch(setToken(response.data.data.token));
        get_permissions();
        setLoader(false);

        localStorage.setItem("password_status", response.data.data.change_password)

        if (response.data.data.change_password === 1) {
          history.push({
            pathname: '/password',
            state: { message: response.data.data.message }
          })
        } else if (history.location.pathname === "/login" || history.location.pathname === "/") {
          history.push({
            pathname: '/dashboard',
            state: { message: response.data.data.message }
          })
        }
        else {
          history.push({ pathname: history.location.pathname });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            history.push('/logout')
          } else if (error.response.status !== 200) {
            setError(error.response.data.message);
            setLoader(false);
          }
        }
      });
  };

  const handlePassword = () => {
    if (showPassword) {
      setShowPassword(false)
      setChangeInput("password");
      setchangeIcons("fa fa-eye-slash")
    } else {
      setShowPassword(true)
      setChangeInput("input")
      setchangeIcons("fa fa-eye")
    }
  }

  const handleSupport = () => {
    setError("Please login to submit a ticket")
  }

  return (
    <div>
      {loader ? <Loader /> : null}
      <div className="row custom-margin-0">
        <div className="col-md-6 login-img" style={{ backgroundImage: 'url(' + LoginImage + ')' }}></div>

        <div className="col-md-6 text-left" style={{ padding: '10% 8%' }}>
          <div className="row">
            <div className="col-md-12 mb-3 p-0">
              <img src={Logo} alt="SYNERGISTIC" style={{
                width: '70%',
                height: 'auto',
                display: 'block'
              }} />
            </div>
          </div>

          <form onSubmit={Submitlogin}>
            <div className="row custom-margin-0">
              <div className="col-md-12 mt-5 p-0">
                <h4 className="login-title">Login</h4>
              </div>
              <div className="col-md-12 mb-2 mt-2 p-0">
                <input type="text" className="form-control login-input" placeholder="Username" name="Username" value={Username} onChange={handleChange} />
              </div>
              <div className="col-md-12 mb-2 mt-2 flex-center-end p-0">
                <i className={changeIcons + " password-icon"} style={{ color: (showPassword) ? 'blue' : 'grey' }}
                  onClick={handlePassword}></i>
                <input type={changeInput} className="form-control login-input" placeholder="Password" name="Password" value={Password} onChange={handleChange} />
              </div>
              <div className="col-md-12 mb-3 mt-3 p-0">
                <input type="submit" className="btn btn-info signin-btn" value="Sign in" />
                <Link to="/reset-password">
                  <p style={{ color: "black" }} className="btn btn-info forgot-password privacy-link">Forgot Password</p>
                </Link>
              </div>
            </div>
          </form>


          <div>
            <ul style={{ listStyle: "none", textAlign: "left", padding: "0" }}>
              <li>
                <Link to="/terms" style={{ color: '#4C5258' }}>
                  <p className="btn term-link-btn privacy-link p-0">T's &amp; C's</p>
                </Link>
              </li>
              <li>
                <Link to="/submit-a-ticket">
                  <p className="btn term-link-btn privacy-link p-0" onClick={handleSupport}>Support</p>
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy">
                  <p className="btn term-link-btn privacy-link p-0">Privacy Policy</p>
                </Link>
              </li>
            </ul>
          </div>

          {/* <div className="row mt-5">
            <div className="col-md-12">
              <Link to="/terms" style={{ color: '#4C5258' }}>
                <p className="btn term-link-btn privacy-link p-0">Ts &amp; Cs</p>
              </Link>
              <Link to="/submit-a-ticket">
                <p className="btn term-link-btn privacy-link p-0" onClick={handleSupport}>Support</p>
              </Link>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Link to="/privacy-policy">
                <p className="btn term-link-btn privacy-link p-0">Privacy Policy</p>
              </Link>
            </div>
          </div> */}

        </div>
      </div>
      <ToastContainer theme="colored" />
    </div>
  );
};

export default withRouter(Login);
