import React ,{useEffect} from 'react'
import { setToken } from "../redux/actions/TokenAction";
import {Redirect, useHistory , useParams} from 'react-router-dom'
import { useDispatch } from 'react-redux';

 const Logout = () => {
    const {LogoutFlag} = useParams();
    let history = useHistory();
	const dispatch= useDispatch();

    useEffect(() =>{
        if (localStorage.getItem("token")) {
            localStorage.removeItem("token");
            localStorage.removeItem("clientFirstName");
            localStorage.removeItem("clientLastName");
            localStorage.removeItem("clientEmail");
            dispatch(setToken(''));
            if(!LogoutFlag){
                history.push({
                    pathname: '/login',
                    state: { message: 'Token Expired!! Please Login Again' }
                })
            }
            
        }
        return () => {
        
        };
    })
    
    return <Redirect to='/login'/>
}
export default Logout;