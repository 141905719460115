import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import FilterComponent from '../FilterComponent';
import axios from "../axios"

const DataTableExample = () => {
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [users, setUsers] = useState([])
    const columns = [
        {
            name: 'Name',
            selector: row => row.Name,
            sortable: true,
            style: "font-weight:bold;font-size:20px",
            // grow: 1,
        },
        {
            name: 'EmailAddress',
            selector: row => row.EmailAddress,
            sortable: true,
        },
    ];

    useEffect(() => {
        axios.get("/user/getall")
            .then((response) => {
                setUsers(response.data.data.users);
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error)
                }
            })
    },[])
// const data = [
//     {
//         id: 1,
//         title: 'Beetlejuice',
//         year: '1988',
//     },
//     {
//         id: 2,
//         title: 'Ghostbusters',
//         year: '1984',
//     },
//     {
//         id: 3,
//         title: 'Ghostbusters',
//         year: '1984',
//     },
//     {
//         id: 4,
//         title: 'busters',
//         year: '1984',
//     },
//     {
//         id: 5,
//         title: 'Willam',
//         year: '1984',
//     },
//     {
//         id: 6,
//         title: 'John',
//         year: '1984',
//     },
//     {
//         id: 7,
//         title: 'deepika',
//         year: '1984',
//     },
//     {
//         id: 8,
//         title: 'AAron',
//         year: '1984',
//     },
//     {
//         id: 9,
//         title: 'Dora',
//         year: '1984',
//     },
//     {
//         id: 10,
//         title: 'Dollas',
//         year: '1984',
//     },
//     {
//         id: 11,
//         title: 'Muray',
//         year: '1984',
//     },
//     {
//         id: 12,
//         title: 'Breet',
//         year: '1984',
//     },
// ]
const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
        }
    };

    return (
        <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    );
}, [filterText, resetPaginationToggle]);



const filteredItems = users.filter(
    item => item.Name && item.Name.toLowerCase().includes(filterText.toLowerCase()),
);

// const paginationComponentOptions = {
//     rowsPerPageText: '',
//     rangeSeparatorText: '',
//     selectAllRowsItem: true,
//     selectAllRowsItemText: '',
// };

return (
    <div>
        <DataTable
            columns={columns}
            data={filterText ? filteredItems : users}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            // selectableRows
            persistTableHead
        // defaultSortFieldId={1}
        // paginationComponentOptions={paginationComponentOptions}
        />
    </div>
)
}

export default DataTableExample