import React from 'react'

const FilterComponent = (props) => {
  return (
    <div>
        <input type="text" value={props.filterText} onChange={props.onFilter} placeholder="Search by Title"/>
        <button type="btn btn-primary" onClick={props.onClear}>X</button>
    </div>
  )
}

export default FilterComponent