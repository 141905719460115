import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router';
import axios from '../../../axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../../components/Loader";
import { Prompt } from 'react-router-dom';

const AddEmailConfigure = () => {
    const permissions = useSelector((state) => state.getPermissions.permissions);
    const { productid } = useParams();
    const { accountId } = useParams();
    const history = useHistory();

    const [EmailDetails, setEmailDetails] = useState({
        ProductID: productid || "",
        Username: "",
        Authenticator: "",
        SendAddress: "",
        ReplyAddress: "",
        SenderDomain: ""
    })
    const { Username, Authenticator, SendAddress, ReplyAddress, SenderDomain } = EmailDetails;
    const [loader, setLoader] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [usernameError, setUsernameError] = useState("")
    const [authenticatorError, setAuthenticatorError] = useState("")
    const [sendAddressError, setSendAddressError] = useState("")
    const [replyAddressError, setReplyAddressError] = useState("")
    const [senderDomainError, setSenderDomainError] = useState("")
    const [FromDataChanged, setFromDataChanged] = useState(false)

    const NormalField = (localStorage.getItem("ClientNormalField") ? localStorage.getItem("ClientNormalField") : "");

    const handleChange = (e) => {
        setEmailDetails({ ...EmailDetails, [e.target.name]: e.target.value });
        setFromDataChanged(true)
    }

    const validate = () => {
        var flag = true;
        setUsernameError("");
        setAuthenticatorError("");
        setSendAddressError("");
        setReplyAddressError("");
        setSenderDomainError("");

        if (Username === "") {
            flag = false;
            setUsernameError("Username field is required");
        } else if (Username.length < 8 || Username.length > 50) {
            flag = false;
            setUsernameError("Username minimum string length is 8 or maximum string length is 50");
        }

        if (Authenticator !== "") {
            if (Authenticator.length < 0 || Authenticator.length > 50) {
                flag = false;
                setAuthenticatorError("Authenticator minimum string length is 0 or maximum string length is 50");
            }
        }

        var Emailpattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
        if (SendAddress === "") {
            flag = false;
            setSendAddressError("Send Address field is required");
        } else if (!Emailpattern.test(SendAddress)) {
            flag = false
            setSendAddressError("Please enter valid email address");
        }

        if (ReplyAddress === "") {
            flag = false;
            setReplyAddressError("Reply Address field is required");
        } else if (!Emailpattern.test(ReplyAddress)) {
            flag = false
            setReplyAddressError("Please enter valid email address");
        }

        var hostCheck = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
        if (SenderDomain === "") {
            flag = false;
            setSenderDomainError("Sender Domain field is required");
        } else if (!hostCheck.test(SenderDomain)) {
            flag = false
            setSenderDomainError("Please enter valid domain address");
        }

        return flag;
    }
    const submiitEmailDetails = (e) => {
        e.preventDefault();
        setSuccess("")
        setError("");
        const flag = validate()
        if (flag) {
            setLoader(true)
            axios.post("product/add_api_email", EmailDetails)
                .then((response) => {
                    setFromDataChanged(false)
                    history.push({
                        pathname: "/add-client-product/" + accountId + "/" + productid,
                        state: { message: response.data.data }
                    })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }
    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    useEffect(() => {
        if (permissions.includes('product_get_product_config')) {
            setLoader(true);
            axios.get('product/get_product_config/' + productid)
                .then((response) => {
                    setEmailDetails({
                        ProductID: productid,
                        Username: (response.data.data.Username) ? response.data.data.Username : '',
                        Authenticator: (response.data.data.Authenticator) ? response.data.data.Authenticator : '',
                        SendAddress: (response.data.data.SendAddress) ? response.data.data.SendAddress : '',
                        ReplyAddress: (response.data.data.ReplyAddress) ? response.data.data.ReplyAddress : '',
                        SenderDomain: (response.data.data.SenderDomain) ? response.data.data.SenderDomain : ''
                    })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [history, productid, permissions])

    const backHandler = () => {
        history.push("/add-client-product/" + accountId + "/" + productid)
    }

    return (
        <div>
             <Prompt
                when={FromDataChanged}
                message="You are navigating away from this screen. You will lose the typed information. Are you sure you want to leave this screen?"
            />
            {loader ? <Loader /> : null}
            <form onSubmit={submiitEmailDetails}>
                <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                    <div className="row flex-a-center">
                        <div className="col-sm-6">
                            <h5 className="mb-0 custom-header-title"><strong>Add Configure - Email</strong></h5>
                        </div>
                        <div className="col-sm-6">
                            <div className="font-22 ms-auto confirm-btn">
                                <button type="submit" className={"btn btn-success px-5" + (permissions.includes('product_add_api_email') ? '' : ' blur-buttons')}>
                                    Save
                                </button>
                                &nbsp;
                                <button type="button" className="btn btn-danger px-4"
                                    onClick={backHandler}>
                                    Cancel/Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                <div className="card card-custom">
                    <div className="card-body">

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >Username<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <input
                                        autoFocus
                                        type="text"
                                        className="form-control mb-3"
                                        id="Username"
                                        name="Username"
                                        placeholder="Enter Username"
                                        value={Username || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {usernameError}
                                    </h6>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >Authenticator<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-3"
                                        id="Authenticator"
                                        name="Authenticator"
                                        placeholder="Enter Authenticator"
                                        value={Authenticator || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {authenticatorError}
                                    </h6>
                                </div>
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >Send Address<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-3"
                                        id="SendAddress"
                                        name="SendAddress"
                                        placeholder="Enter Send Address"
                                        value={SendAddress || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {sendAddressError}
                                    </h6>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >Reply Address<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-3"
                                        id="ReplyAddress"
                                        name="ReplyAddress"
                                        placeholder="Enter Reply Address"
                                        value={ReplyAddress || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {replyAddressError}
                                    </h6>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >Sender Domain<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-3"
                                        id="SenderDomain"
                                        name="SenderDomain"
                                        placeholder="Enter Sender Domain"
                                        value={SenderDomain || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {senderDomainError}
                                    </h6>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </form >
            <ToastContainer theme="colored" />
        </div>);
};
export default AddEmailConfigure;