import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AlertBox from "../../components/AlertBox";
import PaginationComp from "../../components/PaginationComp";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../axios';
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import RecordHandler from '../../components/RecordHandler';

const ViewTemplate = () => {
    const permissions = useSelector((state) => state.getPermissions.permissions);
    const history = useHistory();
    var url = new URL(window.location.href);
    var searchTxt = url.searchParams.get("search");
    var pageNo = url.searchParams.get("page");

    const [templateList, setTemplateList] = useState([])
    const [serviceCall, setServiceCall] = useState(true);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [search, setSearch] = useState("");
    const [searchValue, setSearchValue] = useState(searchTxt || "");
    const [page, setPage] = useState(pageNo || 1);
    const [pagination, setPagination] = useState([]);
    const [perPage, setPerPage] = useState(0);
    const [no_of_records, no_of_recordsHandler] = useState(10);
    const [alert, setAlert] = useState("");
    const [alertType, setAlertType] = useState("");
    const [alertId, setAlertId] = useState("");
    const [loader, setLoader] = useState(false);
    const [ColorId, setColorId] = useState("");

    useEffect(() => {
        if (history.location.state !== undefined) {
            setSuccess(history.location.state.message)
        }
        if (serviceCall) {
            setLoader(true)
            axios
                .get("template/getall?no_of_records=" + no_of_records + "&page=" + page + "&search=" + searchValue)
                .then((response) => {
                    setTemplateList(response.data.data.TemplateID)
                    setPagination(response.data.data.pagination);
                    setPerPage(response.data.data.per_page);
                    setServiceCall(false);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (
                            error.response.status === 401 ||
                            error.response.status === 403
                        ) {
                            history.push("/logout");
                        }
                        if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [serviceCall, no_of_records, page, searchValue, history])

    useEffect(() => {
        var url = new URL(window.location.href);
        var pageNo = url.searchParams.get("page");
        setSuccess('');
        setPage(pageNo || 1);
        setServiceCall(true);
    }, [history.location.search]);

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
            history.replace({
                state: {}
            })
        }
    }, [success, history])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    const searchHandler = () => {
        setSearchValue(search);
        setServiceCall(true);
        history.push({
            search: "page=1&search=" + search,
        });
    }

    const clearSearchHandler = () => {
        history.push({ search: "page=1&search=", });
        setSearch('');
        setSearchValue('');
        setServiceCall(true);
    }

    const recordHandler = (e) => {
        no_of_recordsHandler(e.target.value)
        setPage(1)
        setServiceCall(true)
        history.push({
            search: "no_of_records=" + e.target.value + "&page=1&search=",
        });
    }

    const deleteTemplate = (templateid) => {
        setAlert("Please note that you can only delete a Template after removing it from a Product or Campaign. Are you sure you want to Delete?");
        setAlertType("delete");
        setAlertId(templateid);
    }

    const alertResponse = (response, type, alertId) => {
        if (response) {
            setLoader(true)
            axios
                .post("template/" + type, { TemplateID: alertId })
                .then((response) => {
                    setSuccess(response.data.data);
                    setServiceCall(true);
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response.status === 401 || error.response.status === 403) {
                        history.push("/logout");
                    } else if (error.response.status !== 200) {
                        setError(error.response.data.message);
                        setLoader(false)
                    }
                });
        }
        setAlert("");
        setAlertType("");
        setAlertId();
    };

    const handleKeyEnter = (e) => {
        return (e.key === 'Enter') ? searchHandler() : null
    }

    const handleColor = (contactId) => {
        setColorId(contactId)
    }

    return (
        <div>
            {loader ? <Loader /> : null}
            {alert ? (
                <AlertBox
                    message={alert}
                    type={alertType}
                    alertId={alertId}
                    alertResponse={alertResponse}
                />
            ) : null}
            <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                <div className="row flex-a-center">
                    <div className="col-sm-6">
                        <h5 className="mb-0 custom-header-title"><strong>View Templates</strong></h5>
                    </div>
                    <div className="col-sm-6">
                        <div className="font-22 ms-auto confirm-btn">
                            <Link to="/add-template" className={"btn btn-primary px-4 mb-1" + (permissions.includes('template_add') ? '' : ' blur-buttons')}>
                                Add New
                            </Link>
                            {/* <button type="button" className="btn btn-primary px-4 mb-1"> Cancel/Back</button> */}
                        </div>
                    </div>
                </div>
            </div>
            <hr />

            <div className="row">
                <div className="col-sm-3">
                    <input
                        type="text"
                        className="form-control mb-1"
                        id="search"
                        name="search"
                        placeholder="Search Here"
                        value={search}
                        onKeyDown={(e) => handleKeyEnter(e)}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>

                <div className="col-sm-3">
                    <button
                        style={{ float: 'left' }}
                        type="submit"
                        className="btn btn-primary mb-1"
                        title="Search"
                        onClick={searchHandler}
                    >
                        <i className="fa fa-search"></i>
                    </button>

                    {(searchValue) ?
                        <button
                            style={{ float: 'left', marginLeft: '5px' }}
                            type="submit"
                            className="btn btn-primary"
                            title="Clear Search"
                            onClick={clearSearchHandler}
                        >
                            <i className="fa fa-eraser"></i>
                        </button>
                        : null}
                </div>
            </div>

            <div className="card radius-10">
                <div className="card-body" style={{ overflowX: 'auto' }}>
                    <div className="table-responsive">
                        <table className="table align-middle mb-0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Content</th>
                                    <th>Date Created</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody className="HoverChange">
                                {(permissions.includes('template_getall')) ?
                                    templateList.length > 0 ? templateList.map((template, index) => (
                                        <tr key={template.ID}
                                            onClick={() => handleColor(template.ID)}>
                                            <th scope="row">
                                                {index + (parseInt(page) * perPage - perPage) + 1}
                                            </th>
                                            {
                                                (template.ID === ColorId) ?
                                                    <td style={{ 'color': '#004C97', fontWeight: 'bold', fontSize: '16px' }}>{template.Name}</td> :
                                                    <td>{template.Name}</td>
                                            }
                                            {/* <td>{template.Name}</td> */}
                                            <td>{template.Content}</td>
                                            <td>{template.Created}</td>
                                            <td>
                                                <Link to={"/add-template/" + template.ID} title="Edit"
                                                    className={permissions.includes('template_edit') ? '' : ' blur-buttons'}>
                                                        <b className='editColor'>Edit</b> &nbsp;
                                                    {/* <i className="fa fa-edit"></i> */}
                                                </Link>
                                                <span
                                                    title="Remove"
                                                    onClick={() => deleteTemplate(template.ID)}
                                                    className={permissions.includes('template_delete') ? '' : ' blur-buttons'}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <b className='deleteColor'>Delete</b> &nbsp;
                                                    {/* <i className="fa fa-trash"></i> */}
                                                </span>


                                            </td>
                                        </tr>
                                    )) : <tr><td className="text-center" colSpan="100" ><b>No Record found</b></td></tr> : null}
                            </tbody>
                        </table>
                    </div>

                    <RecordHandler no_of_records={no_of_records} recordHandler={recordHandler}/>
                    <PaginationComp
                        pages={pagination}
                        link={"template"}
                        activePage={parseInt(page)}
                        search={"search=" + searchValue} />
                </div>
            </div>
            <ToastContainer theme="colored" />
        </div>
    )
}

export default ViewTemplate;