import React, { useState, useEffect, useRef } from 'react';
import axios from '../../axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../components/Loader";
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import { Prompt } from 'react-router-dom';

const TopUpBalance = () => {
    const permissions = useSelector((state) => state.getPermissions.permissions);
    const formRef = useRef(null);

    const history = useHistory();

    const [ProcessRequest, setProcessRequest] = useState({ CHECKSUM: '', PAY_REQUEST_ID: '' })
    const [ProcessUrl, setProcessUrl] = useState('')

    const [amount, setAmount] = useState("")
    const [totalAmount, setTotalAmount] = useState("")
    const [currentAmount, setCurrentAmount] = useState(0)
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false);
    const [FromDataChanged, setFromDataChanged] = useState(false)

    const handleAmount = (e) => {
        var input = e.target.value;
        // const value = input.replace(/[^0-9.]/g, "");
        setAmount(input)
        var total = parseFloat(currentAmount) + parseFloat(input)
        if (isNaN(total)) {
            setTotalAmount(0)
        } else {
            setTotalAmount(total.toFixed(2))
        }
        setFromDataChanged(true)
    }

    useEffect(() => {
        if (permissions.includes('topupbalance_get_current_balance')) {
            setLoader(true)
            axios.get("topupbalance/get_current_balance")
                .then((response) => {
                    setCurrentAmount(response.data.data)
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response.status === 401 || error.response.status === 403) {
                        history.push("/logout");
                    } else if (error.response.status !== 200) {
                        setError(error.response.data.message);
                        setLoader(false)
                    }
                })
        }
    }, [history, permissions])

    const submitTopUpHandler = () => {
        setLoader(true)
        axios.post("topupbalance/top_up", { TopUpAmount: amount })
            .then((response) => {
                setFromDataChanged(false)
                setProcessRequest(response.data.data.ProcessRequest)
                setProcessUrl(response.data.data.ProcessUrl)
                setLoader(false)
                formRef.current.submit();
            })
            .catch((error) => {
                if (error.response.status === 401 || error.response.status === 403) {
                    history.push("/logout");
                } else if (error.response.status !== 200) {
                    setError(error.response.data.message);
                    setLoader(false)
                }
            })
    }


    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    return (
        <div>
            <Prompt
                when={FromDataChanged}
                message="You are navigating away from this screen. You will lose the typed information. Are you sure you want to leave this screen?"
            />
            {loader ? <Loader /> : null}
            <ToastContainer theme="colored" />

            <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                <div className="row flex-a-center">
                    <div className="col-sm-6">
                        <h5 className="mb-0 custom-header-title"><strong>Top Up Balance</strong></h5>
                    </div>
                    <div className="col-sm-6">
                        <div className="font-22 ms-auto confirm-btn">
                            <button
                                type="submit"
                                className={"btn btn-primary px-5" + (permissions.includes('topupbalance_top_up') ? '' : ' blur-buttons')}
                                onClick={submitTopUpHandler}>TopUp</button>
                        </div>
                    </div>
                </div>
            </div>
            <hr />

            <div className='card'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-sm-2'></div>
                        <div className='col-sm-4'>
                            <br />
                            <h5 className="mb-0 custom-header-title"><strong>Current Balance</strong></h5>
                        </div>
                        <div className='col-sm-4'>
                            <br />
                            <h5 className="mb-0 custom-header-title"><strong>ZAR {currentAmount}</strong></h5></div>
                        <div className='col-sm-2'></div>
                    </div>
                    <br />

                    <div className='row'>
                        <div className='col-sm-2'></div>
                        <div className='col-sm-4'>
                            <br />
                            <h5 className="mb-0 custom-header-title"><strong>Top Up Amount</strong></h5>
                        </div>
                        <div className='col-sm-4'>
                            <br />
                            <div className="input-group">
                                <span
                                    className="input-group-text bg-transparent">
                                    <strong>ZAR</strong>
                                </span>
                                <input
                                    type="number"
                                    className="form-control border-start-0 hideNumberArrow"
                                    id="amount"
                                    name="amount"
                                    value={amount}
                                    onChange={handleAmount}
                                    placeholder='Enter Amount'
                                />
                            </div>
                        </div>
                        <div className='col-sm-2'></div>
                    </div>
                    <br />

                    <div className='row'>
                        <div className='col-sm-2'></div>
                        <div className='col-sm-4'>
                            <br />
                            <h5 className="mb-0 custom-header-title"><strong>New Total</strong></h5>
                        </div>
                        <div className='col-sm-4'>
                            <br />
                            <h5 className="mb-0 custom-header-title"><strong>ZAR {totalAmount}</strong></h5></div>
                        <div className='col-sm-2'></div>
                    </div>
                    <br />
                    <br />

                    <div className='row'>
                        <div className='col-sm-4'></div>
                        <div className='col-sm-6'>
                            {/* <button
                                type="submit"
                                className={"btn btn-primary px-5" + (permissions.includes('topupbalance_top_up') ? '' : ' blur-buttons')}
                                onClick={submitTopUpHandler}>TopUp</button> */}
                        </div>
                    </div>
                    <br />

                    {
                        (ProcessUrl && ProcessRequest.CHECKSUM && ProcessRequest.PAY_REQUEST_ID) ?
                            <form ref={formRef} method="post" action={ProcessUrl}>
                                <input type="hidden" name="CHECKSUM" value={ProcessRequest.CHECKSUM} />
                                <input type="hidden" name="PAY_REQUEST_ID" value={ProcessRequest.PAY_REQUEST_ID} />
                            </form> : null
                    }

                </div>
            </div>
        </div >
    )
}

export default TopUpBalance