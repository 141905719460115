import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "../../axios";
import Loader from "../../components/Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";

const VolumePerProductReport = () => {
    const permissions = useSelector((state) => state.getPermissions.permissions);
    const history = useHistory();

    const [dateRange, setDateRange] = useState([null, null]);
    const [Accountlist, setAccountList] = useState([])
    const [loader, setLoader] = useState(false);
    const [startDate, endDate] = dateRange;
    const [serviceCall, setServiceCall] = useState(true);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [AccountID, setAccountID] = useState("")
    const [ProductList, setProductList] = useState([])
    const [ProductID, setProductID] = useState("")

    useEffect(() => {
        if (permissions.includes("reports_get_accounts_linked")) {
            if (serviceCall) {
                setLoader(true)
                axios.get("reports/get_accounts_linked")
                    .then((response) => {
                        setAccountList(response.data.data.aAccounts)
                        setServiceCall(false)
                        setLoader(false)
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (
                                error.response.status === 401 ||
                                error.response.status === 403
                            ) {
                                history.push("/logout");
                            }
                            if (error.response.status !== 200) {
                                setError(error.response.data.message);
                                setLoader(false)
                            }
                        }
                    })
            }
        }
    }, [history, serviceCall,permissions])

    useEffect(() => {
        if (AccountID) {
            setLoader(true)
            axios.get("reports/get_products_linked/" + AccountID)
                .then((response) => {
                    setProductList(response.data.data.aProducts)
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (
                            error.response.status === 401 ||
                            error.response.status === 403
                        ) {
                            history.push("/logout");
                        }
                        if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        } else {
            setProductList([])
        }
    }, [history, AccountID])

    useEffect(() => {
        if (success) {
            toast.success(success, '', 3500);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])

    const cancelHandler = () => {
        history.push("/reports")
    }

    return (
        <div>
            {loader ? <Loader /> : null}
            <ToastContainer theme="colored" />
            <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                <div className="row flex-a-center">
                    <div className="col-sm-6">
                        <h5 className="mb-0 custom-header-title"><strong>Reports - Volume Per Product</strong></h5>
                    </div>
                    <div className="col-sm-6">
                        <div className="font-22 ms-auto confirm-btn">
                            <button type="button" className="btn btn-danger px-4 mb-1"
                                onClick={cancelHandler}>
                                Cancel/Back</button>
                        </div>
                    </div>
                </div>
            </div>
            <hr />

            <div className="row">
                <div className="col-sm-3">
                    <label
                        style={{ float: 'left' }}
                        className="form-label form-lable-custom mt-3 mb-3 ml-5"
                    >Date Range
                    </label>
                    <DatePicker
                        className="form-control"
                        dateFormat="yyyy/MM/dd"
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        monthsShown={2}
                        disabledKeyboardNavigation
                        onChange={(update) => {
                            setDateRange(update);
                        }}
                    />
                </div>

                <div className="col-sm-3">
                    <label
                        style={{ float: 'left' }}
                        className="form-label form-lable-custom mt-3 mb-3 ml-5"
                    >&nbsp;
                    </label>
                    <select
                        className="form-select mb-3"
                        name="AccountID"
                        id="AccountID"
                        value={AccountID}
                        onChange={(e) => setAccountID(e.target.value)}
                    >
                        <option value="">Select Account</option>
                        {Accountlist.map((item) => {
                            return <option value={item.ID} key={item.ID}>{item.AccountName}</option>
                        })}
                    </select>
                </div>

                <div className="col-sm-3">
                    <label
                        style={{ float: 'left' }}
                        className="form-label form-lable-custom mt-3 mb-3 ml-5"
                    >&nbsp;
                    </label>
                    <select
                        className="form-select mb-3"
                        name="AccountID"
                        id="AccountID"
                        value={ProductID}
                        onChange={(e) => setProductID(e.target.value)}
                    >
                        <option value="">Select Product</option>
                        {ProductList.map((item) => {
                            return <option value={item.ID} key={item.ID}>{item.Name}</option>
                        })}
                    </select>
                </div>

                <div className="col-sm-3">
                    <button
                        style={{ float: "left" }}
                        type="submit"
                        className="btn btn-primary mt-5"
                        title="Search"
                    // onClick={searchHandler}
                    >
                        <i className="fa fa-search"></i>
                    </button>

                    {/* {
                        (searchValue || (startDate && endDate)) ? */}
                    <button
                        style={{ float: "left", marginLeft: '5px' }}
                        type="submit"
                        className="btn btn-primary mt-5"
                        title="Clear Search"
                    // onClick={clearSearchHandler}
                    >
                        <i className="fa fa-eraser"></i>
                    </button>
                    {/* : null} */}
                </div>
            </div>

        </div>
    )
}

export default VolumePerProductReport