import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "../../axios";
import { useHistory } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../components/Loader";
import AlertBox from "../../components/AlertBox";
import UserPermission from "../../containers/UserManagement/UserPermission";
import { useSelector } from "react-redux";
import { Prompt } from 'react-router-dom';

const AddUser = () => {
    const permissions = useSelector((state) => state.getPermissions.permissions);
    const { userId } = useParams();
    let history = useHistory();

    const [userDetails, setUserDetails] = useState({
        UserID: userId || 0,
        Name: "",
        Surname: "",
        Username: "",
        Password: "",
        EmailAddress: "",
        ContactNumber: "",
    });

    const [ShowPassword, setShowPassword] = useState(false);
    const [ShowConfirmPassword, setShowConfirmPassword] = useState(false);
    const [nameError, setNameError] = useState("");
    const [surnameError, setSurnameError] = useState("");
    const [usernameError, setUsernameError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [contactError, setContactError] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loader, setLoader] = useState(false);
    const [alert, setAlert] = useState("");
    // const [alertCancel, setAlertCancel] = useState("");
    const [userPermission, setUserPermission] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState("")
    const [cPasswordError, setCPasswordError] = useState("");
    const [FromDataChanged, setFromDataChanged] = useState(false)

    // const [alertType, setAlertType] = useState("");
    // const [alertId, setAlertId] = useState("");
    const NormalField = (localStorage.getItem("ClientNormalField") ? localStorage.getItem("ClientNormalField") : "");

    const { Name, Surname, Username, Password, EmailAddress, ContactNumber } = userDetails;

    const handleChange = (e) => {
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
        setFromDataChanged(true)
    };

    const handleChangeName = (e) => {
        let getName = e.target.value;
        getName = getName.replace(/[^a-zA-ZÀ-ȕ ]/g, "");
        setUserDetails({ ...userDetails, Name: getName });
        setFromDataChanged(true)
    }
    const handleChangeSurname = (e) => {
        let getSurname = e.target.value;
        getSurname = getSurname.replace(/[^a-zA-ZÀ-ȕ]/g, "");
        setUserDetails({ ...userDetails, Surname: getSurname });
        setFromDataChanged(true)
    }

    const handleUsername = (e) => {
        let getUserName = e.target.value;
        getUserName = getUserName.replace(/[^A-Za-z0-9]/gi, "");
        setUserDetails({ ...userDetails, Username: getUserName });
        setFromDataChanged(true)
    }

    useEffect(() => {
        if (userId && permissions.includes('user_get')) {
            setLoader(true)
            axios
                .get(`user/get/${userId}`)
                .then((response) => {
                    setUserDetails({
                        UserID: response.data.data.ID,
                        Name: response.data.data.Name,
                        Surname: response.data.data.Surname,
                        Username: response.data.data.Username,
                        EmailAddress: response.data.data.EmailAddress,
                        ContactNumber: response.data.data.ContactNumber,
                        Password: ''
                    })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [userId, history, permissions])

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    function validate() {
        var flag = true
        setNameError("");
        setSurnameError("");
        setUsernameError("");
        setPasswordError("");
        setEmailError("");
        setContactError("");
        setCPasswordError("")

        if (Name === "") {
            flag = false
            setNameError("Name field is required");

        }
        if (Surname === "") {
            flag = false
            setSurnameError("Surname field is required");
        }
        if (Username === "") {
            flag = false
            setUsernameError("Username field is required");
        }

        var passwordCheck = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*^+#=?&_])[A-Za-z\d@$#=+_!%*^?&]{8,}$/;
        if (Password === '' && userId === undefined) {
            flag = false
            setPasswordError("Password field is required");
        } else if (Password.length < 8 && Password !== '') {
            flag = false;
            setPasswordError("Password minimum length should 8")
        }
        else if (!passwordCheck.test(Password) && Password !== '') {
            flag = false;
            setPasswordError("Password should contains from following:- <br/> 1) Uppercase letters: A to Z <br/> 2) Lowercase letters: a to z <br/> 3) Numbers: 0 to 9 <br/> 4) Special characters <br/> 5) Password should be minimum 8 characters ")
        }


        var Emailpattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (EmailAddress === "") {
            flag = false
            setEmailError("Email Address field is required");
        } else if (!Emailpattern.test(EmailAddress)) {
            flag = false
            setEmailError("Please enter valid email address");
        }

        var pattern = new RegExp(/^[0-9\b]+$/);
        if (ContactNumber === '') {
            flag = false
            setContactError("Contact field is required");

        } else if (ContactNumber.length < 10 || ContactNumber.length > 12) {
            flag = false
            setContactError("Please enter valid 10 or 12 digit contact number");

        } else if (!pattern.test(ContactNumber)) {
            flag = false
            setContactError("Please enter only number");
        }

        // if (confirmPassword === "" && userId === "") {
        //     flag = false
        //     setCPasswordError("Confirm Password field is required")
        // } else if (confirmPassword.length < 8 && confirmPassword !== '') {
        //     flag = false
        //     setCPasswordError("Password field minimum length should be 8");
        // } else if (!passwordCheck.test(confirmPassword) && confirmPassword !== '') {
        //     flag = false;
        //     setCPasswordError("Password should contains from following:- <br/> 1) Uppercase letters: A to Z <br/> 2) Lowercase letters: a to z <br/> 3) Numbers: 0 to 9 <br/> 4) Special characters <br/> 5) Password should be minimum 8 characters ")
        // } 

        if (Password !== confirmPassword) {
            flag = false;
            setCPasswordError("Password and confirm password does not match!")
        }


        return flag
    }

    const SavePromptHAndler = () => {
        setAlert("Are you sure you want to save the details?")
    }

    const alertResponse = (response) => {
        if (response) {
            var url = (userId) ? 'edit' : 'add';
            setLoader(true)
            axios
                .post("user/" + url, userDetails)
                .then((response) => {
                    setFromDataChanged(false)
                    history.push({
                        pathname: '/user',
                        state: { message: response.data.data.message }
                    })
                    //  setSuccess(response.data.data.message);
                    //  history.push('/add-user/'+response.data.data.UserID)
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                });
        }
        // history.push("/user")
        setAlert("")
    }

    const submitUserDetails = (e) => {
        e.preventDefault();
        setSuccess("")
        setError("");
        const flag = validate()
        if (flag) {
            SavePromptHAndler()
        }
    };

    // const SavePromptCancelHAndler = () => {
    //     setAlertCancel("Do you want to leave the page")
    // }

    // const alertResponseCancel = (response) => {
    //     if (response) {
    //         history.push("/user")
    //     }
    //     setAlertCancel("")
    // }


    const cancelHandler = () => {
        history.goBack()
        // if (!userId) {
        //     if (Name !== '' && Surname !== '' && Username !== '' && EmailAddress !== '' && Password !== '' && ContactNumber !== '') {
        //         SavePromptCancelHAndler()
        //     } else {
        //         history.goBack()
        //     }
        // } else {
        //     history.goBack()
        // }
        // history.push('/user')
    }

    const editHandler = () => {
        if (userId) {
            setError("Username field is not editable")
        }
    }

    const userPermissionHandler = () => {
        setUserPermission(true);
    }

    const hidePermisssionHandler = () => {
        setUserPermission(false);
    }

    const handleContact = (e) => {
        let getContact = e.target.value;
        getContact = getContact.replace(/[^0-9]/gi, "");
        setUserDetails({ ...userDetails, ContactNumber: getContact });
        setFromDataChanged(true)
    }

    return (
        <div>
            <Prompt
                when={FromDataChanged}
                message="You are navigating away from this screen. You will lose the typed information. Are you sure you want to leave this screen?"
            />
            {loader ? <Loader /> : null}
            {alert ? (
                <AlertBox
                    message={alert}
                    // type={alertType}
                    // alertId={alertId}
                    alertResponse={alertResponse}
                />
            ) : null}
            {/* {alertCancel ? (
                <AlertBox
                    message={alertCancel}
                    // type={alertType}
                    // alertId={alertId}
                    alertResponse={alertResponseCancel}
                />
            ) : null} */}
            {userPermission ?
                (<UserPermission
                    hidePermisssionHandler={hidePermisssionHandler}
                    userId={userId} />)
                :
                <div>
                    <form onSubmit={submitUserDetails}>
                        <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                            <div className="row flex-a-center">
                                <div className="col-sm-6">
                                    <h5 className="mb-0 custom-header-title"><strong>{(userId > 0) ? 'Edit' : 'Add New'} User</strong></h5>
                                </div>
                                <div className="col-sm-6">
                                    <div className="font-22 ms-auto confirm-btn">
                                        <button type="submit" className={"btn btn-success px-5" + (permissions.includes('user_add') ? '' : ' blur-buttons')}>
                                            Save
                                        </button>
                                        &nbsp;
                                        {(userId) ?
                                            <button type="button" className={"btn btn-primary px-4" + (permissions.includes('user_get_user_groups') ? '' : ' blur-buttons')}
                                                onClick={userPermissionHandler}>
                                                Permission
                                            </button>
                                            : null}
                                        &nbsp;
                                        <button type="button" className="btn btn-danger px-4" onClick={cancelHandler}>
                                            Cancel/Back
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />

                        <div className="card card-custom">
                            <div className="card-body">

                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="mt-3 mb-3">
                                            <label
                                                htmlFor="Name"
                                                className="form-label form-lable-custom mt-3 mb-3"
                                            >Name<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                            </label>
                                            <input
                                                autoFocus
                                                type="text"
                                                className="form-control mb-3"
                                                id="Name"
                                                name="Name"
                                                placeholder="Enter Your Name"
                                                value={Name || ''}
                                                onChange={handleChangeName}
                                                maxLength={NormalField}

                                            />
                                            <h6 className="mb-0" style={{ color: "red" }}>
                                                {nameError}
                                            </h6>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mt-3 mb-3">
                                            <label
                                                htmlFor="Surname"
                                                className="form-label form-lable-custom mt-3 mb-3"
                                            >
                                                Surname<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control mb-3"
                                                id="Surname"
                                                name="Surname"
                                                placeholder="Enter Your Surname"
                                                value={Surname || ''}
                                                onChange={handleChangeSurname}
                                                maxLength={NormalField}

                                            />
                                            <h6 className="mb-0" style={{ color: "red" }}>
                                                {surnameError}
                                            </h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">

                                <div className="col-sm-6">
                                        <div className="mt-3 mb-3">
                                            <label
                                                htmlFor="EmailAddress"
                                                className="form-label form-lable-custom mt-3 mb-3"
                                            >
                                                Email Address<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                            </label>
                                            <input
                                                // rows="5"
                                                type="text"
                                                className="form-control mb-3"
                                                id="EmailAddress"
                                                name="EmailAddress"
                                                placeholder="Enter Your Email Address"
                                                value={EmailAddress || ''}
                                                onChange={handleChange}
                                                maxLength={NormalField}

                                            />
                                            <h6 className="mb-0" style={{ color: "red" }}>
                                                {emailError}
                                            </h6>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="mt-3 mb-3">
                                            <label
                                                htmlFor="Username"
                                                className="form-label form-lable-custom mt-3 mb-3"
                                            >
                                                Username<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control mb-3"
                                                id="Username"
                                                name="Username"
                                                placeholder="Enter Your Username"
                                                value={Username || ''}
                                                readOnly={(userId) ? true : false}
                                                onClick={editHandler}
                                                onChange={handleUsername}
                                                maxLength={NormalField}

                                            />
                                            <h6 className="mb-0" style={{ color: "red" }}>
                                                {usernameError}{" "}
                                            </h6>
                                        </div>
                                    </div>

                            
                                </div>

                                <div className="row">
                                <div className="col-sm-6">
                                        <div className="mt-3 mb-3">
                                            <label
                                                htmlFor="Password"
                                                className="form-label form-lable-custom mt-3 mb-3"
                                            >
                                                Password<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                            </label>
                                            <div className="row">
                                                <div className="col-md-12 flex-center-end1">
                                                    <i className={(ShowPassword) ? "fa fa-eye password-icon" : "fa fa-eye-slash password-icon"} style={{ color: (ShowPassword) ? 'blue' : 'gray' }} onClick={() => setShowPassword(!ShowPassword)}></i>
                                                    <input
                                                        type={ShowPassword ? "text" : "password"}
                                                        className="form-control mb-3"
                                                        id="Password"
                                                        name="Password"
                                                        placeholder="Enter Your Password"
                                                        value={Password || ''}
                                                        onChange={handleChange}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                            <h6 dangerouslySetInnerHTML={{ __html: passwordError }} className="mb-0" style={{ color: "red" }}></h6>
                                        </div>
                                    </div>
                                    
                                    <div className="col-sm-6">
                                        <div className="mt-3 mb-3">
                                            <label
                                                className="form-label form-lable-custom mt-3 mb-3"
                                            >
                                                Confirm Password<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                            </label>
                                            <div className="row">
                                                <div className="col-md-12 flex-center-end1">
                                                    <i
                                                        className={(ShowConfirmPassword) ? "fa fa-eye password-icon" : "fa fa-eye-slash password-icon"}
                                                        style={{ color: (ShowConfirmPassword) ? 'blue' : 'gray' }}
                                                        onClick={(e) => setShowConfirmPassword(!ShowConfirmPassword)}
                                                    ></i>
                                                    <input
                                                        type={ShowConfirmPassword ? "text" : "password"}
                                                        className="form-control mb-3"
                                                        id="ConfirmPassword"
                                                        name="ConfirmPassword"
                                                        placeholder="Enter Confirm Password"
                                                        value={confirmPassword || ''}
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                            <h6 dangerouslySetInnerHTML={{ __html: cPasswordError }} className="mb-0" style={{ color: "red" }}></h6>
                                        </div>
                                    </div>


                                  


                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="mt-3 mb-3">
                                            <label
                                                htmlFor="ContactNumber"
                                                className="form-label form-lable-custom mt-3 mb-3"
                                            >
                                                Contact Number<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control mb-3"
                                                id="ContactNumber"
                                                name="ContactNumber"
                                                placeholder="Enter your Contact Number eg.27820000000"
                                                value={ContactNumber || ''}
                                                onChange={handleContact}
                                            />
                                            <h6 className="mb-0" style={{ color: "red" }}>
                                                {contactError}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            }
            <ToastContainer theme="colored" />
        </div>
    );
};

export default AddUser;
