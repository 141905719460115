import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import axios from "../../axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AlertBox from "../../components/AlertBox";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";

const ViewSearchContactList = (props) => {
  const permissions = useSelector((state) => state.getPermissions.permissions);
  var Fileid = props.ContactFileId;
  var FileName = props.ContactFileName;
  const history = useHistory();
  const [CsvFileDetails, setCsvFileDetails] = useState([])
  const [CsvFileDetailsMain, setCsvFileDetailsMain] = useState([])
  const [serviceCall, setServiceCall] = useState(true);
  const [search, setSearch] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("")
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [alertId, setAlertId] = useState("");
  const [FileIndexNo, setFileIndexNo] = useState("");
  const [loader, setLoader] = useState(false);
  const [showRemoveButton, setShowRemoveButton] = useState(false);

  //Get Details Inside Files
  useEffect(() => {
    if (serviceCall) {
      setLoader(true)
      axios
        .get("/contact/get_contact/" + Fileid)
        .then((response) => {
          setCsvFileDetails(response.data.data);
          setCsvFileDetailsMain(response.data.data);
          setServiceCall(false);
          setLoader(false)
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              history.push("/logout");
            }
            if (error.response.status !== 200) {
              setError(error.response.data.message);
              setLoader(false)
            }
          }
        })
    }
  }, [Fileid, history, serviceCall])

  useEffect(() => {
    if (success) {
      toast.success(success);
      setSuccess('')
    }
  }, [success])

  useEffect(() => {
    if (error !== '') {
      toast.error(error);
      setError('')
    }
  }, [error])

  const handleChange = (FileID) => {
    setFileIndexNo(FileID)
    setShowRemoveButton(true)
  }

  const searchHandler = () => {
    var match = false
    for (var i = 0; i < CsvFileDetailsMain.length; i++) {
      if (CsvFileDetailsMain[i].MSISDN === search) {
        setFileIndexNo(CsvFileDetailsMain[i].ID);
        setShowRemoveButton(true);

        var CsvFile = JSON.parse(JSON.stringify(CsvFileDetailsMain))
        CsvFile.reverse().push(CsvFileDetailsMain[i])
        CsvFile.reverse().splice(i + 1, 1)
        setCsvFileDetails(CsvFile);
        match = true
        break;
      }
    }
    if (!match) {
      setError("Number doesn't exists")
    }
  }

  const clearSearchHandler = () => {
    setCsvFileDetails(CsvFileDetailsMain);
    setFileIndexNo("");
    setSearch("");
    setShowRemoveButton(false)
  }

  const removeHandler = (fileNo) => {
    setAlert("Please note that removing numbers from a file linked to a contact list shall also remove the numbers from the contact list");
    setAlertType("delete_contact");
    setAlertId(fileNo);
    // setFileNumberCount(CsvFileDetails.length - 1);
    // console.log(fileNo);
  }

  const alertResponse = (response, type, alertId) => {
    if (response) {
      setLoader(true)
      axios
        .post("contact/" + type, { ContactID: alertId })
        .then((response) => {
          setSuccess(response.data.data);
          setFileIndexNo("");
          setServiceCall(true);
          setLoader(false)
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.status === 401 ||
              error.response.status === 403
            ) {
              history.push("/logout");
            } else if (error.response.status !== 200) {
              setError(error.response.data.message);
              setLoader(false)
            }
          }
        });
    }
    setAlert("");
    setAlertType("");
    setAlertId();
  }

  const handleKeyEnter = (e) => {
    return (e.key === 'Enter') ? searchHandler() : null
  }

  const allowNumeric = (e) => {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  return (
    <div>
      {loader ? <Loader /> : null}
      {alert ? (
        <AlertBox
          message={alert}
          type={alertType}
          alertId={alertId}
          alertResponse={alertResponse}
        />
      ) : null}

      <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
        <div className="row flex-a-center">
          <div className="col-sm-6">
            <h5 className="mb-0 custom-header-title"><strong>View Contact List Files</strong></h5>
          </div>
          <div className="col-sm-6">
            <div className="font-22 ms-auto confirm-btn">
              <button
                type="button" className="btn btn-danger px-4" onClick={props.hideSearchFileWindow}>
                Cancel/Back</button>
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div className="row">
        <div className="col-sm-6"> <b>{FileName} </b></div>
        <div className="col-sm-6"> <b>Numbers:{CsvFileDetails.length} </b></div>
      </div>
      <br />

      <div className="row mb-6">
        <div className="col-sm-3">
          <input
            type="text"
            className="form-control"
            id="search"
            name="search"
            placeholder="Search Here"
            value={search}
            onKeyPress={(e) => allowNumeric(e)}
            onKeyDown={(e) => handleKeyEnter(e)}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div className="col-sm-3">
          <button
            style={{ float: 'left' }}
            type="submit"
            className="btn btn-primary mb-1"
            title="Search"
            onClick={searchHandler}
          >
            <i className="fa fa-search"></i>
          </button>


          {(search !== '') ?
            <button
              style={{ float: 'left', marginLeft: '5px' }}
              type="submit"
              className="btn btn-primary mb-1"
              title="Clear Search"
              onClick={clearSearchHandler}
            >
              <i className="fa fa-eraser"></i>
            </button>
            : null}
          &nbsp;
        </div>
      </div>
      &nbsp;&nbsp;

      <div className="card radius-10">
        <div className="card-body" style={{ overflowX: 'auto' }}>
          <div className="row">
            <div className="col-sm-6">
              {(CsvFileDetails.length > 0) ?
                <div
                  style={{
                    overflowY: 'auto',
                    width: 'max-content',
                    height: '300px',
                    border: '1px solid gray',
                    padding: '20px'
                  }}
                >
                  <table>
                    <tbody>
                      {(permissions.includes('contact_get_contact')) ?
                        CsvFileDetails.map((fileDetails, index) => (
                          <tr key={index}
                            style={(FileIndexNo === fileDetails.ID) ? { background: '#004C97', color: '#ffffff', cursor: 'default' } : { cursor: 'default' }}>
                            <td style={{ padding: '5px' }} onClick={() => handleChange(fileDetails.ID)}>{fileDetails.MSISDN}</td>
                          </tr>
                        )) : null}
                    </tbody>
                  </table>
                </div>
                : "No records Found"}

              {/* <select
                // multiple
                value={FileIndexNo}
								className="form-select mb-6"
								name="FileNumber"
								id="FileNumber"
                 size="8"
                 
								aria-label="Default select example"
								onChange={handleChange}
							>
								{CsvFileDetails.map((fileDetails) => {
                  return <option 
                  onClick={() =>  {
                    navigator.clipboard.writeText(fileDetails.MSISDN)
                   }} 
                   key={fileDetails.ID} value={fileDetails.ID}> {fileDetails.MSISDN} </option>
                    })}
							</select> */}
            </div>

            {showRemoveButton ?
              <div className="col-sm-6">
                <button
                  type="button" className={"btn btn-primary px-4" + (permissions.includes('contact_delete_contact') ? '' : ' blur-buttons')} onClick={() => removeHandler(FileIndexNo)}>
                  Remove</button>
              </div>
              : null}
          </div>
        </div>
      </div>
      <ToastContainer theme="colored" />
    </div>
  )
}
export default ViewSearchContactList;