import React, { useState, useEffect, useRef } from 'react';
import axios from '../../axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../components/Loader";
import { useHistory } from 'react-router';
import { useSelector } from "react-redux";
import { Prompt } from 'react-router-dom';

const SubmitATicket = () => {
    const permissions = useSelector((state) => state.getPermissions.permissions);
    const ref = useRef();
    const history = useHistory();
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loader, setLoader] = useState(false);
    const [productList, setProductList] = useState([]);
    const [LogSupportQuery, setLogSupportQuery] = useState({
        ProductID: '',
        Problem: '',
        Result: '',
        Subject: '',
        UploadFile: null
    })
    const [problemError, setProblemError] = useState("");
    const [resultError, setResultError] = useState("");
    const [productError, setProductError] = useState("");
    const [subjectError, setsubjectError] = useState("");
    const { ProductID, Problem, Result, Subject } = LogSupportQuery;
    const [FromDataChanged, setFromDataChanged] = useState(false)

    const NormalField = (localStorage.getItem("ClientNormalField") ? localStorage.getItem("ClientNormalField") : "");

    const handleChange = (e) => {
        setLogSupportQuery({ ...LogSupportQuery, [e.target.name]: e.target.value });
        setFromDataChanged(true)
    };

    const handleFile = (e) => {
        const file = e.target.files[0]
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => setLogSupportQuery({ ...LogSupportQuery, [e.target.name]: reader.result, UploadFileName: file.name });
        }
        setFromDataChanged(true)
    };


    useEffect(() => {
        if (permissions.includes('support_get_all_products')) {
            setLoader(true)
            axios.get("support/get_all_products")
                .then((response) => {
                    setProductList(response.data.data.products)
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [history, permissions])

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    const validate = () => {
        setProductError("");
        setProblemError("");
        setResultError("");
        setsubjectError("");
        var flag = true;

        if (ProductID === "") {
            flag = false;
            setProductError("Product field is required")
        }

        if (Problem.length < 10) {
            flag = false;
            setProblemError("Problem field required atleast 10 character")
        }

        if (Result.length < 10) {
            flag = false;
            setResultError("Result field required atleast 10 character")
        }

        if (Subject === "") {
            flag = false;
            setsubjectError("Subject field is required")
        }

        return flag;
    }
    // console.log(LogSupportQuery)

    const saveLogSupportQuery = (e) => {
        e.preventDefault();
        const flag = validate();
        if (flag) {
            setLoader(true);
            axios.post("support/log_support_query", LogSupportQuery)
                .then((response) => {
                    setSuccess(response.data.data);
                    setLogSupportQuery({
                        ProductID: '',
                        Problem: '',
                        Result: '',
                        Subject: '',
                        UploadFile: null
                    })
                    ref.current.value = null
                    setLoader(false)
                    setFromDataChanged(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }

    return (
        <div>
            <Prompt
                when={FromDataChanged}
                message="You are navigating away from this screen. You will lose the typed information. Are you sure you want to leave this screen?"
            />
            {loader ? <Loader /> : null}
            <form onSubmit={saveLogSupportQuery}>
                <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                    <div className="row flex-a-center">
                        <div className="col-sm-6">
                            <h5 className="mb-0 custom-header-title"><strong>Support / Submit Ticket</strong></h5>
                        </div>
                        <div className="col-sm-6">
                            <div className="font-22 ms-auto confirm-btn">
                                <button type="submit" className={"btn btn-primary px-4" + (permissions.includes('support_log_support_query') ? '' : ' blur-buttons')}
                                // onClick={() => userPermissionHandler(userId)}
                                >
                                    Log Support Query
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                <div className="card card-custom">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <p>To receive Support, please log your query by completing the form below.</p>
                                <p>You will receive a reciept for your query, and our support team will respond timeously on receipt and investigation of the request.</p>
                                <p>Please remember to include as much detail as you can (Include Screenshot), so that the support team can provide a quick and effective resolution for you.</p>
                                {/* <p>Please remember to include as much detail as you can, so that the support team can provide a quick and effective resolution for you.</p> */}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-8">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="ProductID"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >Select Product <i style={{ color: 'red', fontSize: "20px" }}>*</i></label>
                                    <select
                                        autoFocus
                                        value={ProductID}
                                        className="form-select mb-3"
                                        name="ProductID"
                                        id="ProductID"
                                        aria-label="Default select example"
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Product</option>
                                        {
                                            productList.length > 0 ? productList.map((product) => {
                                                return <option key={product.ID} value={product.ID}>
                                                    {product.Name}
                                                </option>
                                            })
                                                : <option> No Records</option>
                                        }
                                    </select>
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {productError}
                                    </h6>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-sm-8">
                                <div className="mt-3 mb-3">
                                    <label
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >Subject <i style={{ color: 'red', fontSize: "20px" }}>*</i></label>
                                    <input
                                        type='text'
                                        className="form-control mb-3"
                                        id="Subject"
                                        name="Subject"
                                        value={Subject}
                                        onChange={handleChange}
                                        maxLength={NormalField} />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {subjectError}
                                    </h6>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-8">
                                <div className="mt-3 mb-3">
                                    <label
                                        style={{ color: "red" }}
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >Please describe the problem and how this occurred. Please remember to include as much detail as possible and if possible include a screenshot.<i style={{ color: 'red', fontSize: "20px" }}>*</i> </label>
                                    <textarea
                                        className="form-control mb-3"
                                        rows="5"
                                        id="Problem"
                                        name="Problem"
                                        value={Problem}
                                        onChange={handleChange}>
                                    </textarea>
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {problemError}
                                    </h6>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-8">
                                <div className="mt-3 mb-3">
                                    <label
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >Upload Screenshot </label>
                                    <input
                                        ref={ref}
                                        type="file"
                                        className="form-control mb-3"
                                        id="UploadFile"
                                        name="UploadFile"
                                        placeholder="Enter File"
                                        onChange={handleFile}
                                    />
                                </div>
                            </div>
                        </div>



                        <div className="row">
                            <div className="col-sm-8">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="prodResultuct"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >Please describe the results of this problem. What happened? <i style={{ color: 'red', fontSize: "20px" }}>*</i></label>
                                    <textarea
                                        className="form-control mb-3"
                                        rows="5"
                                        id="Result"
                                        name="Result"
                                        value={Result}
                                        onChange={handleChange}>
                                    </textarea>
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {resultError}
                                    </h6>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12'>
                                <br />
                                <hr />
                                <h5 className="mb-2"><strong>Get in touch.</strong></h5>
                                <h6>Call <a href="tel:+27 10 449 1869"> +27 10 449 1869</a> to speak to a consultant</h6>
                            </div>
                        </div>

                        {/* <div className="row">
                            <div className="col-sm-8">
                                <div className="mt-3 mb-3">
                                    <button type="submit" className={"btn btn-primary px-4" + (permissions.includes('support_log_support_query') ? '' : ' blur-buttons')}
                                    // onClick={() => userPermissionHandler(userId)}
                                    >
                                        Log Support Query
                                    </button>
                                </div>
                            </div>
                        </div> */}


                    </div>
                </div>
                <ToastContainer theme="colored" />
            </form>
        </div>
    )
}
export default SubmitATicket;