import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useHistory, useParams } from 'react-router';
import axios from '../../axios'
import AddPermission from './AddPermission';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../components/Loader";
import { Prompt } from 'react-router-dom';

export const AddGroup = () => {
    const permissions = useSelector((state) => state.getPermissions.permissions);
    const { groupid } = useParams();
    const history = useHistory();

    const [groupDetails, setGroupDetails] = useState({ GroupID: groupid || 0, Name: "", Description: "" });
    const [nameError, setNameError] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [showPermission, setShowPermission] = useState(false);
    const [permitId, setPermidId] = useState("");
    const [permissionList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [FromDataChanged, setFromDataChanged] = useState(false)

    const { Name, Description } = groupDetails;

    const handleChange = (e) => {
        setGroupDetails({ ...groupDetails, [e.target.name]: e.target.value });
        setFromDataChanged(true)
    };

    useEffect(() => {
        if (groupid && permissions.includes('group_get')) {
            setLoader(true)
            axios
                .get(`group/get/${groupid}`)
                .then((response) => {
                    setGroupDetails({
                        GroupID: response.data.data.ID,
                        Name: response.data.data.Name,
                        Description: response.data.data.Description,
                    })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [groupid, history, permissions])

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    function validate() {
        var flag = true;
        setNameError("");
        setDescriptionError("");

        if (Name === "") {
            flag = false
            setNameError("Name field is required");
        }

        if (Description === "") {
            flag = false
            setDescriptionError("Description field is required");
        }
        return flag
    }

    const submitGroupDetails = (e) => {
        e.preventDefault();
        setSuccess("")
        setError("");
        const flag = validate()
        var url = (groupid) ? 'edit' : 'add';
        if (flag) {
            setLoader(true)
            axios
                .post("group/" + url, groupDetails)
                .then((response) => {
                    setSuccess(response.data.data.message);
                    setFromDataChanged(false)
                    history.push('/add-group/' + response.data.data.GroupID)
                    setSuccess(response.data.data.Message)
                    // history.push({
                    //     pathname: '/add-group/' + response.data.data.ID,
                    //     state: { message: response.data.data.Message }
                    // })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                });
        }
    };

    const cancelHandler = () => {
        history.push('/group')
    }

    const showPermisssionHandler = (groupid) => {
        setPermidId(groupid)
        setShowPermission(true);
    }

    const hidePermisssionHandler = () => {
        setShowPermission(false);
        setPermidId('');
    }

    const permissionResponse = (response, permitId, permissionList) => {
        if (response && permissions.includes('group_save_group_permission')) {
            setLoader(true)
            axios.post('group/save_group_permission', { GroupID: permitId, Permission: permissionList })
                .then((response) => {
                    setFromDataChanged(false)
                    setShowPermission(false);
                    history.push({
                        pathname: '/group',
                        state: { message: response.data.data }
                    })
                    setSuccess(response.data.data)
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response.status === 401 || error.response.status === 403) {
                        history.push("/logout");
                    } else if (error.response.status !== 200) {
                        setShowPermission(false);
                        setError(error.response.data.message);
                        setLoader(false)
                    }
                })
        }
    }

    return (
        <div>
            <Prompt
                when={FromDataChanged}
                message="You are navigating away from this screen. You will lose the typed information. Are you sure you want to leave this screen?"
            />
            <form onSubmit={submitGroupDetails}>
                {loader ? <Loader /> : null}
                {
                    showPermission ?
                        (<AddPermission permitId={permitId} permissionList={permissionList} Name={Name} permissionResponse={permissionResponse} hidePermisssionHandler={hidePermisssionHandler} />)
                        :
                        <div>
                            <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                                <div className="row flex-a-center">
                                    <div className="col-sm-6">
                                        <h5 className="mb-0 custom-header-title"><strong> {(groupid > 0) ? 'Edit Group : ' + Name : 'Add Group'}</strong></h5>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="font-22 ms-auto confirm-btn">
                                            <button type="submit" className={"btn btn-success px-5" + (permissions.includes('group_add') ? '' : ' blur-buttons')}>
                                                Save
                                            </button>
                                            &nbsp;
                                            {(groupid) ? <button type="button"
                                                className={"btn btn-primary px-4" + (permissions.includes('group_list_permission') ? '' : ' blur-buttons')}
                                                onClick={() => showPermisssionHandler(groupid)}
                                            >
                                                Permission
                                            </button> : null}
                                            &nbsp;
                                            <button type="button" className="btn btn-danger px-4" onClick={cancelHandler}>
                                                Cancel/Back
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />

                            <div className="card card-custom">
                                <div className="card-body">


                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="mt-3 mb-3">
                                                <label
                                                    htmlFor="inputEnterYourName"
                                                    className="form-label form-lable-custom mt-3 mb-3"
                                                >
                                                    Group Name<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                                </label>
                                                <textarea
                                                    autoFocus
                                                    // type="text"
                                                    rows="5"
                                                    className="form-control mb-3"
                                                    id="Name"
                                                    name="Name"
                                                    placeholder="Enter Name"
                                                    value={Name || ''}
                                                    onChange={handleChange}
                                                >
                                                </textarea>
                                                <h6 className="mb-0" style={{ color: "red" }}>
                                                    {nameError}
                                                </h6>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="mt-3 mb-3">
                                                <label
                                                    htmlFor="inputEnterYourSurname"
                                                    className="form-label form-lable-custom mt-3 mb-3"
                                                >
                                                    Group Description<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                                </label>
                                                <textarea
                                                    rows="5"
                                                    // type="text"
                                                    className="form-control mb-3"
                                                    id="Description"
                                                    name="Description"
                                                    placeholder="Enter Description"
                                                    value={Description || ''}
                                                    onChange={handleChange}
                                                >
                                                </textarea>
                                                <h6 className="mb-0" style={{ color: "red" }}>
                                                    {descriptionError}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                }
                <ToastContainer theme="colored" />
            </form>
        </div>
    );
};
