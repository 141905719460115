import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "../../axios";
import { useHistory, useParams } from 'react-router-dom';
import Loader from "../../components/Loader";
import AlertBox from "../../components/AlertBox";
import { useSelector } from "react-redux";
import { Prompt } from 'react-router-dom';

const AddTemplate = () => {
    const permissions = useSelector((state) => state.getPermissions.permissions);
    const { templateId } = useParams();
    const history = useHistory();
    const [templateDetails, setTemplateDetails] = useState({
        TemplateID: templateId || 0,
        Name: "",
        Content: ""
    })
    const { Name, Content } = templateDetails;
    const [nameError, setNameError] = useState("");
    const [contentError, setContentError] = useState("");
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [CharacterCount, setCharacterCount] = useState(0)
    const [CharacterParts, setCharacterParts] = useState(0)
    const [loader, setLoader] = useState(false);
    const [alert, setAlert] = useState("");
    // const [alertCancel, setAlertCancel] = useState("");
    const [FromDataChanged, setFromDataChanged] = useState(false)

    const NormalField = (localStorage.getItem("ClientNormalField") ? localStorage.getItem("ClientNormalField") : "");

    const handleChange = (e) => {
        setTemplateDetails({ ...templateDetails, Name: e.target.value });
        setFromDataChanged(true)
    };

    const handleContent = (e) => {
        var input = e.target.value;
        charcaterPartHandler(input)
        if (input.length >= 459) {
            setError("Maximum character allowed is 459 only!!!")
        }
        setFromDataChanged(true)
    }

    const charcaterPartHandler = (input) => {
        const max_limit = 800
        const part_limit = (input.length > 160) ? 153 : 160;

        if (input.length <= max_limit) {
            setCharacterCount(input.length)
            setCharacterParts((input.length % part_limit === 0) ? (input.length / part_limit) : parseInt(input.length / part_limit) + 1)
            setTemplateDetails({ ...templateDetails, Content: input });
        }
        setFromDataChanged(true)
    }

    useEffect(() => {
        if (templateId && permissions.includes('template_get')) {
            setLoader(true)
            axios
                .get("template/get/" + templateId)
                .then((response) => {
                    setTemplateDetails({
                        TemplateID: response.data.data.ID,
                        Name: response.data.data.Name,
                        Content: response.data.data.Content
                    })
                    const input = response.data.data.Content
                    const max_limit = 800
                    const part_limit = (input.length > 160) ? 153 : 160;
                    if (input.length <= max_limit) {
                        setCharacterCount(input.length)
                        setCharacterParts((input.length % part_limit === 0) ? (input.length / part_limit) : parseInt(input.length / part_limit) + 1)
                    }
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [templateId, history, permissions])

    function validate() {
        var flag = true;
        setNameError("");
        setContentError("");

        if (Name === "") {
            flag = false;
            setNameError("Name field is required");
        }

        if (Content === "") {
            flag = false;
            setContentError("Content Field is required")
        }
        return flag
    }

    const submitTemplateDetails = (e) => {
        e.preventDefault();
        setSuccess("")
        setError("");
        const flag = validate()
        if (flag) {
            SavePromptHandler()
        }

    }

    const SavePromptHandler = () => {
        setAlert("Please ensure that you have verified your character count. Characters beyond 160 count as two or more messages per recipient. Are you sure you want to save the details?")
    }

    const alertResponse = (response) => {
        if (response) {
            var url = (templateId) ? 'edit' : 'add';
            setLoader(true)
            axios
                .post("template/" + url, templateDetails)
                .then((response) => {
                    setFromDataChanged(false)
                    history.push({
                        pathname: '/template',
                        state: { message: response.data.data.Message }
                    })
                    setLoader(false)
                    //  setSuccess(response.data.data.Message);
                    //  history.push('/template')
                    //  history.push('/add-contact-list/'+response.data.data.ContactListID)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.Message);
                            setLoader(false)
                        }
                    }
                });
        } else {
            history.push("/template")
            setAlert("")
        }

    }

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])


    // const SavePromptCancelHandler = () => {
    //     setAlertCancel("Do you want to leave the page")
    // }


    // const alertResponseCancel = (response) => {
    //     if (response) {
    //         history.push("/template")
    //     }
    //     setAlertCancel("")
    // }


    const cancelHandler = () => {
        history.goBack()
        // history.push("/template")
        // if (!templateId) {
        //     if (Name !== '' && Content !== '') {
        //         SavePromptCancelHandler()
        //     } else {
        //         history.goBack()
        //     }
        // } else {
        //     SavePromptCancelHandler()
        // }
    }

    return (
        <div>
             <Prompt
                when={FromDataChanged}
                message="You are navigating away from this screen. You will lose the typed information. Are you sure you want to leave this screen?"
            />
            {alert ? (
                <AlertBox
                    message={alert}
                    alertResponse={alertResponse}
                />
            ) : null}
            {/* {alertCancel ? (
                <AlertBox
                    message={alertCancel}
                    alertResponse={alertResponseCancel}
                />
            ) : null} */}
            {loader ? <Loader /> : null}
            <form onSubmit={submitTemplateDetails}>
                <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                    <div className="row flex-a-center">
                        <div className="col-sm-6">
                            <h5 className="mb-0 custom-header-title"><strong>{(templateId > 0) ? 'Edit' : 'Add New'} Template</strong></h5>
                        </div>
                        <div className="col-sm-6">
                            <div className="font-22 ms-auto confirm-btn">
                                <button type="submit" className={"btn btn-success px-5" + (permissions.includes('template_add') ? '' : ' blur-buttons')}>Save</button>
                                &nbsp;
                                <button type="button" className="btn btn-danger px-4" onClick={cancelHandler}>Cancel/Back</button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                <div className="card card-custom">
                    <div className="card-body">

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="Name"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >
                                        Template Name<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <input
                                        autoFocus
                                        type="text"
                                        className="form-control mb-3"
                                        id="Name"
                                        name="Name"
                                        placeholder="Enter Your Template Name"
                                        value={Name || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField} 
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {nameError}
                                    </h6>
                                </div>
                            </div>


                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="Content"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >
                                        Contents<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <textarea
                                        type="text"
                                        className="form-control mb-3"
                                        id="Content"
                                        name="Content"
                                        rows="5"
                                        cols="5"
                                        maxLength="459"
                                        placeholder="Enter Your Content"
                                        value={Content || ''}
                                        onChange={handleContent}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {contentError}
                                    </h6>
                                </div>

                                <div className="mt-3 mb-3">
                                    <label className="form-label form-lable-custom mt-3 mb-3"> Characters:{CharacterCount} </label>&nbsp;&nbsp;
                                    <label className="form-label form-lable-custom mt-3 mb-3"> /&nbsp;Messages: {CharacterParts} </label>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer theme="colored" />
            </form>
        </div>
    )
}
export default AddTemplate;