import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from "../../axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import { Prompt } from 'react-router-dom';

const AddClientProduct = () => {
    const permissions = useSelector((state) => state.getPermissions.permissions);
    const { productid } = useParams();
    const { accountId } = useParams();
    const history = useHistory();

    const [productArray, setProductArray] = useState([])
    const [apiArray, setApiArray] = useState([])
    const [showMarketingFlag, setShowMarketingFlag] = useState(false)
    const [ClientProdDetails, setClientProdDetails] = useState({
        ProductID: productid || '',
        ClientAccountID: accountId || '',
        ProductTypeID: "",
        Marketing: "",
        Name: "",
        Description: "",
        Status: "Disabled",
        ApiTypeID: ""
    })
    const { ProductID, ProductTypeID, Marketing, Name, Description, Status, ApiTypeID } = ClientProdDetails;
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [ProductTypeError, setProductTypeError] = useState("");
    const [MarketingError, setMarketingError] = useState("");
    const [NameError, setNameError] = useState("");
    const [DescriptionError, setDescriptionError] = useState("");
    const [StatusError, setStatusError] = useState("");
    const [ApiTypeError, setApiTypeError] = useState("");
    const [loader, setLoader] = useState(false);
    const [FromDataChanged, setFromDataChanged] = useState(false)

    const NormalField = (localStorage.getItem("ClientNormalField") ? localStorage.getItem("ClientNormalField") : "");

    const handleChange = (e) => {
        setClientProdDetails({ ...ClientProdDetails, [e.target.name]: e.target.value });
        setFromDataChanged(true)
    };


    useEffect(() => {
        if (history.location.state !== undefined) {
            setSuccess(history.location.state.message)
        }
        if (ProductID && permissions.includes('product_get')) {
            setLoader(true)
            axios
                .get(`product/get/${ProductID}`)
                .then((response) => {
                    setClientProdDetails({
                        ProductID: response.data.data.ID,
                        ProductTypeID: response.data.data.ProductTypeID,
                        Name: response.data.data.Name,
                        Description: response.data.data.Description,
                        Marketing: response.data.data.Marketing,
                        Status: response.data.data.Status,
                        ApiTypeID: response.data.data.ApiTypeID
                    })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [ProductID, history, permissions])

    useEffect(() => {
        if (permissions.includes('product_get_masters')) {
            setLoader(true)
            axios.get("/product/get_masters")
                .then((response) => {
                    setProductArray(response.data.data.aProductType)
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [history, permissions])

    useEffect(() => {
        if (ProductTypeID === 'SMS MT' || ProductTypeID === 'SMS MO') {
            setShowMarketingFlag(true)
        }
    }, [ProductTypeID])

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
            history.location.state = undefined
        }
    }, [success, history])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    function validate() {
        var flag = true;
        setError("");
        setSuccess("");
        setProductTypeError("");
        setNameError("");
        setDescriptionError("");
        setStatusError("");
        setMarketingError("");
        setApiTypeError("");

        if (ProductTypeID === "") {
            flag = false;
            setProductTypeError("Product Type field is required");
        }

        if (Name === '') {
            flag = false;
            setNameError("Name field is required");
        }

        if (Description === '') {
            flag = false;
            setDescriptionError("Description field is required")
        }

        if (showMarketingFlag) {
            if (Marketing === '') {
                flag = false;
                setMarketingError("Marketing field is required")
            }
        }

        if (Status === '') {
            flag = false;
            setStatusError("Status field is required")
        }

        if (ApiTypeID === '') {
            flag = false;
            setApiTypeError("API Type field is required")
        }
        return flag;
    }

    const productHandler = (e) => {
        var indexValue = e.nativeEvent.target.selectedIndex;
        var data = (e.target.value === '') ? '' : e.nativeEvent.target[indexValue].text;
        setClientProdDetails({ ...ClientProdDetails, [e.target.name]: e.target.value, Name: data });
        if (e.target.value === '1' || e.target.value === '2') {
            setShowMarketingFlag(true);
        } else {
            setShowMarketingFlag(false);
        }
        setFromDataChanged(true)
    }

    useEffect(() => {
        var product = []
        for (var i = 0; i < productArray.length; i++) {
            if (productArray[i].ID === ProductTypeID) {
                product = productArray[i].ApiType
                break;
            }
        }
        setApiArray(product)
    }, [productArray, ProductTypeID])

    const submitProductDetails = (e) => {
        e.preventDefault();
        submitProduct()
    }

    const submitProduct = (callFrom = '') => {
        const flag = validate();
        var url = (ProductID) ? 'edit' : 'add';
        if (flag) {
            setLoader(true)
            axios
                .post("product/" + url, ClientProdDetails)
                .then((response) => {
                    setFromDataChanged(false)
                    setLoader(false)
                    if (callFrom === '') {
                        if (ClientProdDetails.ProductTypeID === "5") {
                            history.push('/view-apictb/' + accountId + "/" + response.data.data.ProductID)
                        } else {
                            history.push({
                                pathname: '/client-product/' + accountId,
                                state: { message: response.data.data.message }
                            })
                        }
                    } else {
                        handleConfigure(response.data.data.ProductID)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                });
        }
    }

    const handleConfigure = (productid) => {
        var apiTypeName = '';
        for (let index = 0; index < apiArray.length; index++) {
            if (apiArray[index].ID === ApiTypeID) {
                apiTypeName = apiArray[index].Name
                break;
            }
        }

        // if (parseInt(ClientProdDetails.ProductTypeID) === 5) {
        //     history.push("/add-apictb/" + accountId + "/" + productid)
        // } else {
        if (apiTypeName === 'SMPP') {
            history.push("/add-apismpp/" + accountId + "/" + productid)
        } else if (apiTypeName === 'HTTP REST' || apiTypeName === 'HTTP XML/SOAP') {
            history.push("/add-apihttp/" + accountId + "/" + productid)
        } else if (apiTypeName === 'Email to SMS') {
            history.push("/add-apiemail/" + accountId + "/" + productid)
        } else if (apiTypeName === 'FTP To SMS') {
            history.push("/add-apiftp/" + accountId + "/" + productid)
        }
        // }

    }

    const cancelHandler = () => {
        history.push("/client-product/" + accountId)
    }

    const CancelRequestHandler = () => {
        setLoader(true)
        axios.get("product/product_cancellation_request/" + productid)
            .then((response) => {
                setLoader(false)
                console.log(response)
                setSuccess(response.data.data)

            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401 || error.response.status === 403) {
                        history.push('/logout')
                    } else if (error.response.status !== 200) {
                        setError(error.response.data.message);
                        setLoader(false)
                    }
                }
            })
    }

    return (
        <div>
            <Prompt
                when={FromDataChanged}
                message="You are navigating away from this screen. You will lose the typed information. Are you sure you want to leave this screen?"
            />
            {loader ? <Loader /> : null}
            <form onSubmit={submitProductDetails}>
                <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                    <div className="row flex-a-center">
                        <div className="col-sm-6">
                            <h5 className="mb-0 custom-header-title"><strong>
                                {(productid > 0) ? 'Edit' : 'Add New'} Client Product</strong></h5>
                        </div>
                        <div className="col-sm-6">
                            <div className="font-22 ms-auto confirm-btn">
                                <button type="submit" className={"btn btn-success px-5" + (permissions.includes('product_add') ? '' : ' blur-buttons')}>Save</button>
                                &nbsp;
                                {productid > 0 ?
                                    <button type="button" className="btn btn-primary px-4"
                                        onClick={CancelRequestHandler}>
                                        Request for Cancellation
                                    </button>
                                    : null}
                                &nbsp;
                                <button type="button" className="btn btn-danger px-4" onClick={cancelHandler}>
                                    Cancel/Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                <div className="card card-custom">
                    <div className="card-body">

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="ProductTypeID"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >
                                        Select Product
                                    </label>
                                    <select
                                        autoFocus
                                        value={ProductTypeID || ''}
                                        className="form-select mb-3"
                                        name="ProductTypeID"
                                        id="ProductTypeID"
                                        aria-label="Default select example"
                                        onChange={productHandler}
                                    >
                                        <option value=""> Select Product</option>
                                        {
                                            productArray.map((product) => {
                                                return <option key={product.ID} value={product.ID}>{product.ProductType}</option>
                                            })
                                        }
                                    </select>
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {ProductTypeError}
                                    </h6>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="Name"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-3"
                                        id="Name"
                                        name="Name"
                                        placeholder="Enter Name"
                                        value={Name || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {NameError}
                                    </h6>
                                </div>
                            </div>

                        </div>


                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="Description"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >
                                        Product Description
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-3"
                                        id="Description"
                                        name="Description"
                                        placeholder="Enter Product Description"
                                        value={Description || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {DescriptionError}
                                    </h6>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="Status"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >
                                        Status
                                    </label>
                                    <select
                                        value={Status || ''}
                                        className="form-select mb-3"
                                        name="Status"
                                        id="Status"
                                        disabled
                                        aria-label="Default select example"
                                        onChange={handleChange}
                                    >
                                        <option value="Disabled"> Disabled</option>
                                        <option value="Enabled"> Enabled</option>
                                    </select>
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {StatusError}
                                    </h6>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="API"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >
                                        Select API
                                    </label>
                                    <select
                                        value={ApiTypeID || ''}
                                        className="form-select mb-3"
                                        name="ApiTypeID"
                                        id="ApiTypeID"
                                        aria-label="Default select example"
                                        onChange={handleChange}
                                    >
                                        <option value=""> Select API</option>
                                        {
                                            apiArray.map((Api) => {
                                                return <option key={Api.ID} value={Api.ID}>{Api.Name}</option>
                                            })
                                        }
                                    </select>
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {ApiTypeError}
                                    </h6>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <br />
                                    <br />
                                    <button type="button" className="btn btn-primary px-4"
                                        onClick={() => submitProduct('config')}
                                    >
                                        Configure
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            {(showMarketingFlag === true) ?
                                <div className="col-sm-6">
                                    <div className="mt-3 mb-3">
                                        <label className="form-label form-lable-custom mt-3 mb-3" htmlFor="network">Marketing Flag</label>
                                        <br />

                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="Marketing"
                                                onChange={handleChange}
                                                checked={(Marketing === '1') ? true : false}
                                                value="1" />
                                            <label className="form-label form-lable-custom  mb-3" htmlFor="Marketing">Yes</label>
                                        </div>

                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="Marketing"
                                                value="2"
                                                onChange={handleChange}
                                                checked={(Marketing === '2') ? true : false}
                                            />
                                            <label className="form-label form-lable-custom  mb-3" htmlFor="Marketing">No</label>
                                        </div>
                                        <h6 className="mb-0" style={{ color: "red" }}>
                                            {MarketingError}
                                        </h6>
                                    </div>
                                </div>
                                : null}

                        </div>
                    </div>
                </div>

                <ToastContainer theme="colored" />
            </form>
        </div>
    )
}
export default AddClientProduct
