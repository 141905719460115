import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Accordionr from "../../components/Accordianr";
import { useHistory } from "react-router";
import axios from "../../axios";
import Loader from "../../components/Loader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddPermission = (props) => {
  const permissions = useSelector((state) => state.getPermissions.permissions);
  var groupName = props.Name;
  var permitid = props.permitId;
  const history = useHistory();
  const [permissionList, setPermissionList] = useState([]);
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (permissions.includes('group_list_permission')) {
      setLoader(true)
      axios
        .get(`group/list_permission/${permitid}`)
        .then((response) => {
          setPermissionList(response.data.data);
          setLoader(false)
          // setSuccess(response.data.data);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
              history.push("/logout");
            }
            if (error.response.status !== 200) {
              setError(error.response.data.message);
              setLoader(false)
            }
          }
        });
    }
  }, [permitid, history, permissions]);

  useEffect(() => {
    if (error !== '') {
      toast.error(error, '', 5000);
      setError('')
    }
  }, [error])

  const handleChange = (checked, levelID, parentIndex) => {
    var parentIndexArr = parentIndex.split(" ");
    // console.log(parentIndexArr);
    // console.log(permissionList);
    var permissionListTemp = JSON.parse(JSON.stringify(permissionList))

    if (parentIndexArr.length === 2) {
      permissionListTemp[parentIndexArr[0]].Value[parentIndexArr[1]].Value = checked
    }

    if (parentIndexArr.length === 3) {
      permissionListTemp[parentIndexArr[0]].Value[parentIndexArr[1]].Value[parentIndexArr[2]].Value = checked
    }

    setPermissionList(permissionListTemp);
  };

  const handleParent = (checked, parentIndex) => {
    var permissionListTemp = JSON.parse(JSON.stringify(permissionList))

    var parentIndexArr = (parentIndex.toString().length > 1) ? parentIndex.split(" ") : [parentIndex];

    if (parentIndexArr.length === 1) {
      for (var i = 0; i < permissionListTemp[parentIndexArr[0]].Value.length; i++) {
        if (!Array.isArray(permissionListTemp[parentIndexArr[0]].Value[i].Value)) {
          permissionListTemp[parentIndexArr[0]].Value[i].Value = checked
        } else {
          // console.log(parentIndexArr)
          // console.log(permissionListTemp[parentIndexArr[0]].Value[i].Value)
        }
      }
    }

    if (parentIndexArr.length === 2) {
      var data = permissionListTemp[parentIndexArr[0]].Value[parentIndexArr[1]].Value
      for (i = 0; i < data.length; i++) {
        permissionListTemp[parentIndexArr[0]].Value[parentIndexArr[1]].Value[i].Value = checked
      }
    }
    setPermissionList(permissionListTemp);
  }

  const permission = (permissionList, parentIndex = "") => {
    return permissionList.map((level, index) => {
      return Array.isArray(level.Value) ? (
        <Accordionr
          key={level.ID}
          Value={level.Value}
          parentIndex={parentIndex === "" ? index : parentIndex + " " + index}
          title={level.Name}
          body={permission(
            level.Value,
            parentIndex === "" ? index : parentIndex + " " + index
          )}
          handleParent={handleParent}
        />
      ) : (
        <table key={level.ID} style={{ width: '100%', textAlign: 'left' }}>
          <tbody>
            <tr>
              <td style={{ width: '30%' }}>{level.Name}</td>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={level.Value}
                  onChange={(e) =>
                    handleChange(
                      e.target.checked,
                      level.ID,
                      parentIndex === "" ? index : parentIndex + " " + index
                    )
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      );
    });
  };


  // useEffect(() => {
  //   console.log('here')
  //   setAll(permission(permissionList))
  // },[permissionList])

  const all = permission(permissionList)
  return (
    <div className="card">
      {loader ? <Loader /> : null}
      <div className="d-flex align-items-center">
        <div className="title-box">
          <h5 className="mb-0"><strong>Link Permission : {groupName} Group</strong></h5>
          <button
            className={"btn btn-success px-4"+ (permissions.includes('group_save_group_permission') ? '' : ' blur-buttons')}
            onClick={() =>
              props.permissionResponse(true, props.permitId, permissionList)
            }
          >
            Save
          </button>
          <button
            className="btn btn-danger px-4"
            onClick={() => props.hidePermisssionHandler()}
          >
            Cancel
          </button>
        </div>
      </div>
      <hr />

      <div className="card radius-10">
        <div className="card-body">
          <ul className="accordion accordion-flush">
            {all}
          </ul>
        </div>
      </div>

      {/* <ToastContainer theme="colored" /> */}
    </div>
  );
};
export default AddPermission;
