import React from 'react';
import LogoDark from '../../images/logo-dark.png'
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
    return (
        <div>
            <div className="overlay toggle-icon"></div>
            <div className='privacy-sticky'>
                <div className="align-items-center custom-sticky-header-tc pp-custom-header" id="custom-sticky-header">
                    <div className="row flex-a-center">
                        <div className="col-sm-6 d-flex-start">
                            <img src={LogoDark} className="term-logo-icon-media" alt="logo icon media" />
                        </div>
                        <div className="col-sm-6">
                            <div className="font-22 ms-auto confirm-btn">
                                <Link to="/login" className="term-btn"> <button type="button" className="btn btn-primary px-5">Login</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="privacy-policy">
                <div className="privacy-title">
                    <h1 className='gothamblack'>Privacy Policy</h1>
                </div>

                {/* new content */}

                <h3> PROTECTION OF PERSONAL INFORMATION ACT CUSTOMER PRIVACY NOTICE THE PROTECTION OF PERSONAL INFORMATION ACT CUSTOMER PRIVACY NOTICE
                </h3>

                <div>
                    <p>
                        This Notice explains how we obtain, use and disclose your personal information, in accordance with the  requirements  of  the  Protection  of  Personal  Information  Act  (“POPIA”).  On  this  website,  POPI Act-Compliance)  we  are  committed  to  protecting  your  privacy  and  to  ensure  that  your  personal information is collected and used properly, lawfully and transparently.
                    </p>
                </div>

                <div>
                    <ul type="none">
                        <li>
                            <h2> 1. About the Company</h2>
                            <p>Synergistic Platform</p>
                            <p>1.1 The information we collect</p>
                            <p>1.1.1  &nbsp;We collect and process your personal information mainly to contact you for the purposes of understanding your requirements and delivering services accordingly. For this purpose, we will collect contact details including your name and organisation.</p>

                            <p>1.1.2  &nbsp;We collect information directly from you where you provide us with your personal details.  Where possible, we will inform you what information you are required to provide to us and what information is optional.</p>

                            <p>1.1.3  &nbsp;Website  usage  information  may  be  collected  using  “cookies”  which  allows  us  to  collect standard internet visitor usage information.</p>

                        </li>


                        <li>
                            <h2> 2. How we use your information</h2>
                            <p>2.1 We  will  use  your  personal  information  only  for  the  purposes  for  which  it  was  collected  and agreed with you.  In addition, where necessary your information may be retained for legal or research purposes.</p>
                            <p>For example:</p>
                            <ul>
                                <li>To gather contact information;</li>

                                <li>To confirm and verify your identity or to verify that you are an authorised user for security purposes;</li>

                                <li>For  the  detection  and  prevention  of  fraud,  crime,  money laundering or other malpractice;</li>

                                <li>
                                    To  conduct  market  or  customer  satisfaction  research  or  for statistical analysis;
                                </li>

                                <li>For audit and record keeping purposes;</li>

                                <li>In connection with legal proceedings.</li>
                            </ul>
                        </li>

                        <li>
                            <h2>3. Disclosure of information</h2>

                            <p>3.1 We may disclose your personal information to our service providers who are involved in the delivery  of  products  or  services  to  you.  We  have  agreements  in  place  to  ensure  that  they comply with the privacy requirements as required by the Protection of Personal Information Act.</p>

                            <p>We may also disclose your information:</p>
                            <ul>
                                <li>Where we have a duty or a right to disclose in terms of law or industry codes;</li>
                                <li>Where we believe it is necessary to protect our rights.</li>
                            </ul>
                        </li>

                        <li>
                            <h2>4. Information Security</h2>
                            <p>
                                4.1 We are legally obliged to provide adequate protection for the personal information we hold and to stop unauthorized access and use of personal information. We will, on an on-going basis, continue  to  review  our  security  controls  and  related  processes  to  ensure  that  your  personal information remains secure.
                            </p>

                            <p>4.2 Our security policies and procedures cover:</p>
                            <ul>
                                <li>Physical security;</li>
                                <li>Computer and network security;</li>
                                <li>Access to personal information;</li>
                                <li>Secure communications;</li>
                                <li>Security in contracting out activities or functions;</li>
                                <li>Retention and disposal of information;</li>
                                <li>Acceptable usage of personal information;</li>
                                <li>Governance and regulatory issues;</li>
                                <li>Monitoring access and usage of private information;</li>
                                <li>Investigating and reacting to security incidents.</li>
                            </ul>

                            <p>
                                4.3 When we contract with third parties, we impose appropriate security, privacy, and confidentiality obligations on them to ensure that personal information that we remain responsible for, is kept secure. We will ensure that anyone to whom we pass your personal information agrees to treat your information with the same level of protection as we are obliged to.
                            </p>
                        </li>

                        <h2>5. Your Rights: Access to information</h2>
                        <p>5.1.1  You have the right to request a copy of the personal information we hold about you. To do  this,  simply  contact  us  at  the  numbers/addresses  as  provided  on  our  website  and specify what information you require.  We will need a copy of your ID document to confirm your identity before providing details of your personal information.</p>

                        <p>5.1.2  Please  note  that  any  such  access  request  may  be  subject  to  a  payment  of  a  legally allowable fee.</p>


                        <h2>6. Correction of your information</h2>
                        <p>6.1 You  have  the  right  to  ask  us  to  update,  correct  or  delete  your  personal  information.  We  will require a copy of your ID document to confirm your identity before making changes to personal information we may hold about you. We would appreciate it if you would keep your personal information accurate.</p>

                        <h2>7. Definition of personal information</h2>
                        <p>7.1 According  to  the  Act ‘‘personal  information’’ means  information  relating  to  an  identifiable, living, natural person, and where it is applicable, an identifiable, existing juristic person.  Further to the POPI Act, COR Concepts also includes the following items as personal information:</p>

                        <p>7.2 All addresses including residential, postal and email addresses.
                            {/* <ul>
                                <li>Change of name – for which we require copies of the marriage certificate or official change of name document issued by the state department.</li>
                            </ul> */}
                        </p>

                        <h2>8. How to contact us</h2>
                        <p>
                            8.1 &nbsp; If  you  have  any  queries  about  this  notice;  you  need  further  information  about  our  privacy practices; wish to withdraw consent; exercise preferences or access or correct your personal information, please contact us at the numbers/addresses listed on our website.
                        </p>

                        <p>
                            8.2  &nbsp; Compliance to the Protection of Personal Information Act   (POPIA), also known as the POPI Act,  will  be  mandatory  for  most  organisations  in  South  Africa.   As  the  Information  Regulator develops  the  POPI  Regulations  further,  so  the  dates  and  requirements  will  become  clearer.  See  the latest  status  from  the  Information  Regulator.   This  doesn't  mean  that  organisations should  wait  until  then.   The  European  Union  has  developed  the  General  Data  Protection Regulations (GDPR) and are in the process of implementation.  The Information Regulator is likely to follow similar principles and regulations. Until the POPI Act and Regulations are fully in place, following the GDPR will get you moving in the right direction.
                        </p>

                        <p>
                            8.3 &nbsp; Whilst the focus of the POPI Act is on compliance, our approach is to implement compliance in such a way that it delivers business value, so that it doesn't become a cost centre, or overhead, but rather allows for improvements in efficiencies and effectiveness, done in such a way as to meet the compliance requirements.
                        </p>

                        <p>
                            8.4 &nbsp; The  site  contains  useful  guidance  and  implementation  tools  to  equip  you  to  be  POPI  Act compliant.  It will evolve over time as the Regulations are published.  It will contain information about:
                        </p>

                        <p>
                            8.5 &nbsp; Understanding the POPI Act (POPIA)
                        </p>
                        <p>
                            8.6 &nbsp; How to comply to POPIA
                        </p>
                        <p>
                            8.7 &nbsp; The General Data Protection  Regulations (GDPR)
                        </p>
                        <p>
                            8.8 &nbsp; Similarities, and differences between POPIA and GDPR there related information.
                        </p>

                        <p> 8.9 &nbsp; This site is proudly sponsored by COR Concepts, an Information Management company.</p>

                        <p> 8.10 &nbsp; If you need any further information regarding the POPI Act, and simple steps to compliance, sign-up to our e-mail newsletter</p>

                        <p> 8.11 &nbsp;   The information published on this website is provided for general purposes only and does not constitute  legal  advice.  We  make  every  effort  to  ensure  that  the  content  is  up  to  date  and accurate.  Please  consult  with  a  lawyer  for  legal  advice.  During  our  implementation  we  can engage  with  privacy  lawyers  on  your  behalf.  We  accept  no  responsibility  for  any  loss  or damage,  whether  direct  or  consequential,  which  may  arise  from  reliance  on  the  information contained in these pages</p>
                    </ul>
                </div>
                {/* old content */}

                <div>
                    <h2>Introduction</h2>
                    <p>POPIA is the Protection of Personal Information Act and it was gazetted in South Africa on the 14th of December 2018. In this document we will describe how we handle information from both a data retention and security standpoint.</p>
                </div>

                <div>
                    <h2>Roles and Responsibilities&apos;</h2>
                    <p>In compliance with POPIA, our roles and responsibilities are as follows: </p>
                    <ul>
                        <li>
                            We are the responsible party regarding the client&apos;s personal information, such as email addresses, phone numbers, billing details, and other information used to do business with clients.
                        </li>
                        <li>
                            We are the service provider, or operator regarding the personal information that the client provides in the form of a database, distribution list, or the like
                        </li>
                    </ul>

                </div>

                <div>


                    <h2>Our Privacy Principles</h2>
                    <ul>
                        <li>We undertake to handle data with the utmost respect and care and will ensure it is kept safe and secure from unauthorised access.</li>

                        <li>We undertake to store data for as long as is absolutely necessary to perform functions related to the client&apos;s account, also ensuring we comply with rules laid out in the various other applicable acts in South Africa.</li>

                        <li>We will not share client&apos;s data with 3rd parties other than those that are absolutely necessary to perform functions set</li>

                        <li>Clients can request any data we have concerning them at any time provided it is handled in accordance with POPIA and sufficient lead time is provided.</li>
                    </ul>
                </div>


                <div>
                    <h2>Compliance with Acts</h2>
                    <ol>
                        <li>POPIA</li>
                        <li>CPA</li>
                        <li>Electronic Communications Act (ECT)</li>
                    </ol>
                </div>

                <div>
                    <h2>Information Officer</h2>
                    <p>
                        Our information officer is Lawrence Makau.
                        <br />
                        <br />
                        Please email: lawrence@apprentice.co.za should you have any questions or concerns. Our Telephone Number is: 011 465 6479
                    </p>
                </div>

                <div>
                    <h2>Security &amp; Data Store</h2>
                    <p>
                        Our websites are digitally secure over HTTPS using only the TLS protocol. Ciphers are limited to AES (128 and 256) with both SHA1 and SHA2 hashes. The preferred Key exchange is ECDH, but DH and PKCS is supported.
                        <br />
                        <br />
                        Only the client would have access to these, to view their reports and perform account functions as needed.
                    </p>
                </div>

                <div>
                    <h2>Physical Security </h2>
                    <p>
                        All access to our server environment is strictly controlled with biometric readers, key access and camera surveillance, and all are located behind multiple entry doors each requiring a security measure in order to gain deeper access. Access can only be gained via service request prior to entry at the IDCs and is limited to Company Internal Technical Contacts only.
                    </p>
                </div>

                <div>
                    <h2>Logical Security</h2>
                    <p>
                        All communications between the MNOs and us utilises strict transport security over TLS. All servers and systems are located behind firewalls and other filtering applications in order to prevent and isolate any attempted breach. Logical and isolated networks are used to limit break vectors and unauthorised access to key system areas. Our systems are kept up to date with latest firmware, updates, definitions and patches in order to mitigate any exploits.
                    </p>
                </div>

                <div>
                    <h2>Application Security</h2>
                    <ol>
                        <li>As an organisation, we use best practices for data access to eliminate the possibility of SQL</li>

                        <li>We ensure that all our platform runtimes are kept up to</li>

                        <li>Staff actively keep up to date with news on their development platforms, and vulnerabilities which might affect our applications to ensure they are patched ASAP.</li>

                        <li>We do extensive logging so if we should ever suffer a breach, we will be able to identify and rectify the vector by which the intrusion occurred.</li>

                        <li>At our Edge routers, we practice a very frugal approach to allowing traffic in to limit the attack surface as much as possible, and all connections to our perimeter are via HTTPS unless specifically requested by customers.</li>

                        <li>All applications and associated resources are backed up daily and kept for at least a week; this ensures we can recover in case of emergency.</li>

                        <li>Our Applications run in virtual machines which are mirrored in real time to a remote site so that service continuity can be ensured.</li>
                    </ol>
                </div>

                <div>
                    <h2>Data Replication and Backups</h2>
                    <p>Our data is stored in three distinct phases during its life span,</p>
                    <br />
                    <ol>
                        <li>
                            <u> Active Access: </u>
                            Data is stored for a limited period for optimum performance with the least amount of potential data exposure. Data is replicated/Mirrored for redundancy and high availability.
                        </li>

                        <li>
                            <u> Active Archive: </u>
                            Data is stored mainly for reporting and is maintained in separate store containers (Databases) to lessen impact on the active client services. Data is not replicated to minimise potential data leakage/exposure.
                        </li>

                        <li>
                            <u> Passive Archive: </u>
                            Data is stored in physically and logically separate locations and can only be accessed by key staff, the content is stored in read only format and is not replicated.
                        </li>
                    </ol>

                    <br />
                    <p>Beyond this retention the data is destroyed.</p>
                    <p>Backups of all data in the phases above are performed at various daily/weekly/monthly/quarterly intervals and are copied off- server and off-site for safe keeping and Disaster Recovery.</p>
                </div>

                <div>
                    <h2>API Use and Security</h2>
                    <p>Our API&apos;s are all secured by Username and Password protection. In every available instance we only allow HTTPS access to our APIs.</p>
                </div>

                <div>
                    <h2>Application Access Control</h2>
                    <p>All services are only accessible via user accounts following “least privilege” model to insure only authorised access is provided to the required users.</p>
                </div>

                <div>
                    <h2>Validation and Monitoring</h2>
                    <p>
                        Our customised 3<sup>rd</sup> party Validation / Notification system allows us to easily add new services to monitor as well as escalations. We continually analyse and improve this service.
                    </p>
                </div>

                <div>
                    <h2>Redundancy</h2>
                    <h4>Live Environment Redundancy</h4>
                    <p>The Live environment is hosted in a Datacentre in a redundant full rack solution, which offers us a wide range of failure protection:</p>

                    <ol>
                        <li>Dual power bars running on separate circuits for each Server in the</li>
                        <li>UPS protection for the</li>
                        <li>Generator supplied power feed in case of complete power</li>
                    </ol>

                    <p>The Environment is also kitted in case of fire emergencies, with gas protection, which purges the environment of oxygen to combat electrical fires.</p>

                    <p>The servers themselves are equipped with hardware redundancy by means of RAID controllers/modules with varying RAID Levels.</p>

                    <p>Server level failures will result in that service failing over to the DR environment.</p>

                    <p>The live environment is run over gigabit connection and is connected to the DR environment by a fibre link over encrypted connections.</p>

                    <p>The power strips are also in the process of being upgrade for remotely managed monitoring and recycling which facilitates in quick turnaround time on system failures.</p>

                    <h4>Disaster Recovery Environment</h4>
                    <p>The DR is located in a separate Environment, which is also monitored by surveillance cameras and access controlled door security mechanics. This environment runs off an independent air conditioner and is powered by a dedicated UPS and generator</p>

                    <p>The room is equipped with smoke detection monitors and fire extinguishers.</p>

                    <p>Each server is resilient at a hardware level with the use of different RAID level modules.</p>

                    <p>This environment is also run over gigabit links and has its own independent breakout utilising different ISP(s) to the live environment.</p>
                </div>

                <div>
                    <h2>Internal Access to Client Data</h2>
                    <h3>Physical Access</h3>

                    <p>Our head office is located in a secure gated office park with the following security measures in place:</p>

                    <ul>
                        <li>Restricted access to office park</li>
                        <li>Biometric entry into Building</li>
                    </ul>
                </div>

                <div>
                    <h3>AV Staff</h3>
                    <p>We pride ourselves on the people we employ as it is a direct reflection on our business.</p>

                    <ul>
                        <li>All staff who are employed will have background checks conducted on them before</li>

                        <li>When staff begin work, they are required to sign various policies including:</li>
                        <ul>
                            <li>Code of Conduct</li>
                            <li>Confidentiality Agreement</li>
                            <li>Disciplinary Policy</li>
                            <li>Electronic Communications Policy</li>
                            <li>Social Networking Communications Policy</li>
                        </ul>
                        <li>Staff are trained on the various policies and procured on their</li>

                        <li>Staff are provided with access to a Password Management facility where they are only given access to areas of the business that are relevant to them. When staff leave access is revoked.</li>

                        <li>Staff are required to lock PC&apos;s if not in their</li>

                        <li>Every internal office is locked outside of working hours, to prevent unauthorised or unsupervised</li>
                    </ul>
                </div>

                <div>
                    <h3>Suppliers</h3>
                    <p>We work closely with our suppliers to ensure we have a good working relationship. Multifactor Authentication (MFA) is enabled on every supporting service we use.</p>
                </div>

                <div>
                    <h3>Paper Records</h3>
                    <ul>
                        <li>Records are kept in a locked office and furthermore cabinet for as long as is necessary to perform the necessary</li>

                        <li>Archived data is stored offsite in an approved and secure</li>

                        <li>Unnecessary records are</li>
                    </ul>
                </div>

                <div>
                    <h3>Email / Data Transfer</h3>
                    <p>Sensitive data is not transferred via normal email, instead a secure link sharing tool is available which automatically expires the data after a few days to ensure it is not retained if it doesn&pos;t need to be.</p>
                </div>

                <div>
                    <h3>Remote Access</h3>
                    <p>Staff with the necessary privileges are provided with secure VPN access over an encrypted link.
                        <br />
                        <br />
                        Only data they have permission to view is available to them. Additional permissions are by management approval only.</p>
                </div>

                <div>
                    <h3>Laptops / Storage Devices</h3>
                    <p>Laptops are all secure via Username and Password or Biometrics. In the case of passwords these all need to conform to a standard length and type required by our Network Management Team.</p>

                    <p>If Laptops are kept on site for whatever reason, they are physically secure behind locked doors.</p>

                    <p>Devices owned by our staff are all placed on a separate Wi-Fi network and not the one used for their work. Laptops transferred to other staff or recycled have their hard drives completely wiped.</p>
                </div>

                <div>
                    <h3>Data Breach Policy</h3>

                    <p>The purpose of this policy is to set out the process we follow when a potential data breach has been identified.</p>

                    <p>A data breach means a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, personal or business sensitive data. This includes breaches that are the result of both accidental and deliberate causes.</p>

                    <p>Should a data breach occur, the following steps will be taken:</p>
                    <ol>
                        <li>We will notify the regulator and data subject(s) as soon as reasonably</li>

                        <li>The notification will be in writing sent via electronic communication and will contain:
                            <br />
                            <br />

                            <ol style={{ listStyle: "none" }}>
                                <li>2.1  &nbsp;  Description of the incident</li>
                                <li>2.2 &nbsp; Possible consequences</li>
                                <li>2.3 &nbsp; Possible recommendations the data subject could take to mitigate the adverse effects of the security</li>
                                <li>2.4  &nbsp; Measures we intend to take / have taken to address the</li>
                                <li>2.5  &nbsp; If known, we will indicate the identity of the unauthorised person who may have accessed or acquired the personal information.</li>
                            </ol>
                        </li>
                    </ol>
                </div>

            </div>



        </div >
    )
}

export default PrivacyPolicy