import React, { useState, useEffect } from 'react'
import axios from "../../axios";
import { useHistory, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { baseUrl } from '../../base';
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import AlertBox from "../../components/AlertBox";
import { Prompt } from 'react-router-dom';

const AddContactList = () => {
    const permissions = useSelector((state) => state.getPermissions.permissions);
    const { contactId } = useParams();
    const history = useHistory();

    const [ContactListDetails, setContactListDetails] = useState({
        ContactListID: contactId || 0,
        Name: "",
        Description: "",
        UploadFile: null,
        FileType: "csv",
        FileName: "",
        DuplicateCheck: 0
    })
    const { Name, Description, UploadFile, FileType, DuplicateCheck } = ContactListDetails;
    const [nameError, setNameError] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [UploadFileError, setUploadFileError] = useState("")
    const [fileTypeError, setFileTypeError] = useState("");
    const [duplicateCheckError, setDuplicateCheckError] = useState("");
    // const [getFiles, setGetFiles] = useState("")
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false);
    const [alert, setAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [HandleFlag, setHandleFlag] = useState(false)
    const [FromDataChanged, setFromDataChanged] = useState(false)

    const NormalField = (localStorage.getItem("ClientNormalField") ? localStorage.getItem("ClientNormalField") : "");

    const handleChange = (e) => {
        setContactListDetails({ ...ContactListDetails, [e.target.name]: e.target.value });
        setFromDataChanged(true)
    };

    const handleDuplicate = (e) => {
        var DuplicateCheckValue = e.target.checked === true ? 1 : 0
        setContactListDetails({ ...ContactListDetails, DuplicateCheck: DuplicateCheckValue });
        setFromDataChanged(true)
        // if (e.target.checked === true) {
        //     setContactListDetails({ ...ContactListDetails, DuplicateCheck: DuplicateCheckValue });
        // } else {
        //     setContactListDetails({ ...ContactListDetails, DuplicateCheck: DuplicateCheckValue });
        // }
    }

    const handleFile = (e) => {
        const file = e.target.files[0]
        setError('');
        var maxsize = 8000000;
        if (file && e.target.files[0].size <= maxsize) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => setContactListDetails({ ...ContactListDetails, [e.target.name]: reader.result, FileName: file.name });
        } else {
            e.target.value = '';
            setError("File size should be less than or equal to 8 MB only!!")
        }
        setFromDataChanged(true)
    };


    useEffect(() => {
        if (contactId && permissions.includes('contact_get_contact_list')) {
            setLoader(true)
            axios
                .get("contact/get_contact_list/" + contactId)
                .then((response) => {
                    setContactListDetails({
                        ContactListID: response.data.data.ID,
                        Name: response.data.data.Name,
                        Description: response.data.data.Description,
                        UploadFile: null,
                        FileType: "csv",
                        FileName: "",
                        DuplicateCheck: parseInt(response.data.data.DuplicateCheck)
                    })
                    setLoader(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                })
        }
    }, [contactId, history, permissions])

    function validate() {
        var flag = true;
        setNameError("");
        setDescriptionError("");
        setUploadFileError("");
        setFileTypeError("");
        setDuplicateCheckError("")

        if (Name === "") {
            flag = false;
            setNameError("Name field is required");
        }

        if (Description === "") {
            flag = false;
            setDescriptionError("Description field is required");
        }

        if (!contactId) {
            if (UploadFile === null) {
                flag = false;
                setUploadFileError("File Upload field is required")
            }
        }

        if (FileType === '') {
            flag = false;
            setFileTypeError("File Type field is required")
        }

        if (!DuplicateCheck && !HandleFlag) {
            setAlertMessage("Please take note that you have NOT selected the Duplicate Checking. Any duplicates found will be charged for. Do you want Duplicate Checking?")
            setAlert(true)
            // setDuplicateCheckError("Duplicate check field is required")
        } else {
            setHandleFlag(true)
            setAlert(false)
        }

        return flag
    }

    useEffect(() => {
        // For edit
        if (contactId && DuplicateCheck === 1) {
            setHandleFlag(true)
            setAlert(false)
        }
    }, [DuplicateCheck, contactId])

    const submitContactDetails = (e) => {
        e.preventDefault();
        setSuccess("")
        setError("");
        const flag = validate()
        var url = (contactId) ? 'edit_contact_list' : 'add_contact_list';
        // console.log("out", "falg", flag, "HandleFlag", HandleFlag)
        if (flag && HandleFlag) {
            // console.log("first", "falg", flag, "HandleFlag", HandleFlag)
            setLoader(true)
            axios
                .post("contact/" + url, ContactListDetails)
                .then((response) => {
                    setLoader(false)
                    setFromDataChanged(false)
                    history.push({
                        pathname: '/contact-list',
                        state: { message: response.data.data.message }
                    })
                    setAlert(false)
                    // history.push('/contact-list')
                    //  setSuccess(response.data.data.message);
                    //  history.push('/add-contact-list/'+response.data.data.ContactListID)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401 || error.response.status === 403) {
                            history.push('/logout')
                        } else if (error.response.status !== 200) {
                            setError(error.response.data.message);
                            setLoader(false)
                        }
                    }
                });
        }
        // else {
        //     if (contactId && DuplicateCheck === 1) {
        //         setAlert(false)
        //     } else {
        //         setAlertMessage("Please take note that you have NOT selected the Duplicate Checking. Any duplicates found will be charged for. Do you want Duplicate Checking?")
        //         setAlert(true)
        //     }
        // }
    }

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    const cancelHandler = () => {
        // history.push("/contact-list")
        history.goBack()

    }

    const alertResponse = (response) => {
        if (response) {
            setContactListDetails({ ...ContactListDetails, DuplicateCheck: 1 })
            setHandleFlag(true)
            setAlert(false)
        } else {
            setContactListDetails({ ...ContactListDetails, DuplicateCheck: 0 })
            setHandleFlag(true)
            setAlert(false)
        }
    }

    return (
        <div>
            <Prompt
                when={FromDataChanged}
                message="You are navigating away from this screen. You will lose the typed information. Are you sure you want to leave this screen?"
            />
            {loader ? <Loader /> : null}
            {alert ?
                <AlertBox
                    alertResponse={alertResponse}
                    message={alertMessage}
                />
                : null
            }
            <form onSubmit={submitContactDetails}>
                <div className="align-items-center custom-sticky-header" id="custom-sticky-header">
                    <div className="row flex-a-center">
                        <div className="col-sm-6">
                            <h5 className="mb-0 custom-header-title"><strong>{(contactId > 0) ? 'Edit' : 'Add New'}  Contact List</strong></h5>
                        </div>
                        <div className="col-sm-6">
                            <div className="font-22 ms-auto confirm-btn">
                                <button type="submit" className="btn btn-success px-5">Create</button>
                                &nbsp;
                                <button type="button" className={"btn btn-danger px-4" + (permissions.includes('contact_add_contact_list') ? '' : ' blur-buttons')} onClick={cancelHandler}>Cancel/Back</button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />


                <div className="card card-custom">
                    <div className="card-body">


                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="Name"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >
                                        Name<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <input
                                        autoFocus
                                        type="text"
                                        className="form-control mb-3"
                                        id="Name"
                                        name="Name"
                                        placeholder="Enter Your Name"
                                        value={Name || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {nameError}
                                    </h6>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="Description"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >
                                        Description<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <input
                                        type="text"
                                        // rows="5"
                                        className="form-control mb-3"
                                        id="Description"
                                        name="Description"
                                        placeholder="Enter Your Description"
                                        value={Description || ''}
                                        onChange={handleChange}
                                        maxLength={NormalField}
                                    />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {descriptionError}
                                    </h6>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="UploadFile"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >
                                        Upload File<i style={{ color: 'red', fontSize: "20px" }}>*</i>
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control mb-3"
                                        id="UploadFile"
                                        name="UploadFile"
                                        placeholder="Enter ID Document"
                                        onChange={handleFile}
                                        accept=".csv"
                                    />

                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {UploadFileError}
                                    </h6>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <a
                                        href={baseUrl + "ContactList_Example.csv"}
                                        className="btn btn-secondary px-4 mt-5"
                                        download target="_blank"
                                        rel="noreferrer"
                                    >
                                        Example File
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label
                                        htmlFor="UploadFile"
                                        className="form-label form-lable-custom mt-3 mb-3"
                                    >
                                        File Type
                                    </label>
                                    <select
                                        value={FileType}
                                        className="form-select mb-6"
                                        name="FileType"
                                        id="FileType"
                                        onChange={handleChange}
                                    >
                                        <option value="csv">
                                            csv
                                        </option>
                                    </select>
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {fileTypeError}
                                    </h6>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="mt-3 mb-3">
                                    <label className="form-label form-lable-custom mt-3 mb-3" htmlFor="DuplicateCheck">Duplicate Checking
                                        {(contactId > 0) ? <i style={{ color: 'red', fontSize: "20px" }}>*</i> : null}</label> &nbsp;&nbsp;
                                    <input
                                        className="form-check-input duplicateCheckBox mb-3"
                                        type="checkbox"
                                        name="DuplicateCheck"
                                        onChange={handleDuplicate}
                                        checked={DuplicateCheck === 1 ? true : false}
                                        value={DuplicateCheck} />
                                    <h6 className="mb-0" style={{ color: "red" }}>
                                        {duplicateCheckError}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer theme="colored" />
            </form>
        </div>
    )
}
export default AddContactList;