import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import Logo from '../images/logo-light.png';
import DarkLogo from '../images/logo-dark.png';
import UserLogo from '../images/avatar-1.png'
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidebar = (props) => {
  const permissions = useSelector((state) => state.getPermissions.permissions);
  const location = useLocation();
  const { pathname } = location;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  useEffect(() => {
    localStorage.getItem("password_status")
    if (localStorage.getItem("clientFirstName") && localStorage.getItem("clientLastName")) {
      setFirstName(localStorage.getItem("clientFirstName"))
      setLastName(localStorage.getItem("clientLastName"))
    }
  }, [])

  const [open, setOpen] = useState(false);
  const [openSupport, setOpenSuport] = useState(false);
  const [openMgmt, setOpenMgmt] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  // const [openAPI, setOpenAPI] = useState(false);

  const handleToggle = () => {
    setOpen((prev) => !prev);
  }

  const handleToggleSupport = () => {
    setOpenSuport((prev) => !prev);
  }

  const handleToggleMangement = () => {
    setOpenMgmt((prev) => !prev);
  }

  const handleTogglePayments = () => {
    setOpenPayment((prev) => !prev);
  }

  // const handleToggleAPI = () => {
  //   setOpenAPI((prev) => !prev);
  // }

  useEffect(() => {
    if (window.innerWidth <= 768) {
      window.togglebar()
    }
  }, [pathname])


  return (
    <div className="wrapper">
      <div className="sidebar-wrapper" data-simplebar="true">
        <div className="sidebar-header">
          <div> <img src={Logo} className="logo-icon" alt="logo icon" /> </div>
        </div>
        <ul className={"metismenu" + (localStorage.getItem("password_status") && (localStorage.getItem("password_status") === "1") ? " blur-buttons" : "")} id="menu">
          <li>
            <Link to="/dashboard" className={"single-menu-a" + (pathname === '/dashboard') ? "active-custom" : ""}>
              <div className="menu-title">Dashboard</div>
            </Link>
          </li>

          {/* Quick send */}
          <li className={(permissions.includes('sendsms_add') || permissions.includes('template_getall') || permissions.includes('contact_get_all_contact_list')) ? "" : " blur-buttons"}>

            <hr className="dropdown-divider font-white" />
            <Link to="#" className="has-arrow" onClick={handleToggle} style={{ float: 'right' }}>
              <div className="menu-title">Quick Send Tool</div>
            </Link>
            {open ?
              <ul className="custom-sub-menu">
                <li className={(pathname === '/quick-send') ? "active-custom" : "" + (permissions.includes('sendsms_add') ? '' : ' blur-buttons')}>
                  <Link to="/quick-send" className="single-menu-a"> <i className="fa fa-paper-plane">
                  </i>Send SMS</Link>
                </li>
                <li className={(pathname === '/template') ? "active-custom" : "" + (permissions.includes('template_getall') ? '' : ' blur-buttons')}>
                  <Link to="/template" className="single-menu-a"> <i className="fa fa-clipboard">
                  </i>Templates</Link>
                </li>
                <li className={(pathname === '/contact-list') ? "active-custom" : "" + (permissions.includes('contact_get_all_contact_list') ? '' : ' blur-buttons')}>
                  <Link to="/contact-list" className="single-menu-a"> <i className="fa fa-id-card">
                  </i>Contact Lists</Link>
                </li>

                {/* <li className={(pathname === '/quick-send-results') ? "active-custom" : "" + (permissions.includes('sendsms_get_result') ? '' : ' blur-buttons')}>
                  <Link to="/quick-send-result" className="single-menu-a"> <i className="fa fa-list-alt">
                  </i>Results</Link>
                </li> */}
              </ul>
              : null}
          </li>
          {/* Quick send */}


          {/* Support */}
          <li className={(permissions.includes('support_log_support_query') || permissions.includes('support_get_common_terms')) ? "" : " blur-buttons"}>

            <hr className="dropdown-divider font-white" />
            <Link to="#" className="has-arrow" onClick={handleToggleSupport} style={{ float: 'right' }}>
              <div className="menu-title">Support</div>
            </Link>
            {openSupport ?
              <ul className="custom-sub-menu">
                <li className={(pathname === '/submit-a-ticket') ? "active-custom" : "" + (permissions.includes('support_log_support_query') ? '' : ' blur-buttons')}>
                  <Link to="/submit-a-ticket" className="single-menu-a"> Submit Ticket</Link>
                </li>
                <li className={(pathname === '/common-terms') ? "active-custom" : "" + (permissions.includes('support_get_common_terms') ? '' : ' blur-buttons')}>
                  <Link to="/common-terms" className="single-menu-a">Common Terms</Link>
                </li>
              </ul>
              : null}
          </li>
          {/* Support */}


          {/* management */}
          <li className={(permissions.includes('user_getall') || permissions.includes('group_getall') || permissions.includes('clientaccount_getall')) ? "" : " blur-buttons"}>

            <hr className="dropdown-divider font-white" />
            <Link to="#" className="has-arrow" onClick={handleToggleMangement} style={{ float: 'right' }}>
              <div className="menu-title">Management</div>
            </Link>
            {openMgmt ?
              <ul className="custom-sub-menu">
                <li className={(pathname === '/user') ? "active-custom" : "" + (permissions.includes('user_getall') ? '' : ' blur-buttons')}>
                  <Link to="/user" className="single-menu-a"> User Management</Link>
                </li>

                <li className={(pathname === '/group') ? "active-custom" : "" + (permissions.includes('group_getall') ? '' : ' blur-buttons')}>
                  <Link to="/group" className="single-menu-a"> User Group Management</Link>
                </li>

                <li className={(pathname === '/client-accounts') ? "active-custom" : "" + (permissions.includes('clientaccount_getall') ? '' : ' blur-buttons')}>
                  <Link to="/client-accounts" className="single-menu-a"> Accounts </Link>
                </li>
              </ul>
              : null}
          </li>
          {/* management */}


          {/* Billing */}
          <li className={(permissions.includes('topupbalance_getall')) ? "" : " blur-buttons"}>

            <hr className="dropdown-divider font-white" />
            <Link to="#" className="has-arrow" onClick={handleTogglePayments} style={{ float: 'right' }}>
              <div className="menu-title">Billing</div>
            </Link>
            {
              openPayment ?
                <ul className="custom-sub-menu">
                  <li className={(pathname === '/payments') ? "active-custom" : "" + (permissions.includes('topupbalance_getall') ? '' : ' blur-buttons')}>
                    <Link to="/payments" className="single-menu-a"> Payments</Link>
                  </li>
                </ul> : null
            }
          </li>
          {/* Billing */}

          {/* Api */}
          {/* {(permissions.length > 0) ?
            <li>
              <hr className="dropdown-divider font-white" />
              <Link to="#" className="has-arrow" onClick={handleToggleAPI} style={{ float: 'right' }}>
                <div className="menu-title">APIs</div>
              </Link>
              {
                openAPI ?
                  <ul className="custom-sub-menu">
                    <li className={(pathname === '/http-rest') ? "active-custom" : ""}>
                      <Link to="/http-rest" className="single-menu-a"> HTTP Rest</Link>
                    </li>
                    <li className={(pathname === '/http-soap-html') ? "active-custom" : ""}>
                      <Link to="/http-soap-html" className="single-menu-a"> HTTP SOAP/XML</Link>
                    </li>
                  </ul> : null
              }
            </li>
            : null} */}
          {/* Api */}


          {/* {(permissions.length > 0) ?
            <li>
              <hr className="dropdown-divider font-white" />
              <Link to="/reports" className="single-menu-a">
                <div className="menu-title">Reports</div>
              </Link>
            </li>
            : null} */}

          {/* {(permissions.length > 0) ?
            <li>
              <Link to="/Inbox" className="single-menu-a">
                <div className="menu-title">Inbox</div>
              </Link>
            </li>
            : null} */}

          <hr className="dropdown-divider font-white" />

          <br />
          <br />
          <br />
        </ul>


        <div className="sidebar-footer"> <img src={UserLogo} alt="" style={{ width: '20%', borderRadius: '50%' }} />
          <p style={{ color: '#fff', fontSize: '15px', padding: '3%', margin: '1px' }}>
            {firstName} {lastName}
          </p>

          <div className="dropdown ms-auto">
            <div className="dropdown-toggle dropdown-toggle-nocaret cursor-pointer font-white" data-bs-toggle="dropdown" aria-expanded="false"> <i className="bx bx-dots-vertical-rounded font-22"></i> </div>
            <ul className="dropdown-menu profile-dropdown">

              <li className={(pathname === '/profile') ? "active-custom" : ""
                + (permissions.includes('profile_get_all_details') ? '' : ' blur-buttons')
              }>
                <Link to='/profile' className="dropdown-item logout-btn" href="#"><i className="bx bx-id-card"></i> Profile</Link>
              </li>

              <li className={(pathname === '/top-up') ? "active-custom" : "" + (permissions.includes('topupbalance_top_up') ? '' : ' blur-buttons')}>
                <Link to='/top-up' className="dropdown-item logout-btn" href="#"><i className="bx bx-chevron-up-square"></i> Top Up Balance</Link>
              </li>

              <li>
                <Link to='/password' className="dropdown-item logout-btn" href="#"><i className="bx bx-refresh"></i> Change Password</Link>
              </li>

              <li> <Link to='/logout/user' className="dropdown-item logout-btn" href="#"><i className="bx bx-log-out-circle"></i> Logout</Link> </li>
            </ul>
          </div>
        </div>
      </div>

      {/* <header>
        <div className="topbar d-flex align-items-center">
          <nav className="navbar navbar-expand">
            <div className="mobile-toggle-menu"><i className='bx bx-menu'></i> </div>
            <div className="top-menu ms-auto" style={{ textAlign: 'center', margin: 'auto' }}>
            </div>
          </nav>
        </div>
      </header> */}
      <header>
        <div className="topbar d-flex align-items-center">
          <nav className="navbar navbar-expand">
            <div className="mobile-toggle-menu" onClick={() => window.togglebar()}><i className='bx bx-menu'></i> </div>
            <div className="top-menu ms-auto ms-auto-center">
              <ul className="navbar-nav align-items-center">
                <li className="nav-item">
                  <div>
                    <img src={DarkLogo} className="logo-icon-media" alt="logo icon media" /> </div>
                </li>
                <li className="nav-item dropdown dropdown-large">
                  <div className="dropdown-menu dropdown-menu-end">
                    <div className="header-notifications-list"> </div>
                  </div>
                </li>
                <li className="nav-item dropdown dropdown-large">
                  <div className="dropdown-menu dropdown-menu-end">
                    <div className="header-message-list"> </div>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div className="overlay toggle-icon" onClick={() => window.togglebar()}></div>

      <div className="page-wrapper">
        <div className="page-content">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
